import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RestapiService } from '../shared/services/rest-api.service';
import { Router } from '@angular/router';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { StockQuantPackage } from '../models/stock-quant-package';
import { StockMoveLine } from '../models/stock-move-line';
import { StockMove } from '../models/stock-move';
import { StockPicking } from '../models/stock-picking';
import * as moment from 'moment';
import { StockLocation } from '../models/stock-location';
import { ODOO_IDS } from '../models/deal';
import { Product } from '../models/product.model';
import { ProductPackaging } from '../models/product.packaging.model';
import { StockQuant } from '../models/stock-quant';
import Decimal from 'decimal.js';
import { PackageChooserComponent } from '../picking/package-chooser/package-chooser.component';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

  // @ViewChild('articleBarcodeReader', { static: false }) barCodeScanner: BarcodeReader;

  barCode: string = null;
  packages: number[] = [];
  loading: boolean = false;
  isScanning: boolean = false;
  textInput:ReplaySubject<string> = new ReplaySubject(1)
  package: StockQuantPackage;
  activatedRoute: any;
  moveLines: StockMoveLine[];
  mode : string;
  locationGroups: any;
  product: Product;
  quants: StockQuant[];
  showQuantityEditor: StockQuant | null = null;
  O_IDS = ODOO_IDS;
  pickingTypeId: number;
  destLocationId: number;
  usedPackage: StockQuantPackage;
  modifyingAttrsOfPackage: StockQuantPackage;
  modifyingAttrsOfQuant: StockQuant;
  productAvailable: boolean;
  openMessage: boolean = false;
  showTransfers: boolean = false;
  moves: StockMove[];


  constructor(
    public restapi: RestapiService,
    private el:ElementRef,
    private cd: ChangeDetectorRef,
    private odooEM:OdooEntityManager,
    public router: Router
  ) {
    // Intercpt browser's backs and forwards and stop the barcode scanner
    this.router.events.subscribe(e => {
      // don't want to activate camera in sub-routes
      if (e['url'] !== '/search')
        this.isScanning = false
      else
        this.isScanning = true
    });
  }

  ngOnInit() {
  }


  async loadLocations() {
    if (this.locationGroups)
      return
  
    this.locationGroups = this.groupByFirstNumber(await firstValueFrom(this.odooEM.search(new StockLocation(),[['location_id', '=', ODOO_IDS.M]])))
    this.locationGroups["Piazzale"] = await firstValueFrom(this.odooEM.search(new StockLocation(),[['location_id', '=', ODOO_IDS.PIAZZALE]]))
  }


  onAttrsEditDone() {
    this.modifyingAttrsOfQuant = null
    this.onCode(this.package.name)
  }

  async toggleMessage() { 
    this.openMessage = !this.openMessage
  }

  // funzione moveTo2 che sostituisca il nome del pacco con odooem.update aggiungendo la location
  async moveTo2(location: StockLocation) {
    if (!confirm("Spostare il pacco in " + location.display_name + "?")) {
      return;
    }
    // Controlla se il nome del pacco contiene il carattere "|"
    const currentName = this.package.name;
    const separatorIndex = currentName.indexOf('-');
    // Se il separatore esiste, prendi solo la parte prima del separatore
    let updatedName = currentName;
    if (separatorIndex !== -1) {
      updatedName = currentName.substring(0, separatorIndex).trim();
    } 
    // Aggiorna il nome del pacco concatenando la nuova location
    updatedName += " - " + location.display_name;

    try {
      // Esegui l'aggiornamento del pacco con i campi JSON specificati
      await this.odooEM.update(this.package, { name: updatedName }).toPromise();
      // Esegui ulteriori azioni, ad esempio aggiornare l'interfaccia utente
      await this.onCode(updatedName);
    } catch (error) {
      alert("Errore nell'aggiornamento del pacco:" +  error);
      // Gestisci l'errore come necessario
    }
  }

  async moveTo(location:StockLocation) {

    if (!confirm("Spostare il pacco in " + location.display_name + "?"))
      return
    
    let p = await firstValueFrom(this.odooEM.create<StockPicking>(new StockPicking(), {
      'location_id': ODOO_IDS.stock_location_stock,
      'location_dest_id': location.id,
      'picking_type_id': ODOO_IDS.picking_type_stock,
      'company_id': 1,
      'package_level_ids': [[0, 0, {
        // 'name': 'test',
        'is_done': true,
        'company_id': 1,
        'package_id': this.package.id,
        // 'location_id': ODOO_IDS.stock_location_stock,
        'location_dest_id': location.id,
      }]]
    }))

    await this.check(
      this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[p.id]])
    )
    await this.onCode(this.package.name)
  }

  onModifyAttrs(q:StockQuant,p:StockQuantPackage) {
      this.modifyingAttrsOfQuant = q;
  }

  async check(f) {
    let r = await f
    if (r.error) {
      this.loading = false
      alert(r.error.data.message)
      throw (r.error.data.message)
    }
    return r
  }

  onConsume(quant: StockQuant, picking_type_id: number, destLocationId: number, pack?: StockQuantPackage) {
    this.showQuantityEditor = quant;
    this.pickingTypeId = picking_type_id; // Salva il picking_type_id per usarlo più tardi
    this.destLocationId = destLocationId; // Salva il destLocationId per usarlo più tardi
    this.usedPackage = pack;
    //se ho package, gli devo passare il prodotto altrimenti non funziona quantity editor
    if (pack) {
      this.product = quant.product_id.value;
    }
  }

  // after quantity editor
  async onQuantity(qty: number) {
    await this.consume(this.showQuantityEditor, qty, this.destLocationId, this.pickingTypeId, this.usedPackage);
    this.showQuantityEditor = null;
  }

  async consume(quant: StockQuant, qty: number, location_dest_id: number, picking_type_id: number, pack?: StockQuantPackage) {
    this.loading = true;

    if (!confirm("Confermi di aver consumato " + qty + " " + this.product.uom_id.name + "?")) {
      this.loading = false;
      return;
    }

    let value = this.product.standard_price * qty

    //display an error if consuming value is too high
    if (pack && value > 200
    ) {
      alert("Questo materiale è tracciato, non è possibile consumare grandi quantità. Contattare il responsabile commessa.")
      this.loading = false;
      return;
    }

    let p = await firstValueFrom(this.odooEM.create<StockPicking>(new StockPicking(), {
      'location_id': pack ? pack.location_id.id : quant.location_id.id,
      'location_dest_id': location_dest_id,
      'picking_type_id': picking_type_id,
      'company_id': 1,
    }))

    let move = await firstValueFrom(this.odooEM.create<StockMove>(new StockMove(), {
      'name': quant.product_id.name,
      'company_id': 1,
      'location_id': pack ? pack.location_id.id : quant.location_id.id,
      'product_id': quant.product_id.id,
      // 'quantity_done': qty,
      'product_uom_qty': qty,
      'location_dest_id': location_dest_id, 
      'picking_id' : p.id,
    }))

  
    let moveline = await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
      'product_id': quant.product_id.id,
      'qty_done': qty,
      'location_id': pack ? pack.location_id.id : quant.location_id.id,
      'location_dest_id': location_dest_id,
      'move_id': move.id,
      'picking_id': p.id,
      'package_id': pack ? pack.id : null
    }))

    await this.check(
      this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[p.id]])
    );
   
    //ricarica in base a barcode o pack
    if (pack) {
      this.product = null;
      
      this.package.quant_ids.values = []
      await this.onPackageOrig(pack);
    } else {
      this.package = null;

      await this.onProduct(this.product);
      // await this.onCode(this.package.name);
    }
    this.loading = false;
  }


  async onCode(c) {
    if (!c) return  this.router.navigate(['/'])

    this.loading = true
    //search in packs that are not in odoo_ids.exclude_packs_location_ids
    var rs = await firstValueFrom(this.odooEM.search<StockQuantPackage>( 
      new StockQuantPackage(),[['name', '=like',  c + '%']  , ['location_id', 'not in', ODOO_IDS.exclude_packs_location_ids]])) // =ilike BAD TEMP FIX FOR BARCODES with location
  
    // if it's a package
    if (rs && rs.length > 0) { 
      await this.onPackageOrig(rs[0])
    } else { // try searching for product barcodes 
      
      let ps = await firstValueFrom(this.odooEM.search<Product>(new Product(),[['barcode', '=', c]]))

      let prod = ps[0]
      // if not package and not product
      if (!prod) {
        alert("Collo o prodotto non trovato")
        this.loading = false
        return
      } else {
   
        this.onProduct(ps[0])
      }
    }
    this.loading = false 
  }

  getDescriptiveBarcode(prod:Product, q:number) {
    if (!prod.packaging_ids.values?.length)    // dont want to show 
      return 
      var ps = prod.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
      // var q = line.move_id.value.product_uom_qty

      var d = ""
  
      ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
      
      var totale = new Decimal(q)
  
      ps.forEach((p, i) => {
  
        if (!p.sales || p.name.includes("netti")) 
          return
  
        if (totale.toNumber() <= 0)
          return 
  
        let quo;
        //if last
        if (i == ps.length - 1)
          quo = totale.div(p.qty)
        else
          quo = totale.divToInt(p.qty)
        totale = totale.minus(quo.mul(p.qty))
        

        if (quo.toNumber() > 0) {
          if (d.length > 0) {
            d += " + ";
          }
          d += Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name;
        }
      })
  
      return d
  
}

  getDate(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  groupByFirstNumber = (array) => {
    return array.reduce((acc, curr) => {
      // Estrai il primo numero dalla stringa
      const firstNumber = curr.name.split('-')[0];
      // Se non esiste già una chiave con questo numero, inizializzala con un array vuoto
      if (!acc[firstNumber]) {
        acc[firstNumber] = [];
      }
      // Aggiungi la stringa corrente all'array per questo numero
      acc[firstNumber].push(curr);
      return acc;
    }, {});
  };


  getDescriptive(sq:StockQuant, q) {

    if (q == 0) return 0
    if (!sq.product_id.value) return
    if (!sq.product_id.value.uom_id) return // TODO WHY??

    if (!sq.product_id.value?.packaging_ids?.values?.length)    // dont want to show 
      return q +  " " + sq.product_id.value.uom_id.name 
    
    var ps = sq.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    // var q = line.move_id.value.product_uom_qty

    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    var totale = new Decimal(q)

    ps.forEach((p, i) => {

      if (!p.sales || p.name.includes("netti")) 
        return

      if (totale.toNumber() <= 0)
        return 

      let quo;
      //if last
      if (i == ps.length - 1)
        quo = totale.div(p.qty)
      else
        quo = totale.divToInt(p.qty)
      totale = totale.minus(quo.mul(p.qty))
      

      if (quo.toNumber() > 0) {
        if (d.length > 0) {
          d += " + ";
        }
        d += Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name;
      }
    })
   
    return d
  }

  getPieces(product:Product, q:number) {
    // if multiple packages and one of it is pz, calculate the number of pz
    // this is needed because i have to know the number of ps for some products, "3 PACKS" is not enough
    
    if (product.packaging_ids.values?.length > 1)  //search for Pz pack or Pann pack or Set pack
    {
      let pzPack = product.packaging_ids.values.find(x => x.name. toLowerCase().includes("pz") || x.name.toLowerCase().includes("pann") || x.name.toLowerCase().includes("set"))
      
      if (pzPack) {
        //retun number of pz rounded and "pz"
        return "("+(q / pzPack.qty).toFixed(0) + " " + pzPack.name+")"
      }
    }
  }

 
  isModifiableProduct(p: Product) {
    return p.product_tag_ids.ids.includes(ODOO_IDS.tag_variant_search);
  }

  async onProduct(p:Product) {
    this.product = p
    this.productAvailable = false
    
    let qs = await firstValueFrom(this.odooEM.search<StockQuant>(new StockQuant(),[['product_id', '=', p.id], ['location_id.usage', '=', 'internal']])) 
    await firstValueFrom(this.odooEM.resolve(p.packaging_ids))
    this.quants = qs
    this.cd.detectChanges();
    

    //check if available in giacenza
    for (let q of qs) {
      if (q && q.quantity > 0){
        if (q.location_id.name.includes('Giacenza')){
          this.productAvailable = true
                }
    }
      }
  }

  async onPackageOrig(p:StockQuantPackage) { 
  
    let copy = {...p}

    await firstValueFrom(this.odooEM.resolve(copy.quant_ids)) 
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new Product(), copy.quant_ids.values, "product_id"))
    let prods = []
    
    copy.quant_ids.values.forEach(m => {
      prods.push(m.product_id.value)
    })
    // for descriptive 
    await firstValueFrom(this.odooEM.resolveArray(new ProductPackaging(), prods, "packaging_ids"))
    
    p.quant_ids.values = copy.quant_ids.values

    this.package = p
    
    this.cd.detectChanges();

  }

  async toggleTransfers() {
    this.showTransfers = !this.showTransfers
    if (this.showTransfers) {
      this.loading = true


      if (this.product) {
        if(!this.moves || this.moves.length == 0) {
          //only ones that originate from ODOO_IDS.stock_location_stock
          this.moves = await firstValueFrom(this.odooEM.search<StockMove>(new StockMove(),[['product_id', '=', this.product.id], 
          ['state', 'not in', ['done', 'draft', 'cancel']],
          ['location_id', '=', ODOO_IDS.stock_location_stock]]))
        }
        console.log(this.moves)
        if (this.moves.length == 0) {
          alert("Nessun movimento trovato")
        }
      }
      if (this.package) {
        if(!this.moveLines || this.moveLines.length == 0) {
          this.moveLines = await firstValueFrom(this.odooEM.search<StockMoveLine>(new StockMoveLine(),[['package_id', '=', this.package.id], ['state', 'not in', ['done', 'draft', 'cancel']]]))
          await firstValueFrom(this.odooEM.resolveArrayOfSingle(new StockMove(), this.moveLines, "move_id")) //need this for various data
          console.log(this.moveLines)
    }
    if (this.moveLines.length == 0) {
      alert("Nessun movimento trovato")
    }
  }
  this.loading = false
}
  }

  getMLQuantity(ml:StockMoveLine) {
    if (ml.reserved_uom_qty > 0) {
      return ml.reserved_uom_qty
    }
    else {
      return ml.move_id.value?.product_uom_qty
    }
  }

  GetMLOperationType(name: string) {
      return name.replace("LOMAGNA: ", "")
  }
}