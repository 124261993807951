import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Lead } from '../models/crm.lead.model';
import { Line_cost_data } from '../models/sale-order-line.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { PRINTS_COSTLINES_CFG } from '../models/deal';

interface CostsGroup {
  groupName: string;
  lines: Line_cost_data[];
  isOpen: boolean;
  totalRequested?: number;
  totalDelivered?: number;
  totalTotal?: number;
}

interface OrderInfo { 
  order: string; 
  title: string; 
  selected?: boolean;
}

@Component({
  selector: 'app-cost-check-embedded',
  templateUrl: './cost-check-embedded.component.html',
  styleUrls: ['./cost-check-embedded.component.scss'],
})
export class CostCheckEmbeddedComponent {
  @Input() lead: Lead;
  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  allCostlines: Line_cost_data[] = [];
  orders: OrderInfo[] = [];
  ctrlMessage: string = '';
  viewMode: 'category' | 'order' = 'category';
  showDetails: boolean = false;
  showComponent: boolean = false;


  hideUndelivered: boolean = false;
  filteredCostlines: Line_cost_data[] = [];
  SomeUndelivered: boolean = false;

  //form groups of costlines for orders and for categories
  categoryGroups: CostsGroup[] = [];
  orderGroups: CostsGroup[] = [];
  selectedGroups: CostsGroup[] = []; 

  filteredTotal: number = 0;
  allTotal: number = 0;

  constructor(
    private odooEm: OdooEntityManager,
    private gapiService: GapiService
  ) {}

  async fetchCostData() {

    if (!this.lead.order_ids.ids || this.lead.order_ids.ids.length === 0) {
      this.ctrlMessage="Non ci sono ordini da controllare"
      this.loading.emit(false);
      return;
    }
    this.loading.emit(true);

    this.ctrlMessage = `Raccolgo i dati di ${this.lead.order_ids.ids.length} ordini del fascicolo ${this.lead.tracking_code}, attendere...`;
    
    const timeoutId = setTimeout(() => {
      this.ctrlMessage = "Ci sono parecchi dati, attendi ancora qualche secondo...";
    }, 10000);

    try {
      const ctrl = await this.odooEm.run(831, this.lead.id, "crm.lead");
      clearTimeout(timeoutId);
      this.ctrlMessage = "";
      this.allCostlines = ctrl.result.params.data.lines;
      console.log("Cost data:", this.allCostlines);


      this.modelDataLines()
      this.filteredCostlines = [...this.allCostlines];
      this.orders = this.getOrders();
      this.createGroups();

      console.log("Category groups:", this.categoryGroups);
      console.log("Order groups:", this.orderGroups);
      console.log("Selected groups:", this.selectedGroups);
     

    } catch (error) {
      clearTimeout(timeoutId);
      this.ctrlMessage = "Si è verificato un errore durante la raccolta dei dati";
      console.error("Error fetching cost data:", error);
    } finally {
      this.loading.emit(false);
    }
  }

  modelDataLines(){
      //replace category fetched with the category up until the first "/"
    this.allCostlines.forEach(line => {
      const category = line.category.split('/')[0];
      line.category = category;
    });

    //format delivered, requested, cost and total as numbers
    this.allCostlines.forEach(line => {
      line.delivered = Number(line.delivered);
      line.requested = Number(line.requested);
      line.cost = Number(line.cost);
      line.total = Number(line.total);
    });

    //replace ", -" with "" in product names
    this.allCostlines.forEach(line => {
      line.product = line.product.replaceAll(", -", "");
    });

    //for lines with delivered = 0, set total = requested*cost. leave delivered as 0
    this.allCostlines.forEach(line => {
      if(line.delivered === 0){
        line.total = line.requested * line.cost;
      }
    });
  }

  createGroups(){
    this.categoryGroups = this.getCategories().map(category => ({
      groupName: category,
      lines: this.getLinesForCategory(category),
      isOpen: false,
    }));
    //add 2 groups: 
    this.getCategoryTotals();

    this.orderGroups = this.orders.map(order => ({
      groupName: order.order + ' - ' + order.title,
      lines: this.getLinesForOrder(order.order),
      isOpen: false,
    }));
    this.getOrderTotals();


    //remove empty groups
    this.categoryGroups = this.categoryGroups.filter(group => group.lines.length > 0);
    this.orderGroups = this.orderGroups.filter(group => group.lines.length > 0);
    this.SomeUndelivered = this.filteredCostlines.some(line => line.delivered === 0);
    this.getBigTotals();
    this.toggleView(this.viewMode);
    console.log("modifice groups", this.categoryGroups, this.orderGroups);  
  }


  // Helper methods for UI
  toggleView(view: 'category' | 'order') {
    this.selectedGroups = [];

    this.viewMode = view;
    if(this.viewMode === 'category'){
      this.selectedGroups = this.categoryGroups;
    }
    else{
      this.selectedGroups = this.orderGroups;
    }
  }

recalculateFiltered() {
    const selectedOrders = this.orders
        .filter(o => o.selected)
        .map(o => o.order);
    
        //remove all lines that hav eorder name not in selectedOrders
    this.filteredCostlines = this.allCostlines.filter(line => selectedOrders.includes(line.order));

    //remove undelivered lines if hideUndelivered is true
    this.filteredCostlines = this.hideUndelivered 
        ? this.filteredCostlines.filter(line => line.delivered > 0)
        : [...this.filteredCostlines];

    this.createGroups(); // Recreate groups with filtered data
}

  toggleComponent(){ 
    this.showComponent = !this.showComponent;
    //if no costlines are fetched, fetch them
    if(this.showComponent && this.allCostlines.length === 0){
      this.loading.emit(true);
      this.fetchCostData();
      this.loading.emit(false);
    }
  }

  toggleUndelivered() {
    this.hideUndelivered = !this.hideUndelivered;
    this.recalculateFiltered();
  }
  
  selectAllOrders(selected: boolean) {
    this.orders.forEach(order => order.selected = selected);
    this.recalculateFiltered();
  }


  toggleAllGroups() {
    this.showDetails = !this.showDetails;
    const groups = this.viewMode === 'category' ? this.categoryGroups : this.orderGroups;
    groups.forEach(group => group.isOpen = this.showDetails);
  }

  // Get unique categories
  getCategories(): string[] {
    return [...new Set(this.allCostlines.map(line => line.category))];
  }

  // Get unique orders
  getOrders(): { order: string; title: string }[] {
    const uniqueOrders = new Map();
    this.allCostlines.forEach(line => {
      uniqueOrders.set(line.order, { order: line.order, title: line.ga_title, selected: true });
    });
    return Array.from(uniqueOrders.values());
  }

  // Get lines for a specific category
  getLinesForCategory(category: string): Line_cost_data[] {
    return this.filteredCostlines.filter(line => line.category === category);
  }

  // Get lines for a specific order
  getLinesForOrder(order: string): Line_cost_data[] {
    return this.filteredCostlines.filter(line => line.order === order);
  }

  getFilteredOrdersCount(): number {
    return this.orders.filter(o => o.selected).length;
  }

  // Calculate totals for a category
  getCategoryTotals( ) {
    this.categoryGroups.forEach(group => {

      group.lines.forEach(line => {
        group.totalDelivered = (group.totalDelivered || 0) + line.delivered;
        group.totalRequested = (group.totalRequested || 0) + line.requested;
        group.totalTotal = (group.totalTotal || 0) + line.total;
      }
      );
    }
    );
  }

  // Calculate totals for an order
  getOrderTotals( ) {
    this.orderGroups.forEach(group => {
      group.lines.forEach(line => {
        group.totalDelivered = (group.totalDelivered || 0) + line.delivered;
        group.totalRequested = (group.totalRequested || 0) + line.requested;
        group.totalTotal = (group.totalTotal || 0) + line.total;
      }
      );
    }
    );
  }

  // Calculate totals for all costlines
  getBigTotals() {
    this.filteredTotal = this.filteredCostlines.reduce((acc, line) => acc + line.total, 0);
    this.allTotal = this.allCostlines.reduce((acc, line) => acc + line.total, 0);
  
  }



  // Format currency
  formatCurrency(value: number): string {
    return new Intl.NumberFormat('it-IT', { 
      style: 'currency', 
      currency: 'EUR' 
    }).format(value);
  }

  async printCtrl(costlines : Line_cost_data[]) {
    this.loading.emit(true);
    this.ctrlMessage = "Genero il foglio di controllo, attendere...";
    let tracking_code = this.lead.tracking_code;
    
    // Header remains the same, just more readable what the first column represents
    const header :string [] = 
   [tracking_code, "ga_title", "state","id", "product", "category", "uom", "requested", "delivered", "cost", "total", "origin"];
    
    const Data = [];
    Data.push(header);
    //push costlines into Data  , but take string values!
    costlines.forEach((l) => {
      Data.push([tracking_code, l.ga_title, l.state, l.id, l.product, l.category, l.uom, l.requested.toString(), l.delivered.toString(), l.cost.toString(), l.total.toString(), l.origin]);
    }
    );
    var sheetid = await this.gapiService.printGenericSingleSheet(
      PRINTS_COSTLINES_CFG.template_sheet_id,
      PRINTS_COSTLINES_CFG.spool_folder_id,
      Data
      )
    //open sheet id in a new tab
    this.ctrlMessage = "";
    window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');
    this.loading.emit(false);
    }
}