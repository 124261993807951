<app-navbar [loading]="loading" backroute="..">
  <div class="d-flex justify-content-between align-items-center w-100">
  <a class="navbar-brand">
    Vendite
  </a>
  <ng-container>
    <div class="ms-auto dropdown sticky-top me-4">
      <a class="btn text-white btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        Nuovo
      </a>
      <ul class="dropdown-menu dropdown-menu-end p-3" style="min-width: 300px;">
  
          <div class="mb-3">
            <label class="form-label fw-bold">Contatto</label>
            <app-contact-picker2 [mode]="'embedded'" class="embedded overflow-hidden border-0" 
              (onSelect)="onContact($event)"></app-contact-picker2>
          </div>
          <button [disabled]="!newSale.partner_id.value" class="btn btn-primary text-white mt-3"
            (click)="$event.stopPropagation();create()">Crea</button>
 
      </ul>
    </div>
  </ng-container>
  </div>
</app-navbar>
<!-- Fixed filters section -->
<div class="bg-light px-3 py-3 w-100">
  <div class="row g-3">
    <div class="col-md-3 col-lg-3">
      <div class="input-group">
        <span class="input-group-text">Cliente</span>
        <input class="form-control" [ngModel]="searchInput | async" (ngModelChange)="searchInput.next($event)">
      </div>
    </div>
    <div class="col-md-3 col-lg-2">
      <select class="form-select" [(ngModel)]="selectedUser" (change)="load()">
        <option value="">Tutti gli utenti</option>
        <option *ngFor="let user of users" [value]="user">{{user}}</option>
      </select>
    </div>
    <div class="col-md-3 col-lg-4">
      <div class="input-group">
        <span class="input-group-text">Data ordine</span>
        <input type="date" class="form-control" [(ngModel)]="startDate" (change)="load()">
        <span class="input-group-text">a</span>
        <input type="date" class="form-control" [(ngModel)]="endDate" (change)="load()">
      </div>
    </div>
    <div class="col-md-12 col-lg-3">
      <div class="d-flex justify-content-start justify-content-lg-end align-items-center h-100">
        <div class="form-check form-check-inline me-3">
          <input class="form-check-input" type="checkbox" [(ngModel)]="filterToShip" (change)="load()" id="filterToShip">
          <label class="form-check-label" for="filterToShip">Da spedire</label>
        </div>
        <div class="form-check form-check-inline me-3">
          <input class="form-check-input" type="checkbox" [(ngModel)]="filterShipped" (change)="load()" id="filterShipped">
          <label class="form-check-label" for="filterShipped">Spedite</label>
        </div>
        <div class="form-check form-check-inline me-3">
          <input class="form-check-input" type="checkbox" [(ngModel)]="filterList" (change)="load()" id="filterList">
          <label class="form-check-label" for="filterList">Solo lista</label>
        </div>
        <button class="btn bg-info btn-sm ms-2 px-3 text-light" (click)="resetFilters()">Reset Filtri</button>
      </div>
    </div>
  </div>
</div>

 <!-- Scrollable table section -->
<div class="table-responsive" [hidden]="loading">
  <table class="table table-bordered align-middle mb-0">
    <thead class="bg-light">
      <tr>
        <th class="text-nowrap"></th>
        <th class="text-nowrap">Numero</th>
        <th>Cliente</th>
        <th>Creato da</th>
        <th class="text-nowrap">Data ordine</th>
        <th class="text-nowrap">Importo</th>
        <th class="text-nowrap">Produzione</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let s of sales" >
          <!-- here if there's opp id, show fa-solid fa-shop, otherwise fa-solid fa-square-list  -->
            <td class="text-wrap align-middle  text-center py-1">  
              <i *ngIf="s.opportunity_id.id " class="fa-lg fa fa-shop" title="Parte di commessa"></i>
              <i *ngIf="!s.opportunity_id.id " class="fa-lg fa fa-square-list" title="Vendita diretta"></i>
            </td>
          <td (click)="onRowClick(s.id)" class="text-nowrap">
            <span title="Spedito parzialmente" class="fa fa-circle text-warning "
              *ngIf="s.state == 'sale' && s.delivery_status == 'partial'"></span>
            <span title="Confermato - Non spedito" class="fa fa-circle text-primary "
              *ngIf="s.state == 'sale' && s.delivery_status == 'pending'"></span>
            <span title="Spedito interamente" class="fa fa-circle text-success "
              *ngIf="s.state == 'sale' && s.delivery_status == 'full'"></span>
            <span title="Confermato" class="fa fa-circle text-primary "
              *ngIf="s.state == 'sale' && !s.delivery_status"></span>
            <span title="Bozza" class="fa fa-circle text-dark " *ngIf="s.state == 'draft'"></span>
            <span title="Annullato" class="fa fa-circle  text-light" *ngIf="s.state == 'cancel'"></span>
            <span class="ms-3">{{s.name}}</span>
          </td>
          <td (click)="onRowClick(s.id)" class="text-muted">
            <strong>{{s.partner_id?.name}} </strong>{{s.ga_title ? ' - ' + s.ga_title : ''}}
          </td>

          <td (click)="onRowClick(s.id)" class="text-muted">
            {{s.user_id?.name}}
            <!-- {{p.group_id.value?.sale_id.value?.partner_id?.name}} -->
          </td>

          <td (click)="onRowClick(s.id)" class="text-muted">
            {{s.date_order | Date4Humans }}
          </td>
          <td (click)="onRowClick(s.id)" class="text-muted text-nowrap">
            € {{s.amount_untaxed | number : '1.0-3':'it-IT'}}
            <!-- {{p.scheduled_date | Date4Humans }} -->
          </td>
          <td class=" text-end">
            <div class="d-flex text-nowrap text-end align-items-center">

              <ng-container *ngIf="s.state == 'sale' && s.delivery_status">
                <app-activity-scheduler [sale]="s"></app-activity-scheduler>
              </ng-container>


              <app-connect-to class="d-block-inline text-nowrap text-end" faSize="fa-2x"
                [driveFolders]="s.drive_folder_ids.values" [connectable]="s" type="Produzione"
                [driveSource]="cfg?.produzione_src" [driveTarget]="cfg?.produzione_dst"
                [driveTargetTitle]="s.name + ' ' + (s?.ga_title ? s?.ga_title : '') + '-' + s.partner_id.name"
                (onConnect)="onConnect(s,$event)"
                [trelloTitle]="s.name + ' ' + (s?.ga_title ? s?.ga_title : '') + '-' + s.partner_id.name"
                [trelloCards]="s.trello_card_ids.values" [trelloListId]="cfg?.produzione_trello"
                [trelloBoardId]="cfg?.produzione_trello_board">
                <!-- [driveLinkTitle]="cfg.produzione_link_name + ' > ' + s.name + ' ' + (s?.ga_title ? s.ga_title : '')" -->
              </app-connect-to>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
