<div id="inventory">
  <div class="card rounded-0 sticky-top bg-dark">
    <div class="p-2 border-bottom">
      <div class="input-group">
        <!-- Main template button -->
        <button type="button" class="btn btn-outline-primary text-white text-nowrap"
          [ngClass]="{'bg-primary': activeTemplate?.id}" 
          [disabled]="!loadedTemplates"
          (click)="toggleProductTemplate(productToShow.id)">
          {{productToShow.name}}
          <span *ngIf="!loadedTemplates">
            <i class="fa fa-spinner fa-spin"></i>
          </span>
        </button>
      
        <!-- Loading/Dropdown toggle button -->
        <button type="button" class="btn btn-outline-primary text-white dropdown-toggle dropdown-toggle-split"
          [disabled]="!loadedTemplates" 
          data-bs-toggle="dropdown" 
          aria-expanded="false">
        </button>
      
        <!-- Dropdown menu (unchanged) -->
        <ul class="dropdown-menu p-0 shadow" style="z-index: 300000;">
          <div class="accordion border-0">
            @for (category of productTemplates | keyvalue; track category.key) {
            <div class="accordion-item border-0">
              <!-- Category header -->
              <button
                class="accordion-header link-underline-opacity-0 accordion-button collapsed text-muted w-100 text-start"
                (click)="$event.stopPropagation()" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse' + category.key.replaceAll(' / ','')">
                {{category.key}}
              </button>

              <!-- Category content -->
              <div [id]="'collapse' + category.key.replaceAll(' / ','')" class="accordion-collapse collapse"
                data-bs-parent="#accordionExample">
                <div class="accordion-body p-0">
                  <ul class="list-group list-group-flush">
                    @for (product of category.value; track product.id) {
                    <li class="list-group-item">
                      <a class="d-block text-decoration-none text-body py-1 px-2" href="javascript:void(0)"
                        (mouseup)="$event.stopPropagation(); toggleProductTemplate(product.id)">
                        {{product.name}}
                      </a>
                    </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
            }
          </div>
        </ul>
      
        <!-- Fondi filter button -->
        <button type="button" class="btn btn-outline-primary text-white text-nowrap ms-2"
          (click)="toggleFondi()"
          title="Mostra solo i fondi di magazzino"
          [class.btn-primary]="onlyFondi">
          <i class="fa-regular fa-trash-can-clock"></i>
        </button>
      
        <!-- Search input -->
        <input class="form-control border-primary ms-2" 
          type="search" 
          placeHolder="Cerca per nome/lunghezza"
          id="search-input" 
          autocomplete="off" 
          (ngModelChange)="inputSearch.next($event)"
          [ngModel]="inputSearch | async" 
          name="s1">
      </div>
    </div>



    <!--- Search by attribut -->
    <div class="card rounded-0">
      <!-- Table wrapper with sticky header -->
      <div class="table-responsive" style="height: calc(100vh - 100px);">
        <table class="table table-hover table-bordered mb-0">

          <thead class="sticky-top" style="z-index: 10;">
            @if (activeTemplate?.attribute_line_ids.values.length > 0) {
            <tr class="bg-white border-bottom shadow-sm">
              <th class="border-0" colspan="10">
                <div class="w-100 d-flex flex-wrap align-items-start p-2">

                  <ng-container *ngFor="let a of activeTemplate?.attribute_line_ids.values">
                    <div *ngIf="a.value_ids.ids.length != 1 && !a.attribute_id.name.startsWith('Lunghezza')"
                      (mousedown)="mousedown($event)" class="flex-wrap d-flex align-items-start">

                      <!-- Attribute name and check icon -->
                      <div class="text-nowrap w-100 me-3 mx-1" *ngIf="a.value_ids.values.length">
                        <span [ngClass]="{'text-danger': getIconClass(a).includes('text-danger'), 
                                        'text-success': getIconClass(a).includes('text-success'),
                                        'text-primary': getIconClass(a).includes('text-primary')}">
                          {{a.display_name}}
                        </span>
                        <i class="fa-solid ms-3" [ngClass]="getIconClass(a)"></i>
                      </div>


                      <!-- Values selector -->
                      <ng-container *ngIf="a.value_ids.values.length">
                        <!-- Dropdown for many values (>100) NOT USED ANYMORE -->
                        @if (a.value_ids.values.length > 100) {
                          <div class="d-flex flex-column">
                            <!-- Dropdown for selection -->
                            <div class="dropdown">
                              <button class="btn btn-link btn-sm dropdown-toggle" type="button"
                                [ngClass]="getTextClass(a)" 
                                data-bs-toggle="dropdown" 
                                aria-expanded="false">
                                {{getCriteriaName(a)}}
                              </button>
                            <ul class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
                              @for (v of a.value_ids.values | sortAsNumber; track v.id) {
                              <li>
                                <a class="dropdown-item" href="javascript:void(0)"
                                  [class.active]="hasCriteria({attributeLine: a, attributeValue: v})"
                                  (click)="toggleCriteria({attributeLine: a, attributeValue: v}); refresh$.next(true)">
                                  {{v?.name}}
                                </a>
                              </li>
                              }
                            </ul>
                          </div>
                        </div>
                        } @else {
                        <!-- Badge buttons for few values (≤10) -->
                        <span style="user-select: none;" *ngFor="let v of a.value_ids.values | sortAsNumber"
                          (click)="toggleCriteria({attributeLine: a, attributeValue: v}); refresh$.next(true)"
                          [ngClass]="{'bg-primary': hasCriteria({attributeLine: a, attributeValue: v})}"
                          class="badge text-dark me-1 mb-1" (mouseover)="mouseover($event)">
                          {{v?.name}}
                        </span>
                        }
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </th>
            </tr>
            }
            @if (loadingAttributes) {
            <tr class="bg-white border-bottom shadow-sm">
              <th class="border-0" colspan="10">
                <div class="w-100 d-flex flex-wrap align-items-start p-2">
                  <div class="spinner-border spinner-border-sm me-4" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Carico attributi dell'articolo selezionato...
                </div>
              </th>
            </tr>
            }


            <!-- intestazione della tabella risultati-->
            <tr class="bg-white border-bottom shadow-sm align-middle " style="top:0px">
              <th class="bg-white">
                <!-- dropdown to show legend of getReorderingClass  -->
          
                <div class="dropdown">
                  <button class="btn btn-link btn-sm text-dark"  data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fa-solid fa-info-circle"></i>
                  </button>
                  <ul class="dropdown-menu ">
                    <!-- dropdown title -->
                    <li class="dropdown-item ">
                      <h6>   Legenda icone</h6>
                    </li>
      
                    <li class="dropdown-item">
                      <i class="fa-solid fa-trash-clock text-danger"></i>
                      <span class="ms-2">Articolo in offerta, qualità non ottimale, costo ridotto </span>
                    </li>
                    <li class="dropdown-item">
                      <i class="fa-solid fa-trash-clock text-warning"></i>
                      <span class="ms-2">Articolo da terminare</span>
                    </li>
                    <li class="dropdown-item">
                      <i class="fa-solid fa-down-to-line text-warning"></i>
                      <span class="ms-2">Materiale non standard disponibile</span>
                    </li>
                    <li class="dropdown-item">
                      <i class="fa-solid fa-star text-success"></i>
                      <span class="ms-2">Articolo standard, con giacenza minima a magazzino</span>
                    </li>
                    <li class="dropdown-item">
                      <i class="fa-solid fa-cart-shopping text-body-secondary"></i>
                      <span class="ms-2">Materiale non standard, ordinato solo su richiesta </span>
                    </li>
                    
                    </ul>
                </div>            
              </th>
              <th class="bg-white">ID</th>
              <th class="bg-white" title="Descrizione prodotto">Prodotto</th>
              @if (showPhotos) {
              <th class="bg-white text-center" title="Pacchi e foto">
                Colli
                @if (!loaded && products &&products.length > 0 ) {
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                }
              </th>
              }
              <th class="bg-white text-center" title="Costo di acqusito del prodotto">Costo</th>
              <th class="bg-white text-center" title="Prezzo di vendita consigliato secondo listino standard">
                Vendita</th>
              <th class="bg-white text-center">UdM</th>
              <th class="bg-white">Qtà</th>
              <th class="bg-white" colspan="2">Qtà libera</th>
            </tr>
          </thead>
          <!-- Table body -->
          <tbody cdkDropList id="inventoryList" [cdkDropListConnectedTo]="saleIds" (cdkDropListDropped)="drop($event)">
            <!-- Empty state -->
            @if (products && products.length == 0 && loaded ) {
            <tr>
              <td colspan="9" class="text-center py-3">
                <i class="fa fa-search fa-2x mb-2 text-muted"></i>
                <p class="text-muted mb-0">Nessun prodotto trovato</p>
                @if (activeTemplate && !canCreateVariant()) {
                <p class="text-muted mb-0 text-center">
                  <i class="fa-solid fa-times text-danger"></i>
                  Per creare una nuova variante, seleziona tutti gli attributi che vuoi usare
                </p>
                }
              </td>
            </tr>
            }

            <!-- VARIANT -->
            <tr *ngIf="canCreateVariant() && products.length == 0">
              <td colspan="9">
                <button class="btn bg-primary" (click)="createVariant()">Crea nuova variante</button>
              </td>
            </tr>

            <tr cdkDrag [cdkDragDisabled]="noDrag" [cdkDragData]="p" 
            *ngFor="let p of products"
            class="cursor-pointer text-nowrap"
            [ngClass]="{'bg-light text-muted': p.qty_available <= 0}"
            >
              <td class="text-center py-2">
                <i class="fa-solid" [ngClass]="getReorderingClass(p)"></i>
              </td>
              <td class="text-wrap align-middle">
                {{p.id}}
              </td>
              <td class="text-nowrap align-middle py-1" (click)="insert(p)">
                <div class="d-flex align-items-center justify-content-between">
                  <!-- Product name and link -->
                  <div >
                    {{p.display_name.replaceAll(", -","")}}
                    <a class="text-wrap btn btn-link ms-1" title="Apri in Odoo"
                      href="https://o3.galimberti.eu/web#id={{p.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form"
                      target="_blank" (click)="$event.stopPropagation()">
                      <i class="text-wrap fa-solid fa-magnifying-glass my-0 py-1"></i>
                    </a>
                  </div>

                  <!-- Image dropdown -->
                  @if (p.image_1920) {
                  <div class="dropdown" (click)="$event.stopPropagation()">
                    <img [src]="'data:image/jpeg;base64,' + p.image_1920" class="cursor-pointer dropdown-toggle"
                      data-bs-toggle="dropdown" style="width: 32px; height: 32px; object-fit: cover;"
                      aria-expanded="false">
                    <div class="dropdown-menu dropdown-menu-end p-2">
                      <img [src]="'data:image/jpeg;base64,' + p.image_1920" class="img-fluid"
                        style="max-width: 800px; max-height: 800px;">
                    </div>
                  </div>
                  }
                </div>
              </td>

              <!-- Packs and photos -->
              @if (showPhotos) {
              <td class="text-nowrap align-middle text-center py-1 position-relative"
                (click)="$event.stopPropagation()">
                @if (loaded) {
                <app-packs-photos [productWithQuants]="p" from='product'></app-packs-photos>
                }
              </td>
              }
              <!-- pricing and quantitites-->
              <td class="text-nowrap align-middle text-center py-1">
                <span *ngIf="!isOffer(p)">
                  {{p.list_price | number : '1.2-2':'it-IT'}} €
                </span>

                  <span *ngIf="isOffer(p)">
                    <span class="text-decoration-line-through">
                      {{getOriginalCost (p)| number : '1.2-2':'it-IT'}} €
                    </span>
                    <br>
                    <span class="text-primary">
                      <i class="fa-solid fa-chevrons-down"></i>
                      {{p.list_price| number : '1.2-2':'it-IT'}} €
                    </span>
                  </span>
              </td>
              <td class="text-nowrap align-middle text-center py-1"
              [ngClass]="{'text-primary': isOffer(p)}">
              
              {{p._lst_price ? (p._lst_price | number :
                '1.2-2':'it-IT') : '-'}} €
              </td>
              <td class="text-nowrap align-middle text-center py-1">{{p.uom_id.name}} </td>
              <td class="text-nowrap align-middle text-body-tertiary py-1">{{p.qty_available | number :
                '1.1-1':'it-IT'}}
              </td>
              <td class="text-nowrap align-middle fw-bold"> {{ getFree(p) | number : '1.1-1':'it-IT' }} </td>
              <td class="text-nowrap align-middle fw-bold py-1">{{getInPzFast(p)}}</td>
            </tr>



          </tbody>
        </table>
      </div>
    </div>
  </div>