import { Component, OnInit, HostListener } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { StockQuant } from '../models/stock-quant';
import { ODOO_IDS } from '../models/deal';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { Product } from '../models/product.model';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import _ from 'lodash';  // Add this import
import { ProductAttributeValue } from '../models/product.attribute.value';
import { ProductAttribute } from '../models/product.attribute.model';
import { ProductTemplate } from '../models/product.template.model';
import { parse } from 'path';
import { parseConditionAttributes, parseConditionFields } from './parseConditions';
import { StockQuantPackage } from '../models/stock-quant-package';


export interface ColumnType {
  field: string; //call to the field (des not contain .value, used for Odoo query)
  fieldType: 'field' | 'function' | 'attribute'; //type of the field
  callString?: string; //call to the oddo field to display in the table (contains .value)
  label: string; //label to display in the header
  type: 'text' | 'number' | 'date' | 'currency'; //type of the field
  sort?: 'asc' | 'desc'; //sort order
  style: 'Collo' | 'Dettaglio' | 'Categoria' | 'Prodotto'; //style of the field
  selected: boolean; //if the column is selected
  searchInput?: string; //search input  
  total?: any; //total of the column
  totalSelected?: any; //total of the selected cells
}

const STARTING_COLUMNS: ColumnType[] = [
  {
    field: 'product_categ_id.name', fieldType: 'field', callString: 'product_categ_id.name',
    label: 'Categoria', type: 'text', style: 'Categoria', selected: true, total: 'Totale ricerca', totalSelected: 'Totale Selezionato'
  },
  {
    field: 'product_tmpl_id.id', fieldType: 'field', callString: 'product_tmpl_id.id',
    label: 'ID Prodotto', type: 'text', style: 'Prodotto', selected: true
  },
  {
    field: 'product_tmpl_id.name', fieldType: 'field', callString: 'product_tmpl_id.name',
    label: 'Prodotto', type: 'text', style: 'Prodotto', selected: true
  },
  {
    field: 'product_uom_id.name', fieldType: 'field', callString: 'product_uom_id.name',
    label: 'UdM', type: 'text', style: 'Prodotto', selected: true
  },
  {
    field: 'product_tmpl_id.qty_available', fieldType: 'field', callString: 'product_tmpl_id.value.qty_available',
    label: 'Giacenza', type: 'number', style: 'Prodotto', selected: true
  },
  {
    field: 'package_id.name', fieldType: 'field', callString: 'package_id.name',
    label: 'Collo', type: 'text', style: 'Collo', selected: true
  },
  {
    field: 'quantity', fieldType: 'field', callString: 'quantity',
    label: 'Quantità', type: 'number', style: 'Collo', selected: true
  },
  {
    field: 'available_quantity', fieldType: 'field', callString: 'available_quantity',
    label: 'Disponibile', type: 'number', style: 'Collo', selected: true
  },
  {
    field: 'reserved_quantity', fieldType: 'field', callString: 'reserved_quantity',
    label: 'Riservato', type: 'number', style: 'Collo', selected: true
  },
  {
    field: 'location_id.name', fieldType: 'field', callString: 'location_id.name',
    label: 'Ubicazione', type: 'text', style: 'Collo', selected: true
  },
  {
    field: 'create_date', fieldType: 'field', callString: 'create_date',
    label: 'Data Creazione', type: 'date', style: 'Collo', selected: true
  },
  {
    field: 'value', fieldType: 'field', callString: 'value',
    label: 'Valore', type: 'currency', style: 'Collo', selected: true
  },
  // custom created column
  {
    field: 'getInPzFast', fieldType: 'function',
    label: 'Pezzi', type: 'number', style: 'Dettaglio', selected: true
  },
  // base attributes columns
  {
    field: 'Lunghezza', fieldType: 'attribute',
    label: 'Lunghezza', type: 'number', style: 'Dettaglio', selected: true
  },
  {
    field: 'Larghezza', fieldType: 'attribute',
    label: 'Larghezza', type: 'number', style: 'Dettaglio', selected: true
  },
  {
    field: 'Altezza', fieldType: 'attribute',
    label: 'Altezza', type: 'number', style: 'Dettaglio', selected: true
  },
];


@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrl: './package-list.component.scss'
})
export class PackageListComponent implements OnInit {
  quants: StockQuant[] = [];
  inputSearch: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedPar: string
  selectedOrder: string
  loading: boolean = false;
  ODOO_IDS = ODOO_IDS;
  selectedAttributes: string[] = []; // To store selected attribute names
  showPhotos: boolean = false;
  nostalgic: boolean = false;
  loadedPacks: boolean = false;

  allColumns: ColumnType[]


  // Selection properties
  isSelecting = false;
  selectionStart: { row: number; col: number } | null = null;
  selectionEnd: { row: number; col: number } | null = null;
  selectedCells: Set<string> = new Set();
  lastSelection: { row: number; col: number } | null = null; // Store the last selection point for Shift key
  isRowSelectionMode = false;


  // New clipboard properties
  private hasClipboardPermission: boolean = false;
  private clipboardData: string[][] = [];
  private clipboardStartCell: { row: number; col: number } | null = null;


  constructor(private odooEm: OdooEntityManager) { 
    // Check for clipboard permission on component creation
    this.checkClipboardPermission();
  }

  async ngOnInit() {

    //fill all columns with the starting columns
    this.allColumns = [...STARTING_COLUMNS];
    this.refreshQuants();
  }

  async refreshQuants() {
    this.loading = true;
    try {

      let quants = await this.getFilteredQuants(this.allColumns.filter(col => col.selected)) //call odoo to get the quants with the filters

      let prods = [];
      await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), quants, 'product_id'));
      prods = quants.map(quant => quant.product_id.value);
      await firstValueFrom(this.odooEm.resolveArray(new ProductTemplateAttributeValue(), prods, 'product_template_attribute_value_ids'));

      // Assign resolved products back to ALL matching quants
      prods.forEach(prod => {
        quants.filter(quant => quant.product_id.id === prod.id)
          .forEach(quant => {
            quant.product_id.value.product_template_attribute_value_ids = prod.product_template_attribute_value_ids;
          });
      });

      // solve product_tmpl_id relationship to display data of the product
      await firstValueFrom(this.odooEm.resolveArrayOfSingle(new ProductTemplate(), prods, 'product_tmpl_id'));

      // Assign resolved products back to ALL matching quants
      prods.forEach(prod => {
        quants.filter(quant => quant.product_tmpl_id.id === prod.product_tmpl_id.id)
          .forEach(quant => {
            quant.product_tmpl_id = prod.product_tmpl_id;
          });
      });

      // Sort quants using lodash, for original order purposes
      const sortedQuants = _.orderBy(quants, [
        'product_categ_id.name',
        'product_tmpl_id.id',
        quant => quant.package_id?.name || 'Senza pacco'
      ], ['asc', 'asc', 'asc']);

      // Assign sequence numbers after sorting
      sortedQuants.forEach((quant, index) => {
        quant._sequence = index;
      });

      this.quants = sortedQuants;

      await this.createAttributeColumns(prods);

      console.log('Quants:', this.quants);
    } catch (error) {
      console.error('Error fetching quants:', error);
    } finally {
      this.calculateTotals();
      this.loading = false;
    }
  }

  async getFilteredQuants(columns: ColumnType[]): Promise<StockQuant[]> {
    this.showPhotos = false;
    //check if some attributes have a search input
    let filterAttributesCols = columns.filter(col => col.fieldType === 'attribute' && col.searchInput);
    let attributes = await firstValueFrom(this.odooEm.search<ProductAttribute>(new ProductAttribute(), [['name', 'in', filterAttributesCols.map(col => col.label)]]))
    //fetch all the values of the attributes
    let allValues = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), [['attribute_id', 'in', attributes.map(attr => attr.id)]], 10000))
    //create arrays for each column with the values that respond to the search condition
    var criteriagroups = {}
    filterAttributesCols.forEach(col => {
      criteriagroups[col.label] = allValues.filter(val => val.attribute_id.name === col.label && parseConditionAttributes(val.name, col.searchInput));
    })
    //create a list of criterias to search for. criterias work on the product so if i want to search quants 
    //i have to include the product_id in the search
    var cri: any = Object.entries(criteriagroups).map(([key, value]) => {
      let v = value as [{ id: number }]
      return ['product_id.product_template_attribute_value_ids', 'in', v.map(x => x.id)]
    })
   
    // Handle field conditions
    let filteredQuantColumns = columns.filter(col => col.fieldType !== 'attribute' && col.searchInput);
    
    // Handle each column's search condition
    filteredQuantColumns.forEach(col => {
      const searchValue = col.searchInput?.trim();
      if (searchValue) {
        if (searchValue.toLowerCase().includes(' or ')) {
          // Handle OR condition
          const [term1, term2] = searchValue.split(/\s+or\s+/i);
          cri.push('|');
          cri.push([col.field, 'ilike', term1.trim()]);
          cri.push([col.field, 'ilike', term2.trim()]);
        } else {
          // Handle single condition
          const parsedCondition = parseConditionFields(col.field, searchValue);
          cri.push(parsedCondition);
        }
      }
    });

    // Add base criteria
    cri.push(['product_id.qty_available', '>', 0]);
    cri.push(['location_id.usage', '=', 'internal']);
    cri.push(['product_id.active', '=', true]);

    console.log("CRITERIA AFTER ALL FIELDS", cri);

    let quants = await firstValueFrom(this.odooEm.search<StockQuant>(new StockQuant(), cri, 100));
    return quants;
}


  getValue(quant: StockQuant, col: ColumnType): any {
    //add case based on column filedType
    if (col.fieldType === 'field') {
      //if same as previous, return null
      if (this.hasSameValueAsPrevious(quant, col) || !col.callString) {
        return null;
      }
      return this.getFieldValue(quant, col.callString);
    }
    if (col.fieldType === 'function') {
      return this.getFunctionValue(quant, col.field);
    }
    if (col.fieldType === 'attribute') {
      return this.getAttributeValue(quant, col.field);
    }
  }

  getFieldValue(quant: StockQuant, string: string): any {
    // return the value of "quant+'.'+string"
    return eval(`quant.${string}`);

    //here i simply have to return the value of the field, ex quant.'field'
    // return field.split('.').reduce((obj, key) => obj ? obj[key] : null, quant);
  }

  getFunctionValue(quant: StockQuant, field: string): any { //here i list all possible functions and  call the right one
    if (field === 'getInPzFast') {
      return this.getInPzFast(quant);
    }
  }

  getAttributeValue(quant: StockQuant, attributeName: string): string {
    const attrValue = quant.product_id.value.product_template_attribute_value_ids.values
      .find(attr => attr.attribute_id.name === attributeName);
    return attrValue ? attrValue.name : '';
  }

  hasSameValueAsPrevious(quant: StockQuant, col: ColumnType): boolean {
    if (quant._sequence === 0) return false;
    let currentValue;
    let previousValue;

    //only for field style category and product, check if the value is the same as the previous one

    if (col.style === 'Categoria') {
      // Find the quant with the previous sequence
      const prevQuant = this.quants.find(q => q._sequence === quant._sequence - 1);
      if (!prevQuant) return false;
      currentValue = quant.product_categ_id.name;
      previousValue = prevQuant.product_categ_id.name;
      return currentValue === previousValue;
    }
    else if (col.style === 'Prodotto') {
      // Find the quant with the previous sequence
      const prevQuant = this.quants.find(q => q._sequence === quant._sequence - 1);
      if (!prevQuant) return false;
      currentValue = quant.product_tmpl_id.id; //confront only template product 
      previousValue = prevQuant.product_tmpl_id.id;
      return currentValue === previousValue;
    }
    else {
      return false;
    }

  }

  async createAttributeColumns(prods: Product[]) {
    // create an array with unique display_name of all attribute values
    let attributeNames = [];
    prods.forEach(prod => {
      prod.product_template_attribute_value_ids.values?.forEach(attr => {
        if (!attributeNames.includes(attr?.attribute_id.name)) {
          attributeNames.push(attr?.attribute_id.name);
        }
      });
    });
    // filter out attributes with name that contains 'extra'
    attributeNames = attributeNames.filter(attr => !attr.toLowerCase().includes('extra'));

    // add these attributes to the columns if not already present, confrontin column label with attribute name
    attributeNames.forEach(attr => {
      if (!this.allColumns.find(col => col.label === attr)) {
        this.allColumns.push({ field: attr, fieldType: 'attribute', label: attr, type: 'text', style: 'Dettaglio', selected: false });
      }
    });
    //ok lables are the ones in the starting columns and the ones in the attributeNames
    let okLabels = [...attributeNames, ...STARTING_COLUMNS.map(st => st.label)];
    //remove columns that are not in okLabels
    this.allColumns = this.allColumns.filter(col => okLabels.includes(col.label));

  }

  toggleColumn(column: ColumnType) {
    column.selected = !column.selected;
  }

  getPackageUrl(packageId: number): string {
    return `https://o3.galimberti.eu/web?debug=1#id=${packageId}&menu_id=223&cids=1&action=370&model=stock.quant.package&view_type=form`;
  }

  getProductUrl(productTemplateId: number): string {
    return `https://o3.galimberti.eu/web?debug=1#id=${productTemplateId}&menu_id=223&cids=1&action=392&model=product.template&view_type=form`;
  }

  handleSearch(event: { field: string, value: string }) {
    // Handle search with both field and value
    console.log(`Searching ${event.field} for ${event.value}`);
    this.inputSearch.next(event.value) //for now we do this cause we query only on product name
    // refresh quants with new search term

    this.allColumns.forEach(col => {
      if (col.field === event.field) {
        col.searchInput = event.value;
      }
    })
  }

  handleOrder(event: { field: string, sort: 'asc' | 'desc' }) {
    console.log(`Sorting ${event.field} in ${event.sort} order`);

    // Update sort indicators in columns
    this.allColumns.forEach(col => {
      col.sort = col.field === event.field ? event.sort : null;
    });

    const column = this.allColumns.find(col => col.field === event.field);
    if (!column) return;

    // Create array of objects containing the sort value and original quant
    const sortableQuants = this.quants.map((quant, originalIndex) => {
      let sortValue = this.getValue(quant, column);
      
      // Handle numeric attributes (caus ewe get them with the getvalue function)
      if (column.fieldType === 'attribute' && column.type === 'number' && typeof sortValue === 'string') {
        // Replace comma with dot for proper numeric parsing
        sortValue = parseFloat(sortValue.replace(',', '.')) || 0;
      }

      return {
        quant,
        sortValue,
        originalIndex,
        categoryValue: quant.product_categ_id?.name,
        productId: quant.product_tmpl_id?.id
      };
    });

    // Perform the sort while maintaining hierarchy
    const sortedQuants = _.orderBy(
      sortableQuants,
      [
        'sortValue',
        'categoryValue',
        'productId',
        'originalIndex'
      ],
      [
        event.sort,
        'asc',
        'asc',
        'asc'
      ]
    );

    // Update quants array and reassign sequences
    this.quants = sortedQuants.map((item, index) => {
      const quant = item.quant;
      quant._sequence = index;
      return quant;
    });

    // Force recalculation of totals after sort
    this.calculateTotals();
}

  clientiStyle(type: string) {
    if (!this.nostalgic) {
      if (type === 'Collo') {
        return 'text-white bg-secondary bg-opacity-75';
      }
      if (type === 'Dettaglio') {
        return 'text-dark bg-primary bg-opacity-25 ';
      }
      if (type === 'Categoria') {
        return 'text-dark bg-success bg-opacity-50';
      }
      if (type === 'Prodotto') {
        return 'text-dark bg-secondary bg-opacity-25';
      }
      return '';
    }
    // if we are nostalgic let's use the old style
    else {
      if (type === 'Collo') return 'nostalgic-collo';
      if (type === 'Dettaglio') return 'nostalgic-dettaglio';
      if (type === 'Categoria') return 'nostalgic-categoria';
      if (type === 'Prodotto') return 'nostalgic-prodotto';
      return '';
    }
  }
 
  getInPzFast(quant: StockQuant) {
    let product = quant.product_id.value;
    //find values of Lunghezza, Larghezza and Altezza inside the product_template_attribute_value_ids of the product_id
    let la = 0;
    let al = 0;
    let lu = 0;
    let pz = 0;
    let uom = quant.product_uom_id.id;
    let quantita = quant.quantity;
    product.product_template_attribute_value_ids.values?.forEach(attr => {
      if (attr.attribute_id.name === 'Lunghezza') {
        lu = parseFloat(attr.name);
      }
      if (attr.attribute_id.name === 'Larghezza') {
        la = parseFloat(attr.name);
      }
      if (attr.attribute_id.name === 'Altezza') {
        al = parseFloat(attr.name);
      }
    });

    //calcolo il numero di pezzi dalla quantità disponibile
    //se unità misura = metri cubi (uom id = 11) moltiplico tutte e 3 le dimensioni e divido per 1000000000
    if (uom == 11 && la && al && lu) {
      pz = la * al * lu / 1000000000;
    }
    //se unità misura = metri quadrati (uom id = 9) moltiplico le prime due dimensioni e divido per 1000000
    if (uom == 9 && la && lu) {
      pz = la * lu / 1000000;
    }
    //se unità misura = metri lineari (uom id = 5) divido la lunghezza per 1000
    if (uom == 5 && lu) {
      pz = lu / 1000;
    }
    if (pz > 0 && quantita > 0) {
      const result = Math.round((quantita / pz) * 100) / 100;
      return result
    }
    else {
      return '';
    }
  }

  async onShowPhotos() {
    this.loadedPacks = false;

    if (this.showPhotos) {
      this.loading = true;
      //we need to solve all package ids to send data to the photos component
      await firstValueFrom(this.odooEm.resolveArrayOfSingle(new StockQuantPackage(), this.quants, 'package_id'));
    console.log('Quants with package ids:', this.quants);
    this.loadedPacks = true;
    this.loading = false;
    }


  }



  // total calculation
  calculateTotals() {
    this.allColumns.forEach((col, colIndex) => {
      let total = 0;
      let totalSelected = 0;


      if (col.fieldType != 'attribute' && col.type != 'text') { //this is to exclude the attribute columns we should not sum them and categoria alresy has presets)
        if (col.type === 'number' || col.type === 'currency') {
          // Sum numeric values
          this.quants.forEach((quant, rowIndex) => {
            let value = this.getValue(quant, col);
            if (value && !isNaN(value)) {
              total += value;
              // Use actual indices instead of looking up column index
              if (this.isCellSelected(rowIndex, colIndex)) {
                totalSelected += value;
              }
            }
          });

          col.total = total ? total : null;
          col.totalSelected = totalSelected ? totalSelected : null;
        }
      }
    });
  }

  // ----------------------- ---------------------- selection methods

  onCellMouseDown(event: MouseEvent, row: number, col: number) {
    // Get click position relative to the table cell
    const cellRect = (event.target as HTMLElement).getBoundingClientRect();
    const clickX = event.clientX - cellRect.left;

    // If click is within first 50px, enter row selection mode
    if (clickX <= 50 && col === 0) {
      this.isRowSelectionMode = true;

      // Clear previous selection if not using modifier keys
      if (!event.shiftKey && !event.ctrlKey) {
        this.selectedCells.clear();
      }

      // Select all cells in the row
      this.allColumns.forEach((col, colIndex) => {
        if (col.selected) {
          this.selectedCells.add(`${row}-${colIndex}`);
        }
      });

      // Set selection points for drag operation
      this.selectionStart = { row, col: 0 };
      this.selectionEnd = { row, col: this.allColumns.length - 1 };
      this.lastSelection = { row, col: 0 };
    } else {
      this.isRowSelectionMode = false;
      // Normal cell selection logic
      if (event.shiftKey && this.lastSelection) {
        this.selectionStart = this.lastSelection;
        this.selectionEnd = { row, col };
        this.updateSelectedCells(false);
      } else if (event.ctrlKey) {
        this.selectionStart = { row, col };
        this.selectionEnd = { row, col };
        this.updateSelectedCells(false);
      } else {
        this.selectionStart = { row, col };
        this.selectionEnd = { row, col };
        this.updateSelectedCells(true);
      }

      this.lastSelection = { row, col };
    }

    this.isSelecting = true;
    event.preventDefault();
    this.calculateTotals();
  }

  onCellMouseEnter(event: MouseEvent, row: number, col: number) {
    if (this.isSelecting) {
      if (this.isRowSelectionMode) {
        // In row selection mode, select all cells from start row to current row
        const startRow = Math.min(this.selectionStart.row, row);
        const endRow = Math.max(this.selectionStart.row, row);

        for (let r = startRow; r <= endRow; r++) {
          this.allColumns.forEach((col, colIndex) => {
            if (col.selected) {
              this.selectedCells.add(`${r}-${colIndex}`);
            }
          });
        }
      } else {
        // Normal cell selection
        this.selectionEnd = { row, col };
        this.updateSelectedCells(false);
      }
      this.calculateTotals();
    }
  }

  onCellMouseUp() {
    this.isSelecting = false;
    this.isRowSelectionMode = false;
    this.calculateTotals();
  }

  updateSelectedCells(clearPrevious: boolean = true) {
    if (!this.selectionStart || !this.selectionEnd) return;

    if (clearPrevious) {
      this.selectedCells.clear();
    }

    const startRow = Math.min(this.selectionStart.row, this.selectionEnd.row);
    const endRow = Math.max(this.selectionStart.row, this.selectionEnd.row);
    const startCol = Math.min(this.selectionStart.col, this.selectionEnd.col);
    const endCol = Math.max(this.selectionStart.col, this.selectionEnd.col);

    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        this.selectedCells.add(`${row}-${col}`);
      }
    }

    // Check if entire rows are selected and update checkbox state
  }


  isCellSelected(row: number, col: number): boolean {
    return this.selectedCells.has(`${row}-${col}`);
  }


  getCellClasses(quant: StockQuant, col: ColumnType, rowIndex: number, colIndex: number): string {
    const baseClasses = [
      'align-middle',
      'py-1',
      (!this.hasSameValueAsPrevious(quant, col) ? this.clientiStyle(col.style) : 'border-top-0'),
      (col.field === 'product_categ_id.name' ? 'fw-bold' : '')
    ];

    if (this.isCellSelected(rowIndex, colIndex)) {
      baseClasses.push('selected');
    }

    return baseClasses.join(' ');
  }

  // ----------------------------------------------------  copy functionality - IMPROVED WITH CLIPBOARD API
  private async checkClipboardPermission(): Promise<void> {
    try {
      // Check if the Permissions API is supported
      if ('permissions' in navigator) {
        const permission = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
        this.hasClipboardPermission = permission.state === 'granted';
        
        // Listen for permission changes
        permission.addEventListener('change', () => {
          this.hasClipboardPermission = permission.state === 'granted';
        });
      }
    } catch (error) {
      // Some browsers might not support clipboard permissions
      console.warn('Clipboard permissions API not supported');
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    // Handle copy (Ctrl+C)
    if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
      event.preventDefault();
      await this.copySelectedCells();
    }
    
    // Handle paste (Ctrl+V)
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      event.preventDefault();
      await this.pasteFromClipboard();
    }

    // Toggle nostalgic mode with F4
    if (event.key === 'F4') {
      this.nostalgic = !this.nostalgic;
    }

    //refrsh on f9  
    if (event.key === 'F9') {
      this.refreshQuants();
    }
  }

  async copySelectedCells() {
    if (this.selectedCells.size === 0) return;

    try {
      const bounds = this.getSelectionBounds();
      if (!bounds) return;

      // Create selected data array
      const selectedData = this.createSelectionArray(bounds);
      
      // Create text/plain ClipboardItem
      const text = this.formatForClipboard(selectedData);
      
      // Create text/html ClipboardItem for rich copy support
      const html = this.createHtmlTable(selectedData);

      // Create ClipboardItem with multiple formats
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([text], { type: 'text/plain' }),
        'text/html': new Blob([html], { type: 'text/html' })
      });

      // Write to clipboard using the modern API
      await navigator.clipboard.write([clipboardItem]);
      
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
      // Fallback for browsers that don't support the modern Clipboard API
      this.copyToClipboardFallback(this.formatForClipboard(this.createSelectionArray(this.getSelectionBounds())));
    }
  }

  private copyToClipboardFallback(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback clipboard copy failed:', err);
    }
    document.body.removeChild(textArea);
  }

  async pasteFromClipboard() {
    try {
      // Try to read clipboard items
      const clipboardItems = await navigator.clipboard.read();
      
      for (const clipboardItem of clipboardItems) {
        // Check for available formats
        const types = clipboardItem.types;
        
        // Prefer HTML format if available
        if (types.includes('text/html')) {
          const blob = await clipboardItem.getType('text/html');
          const html = await blob.text();
          await this.handlePastedHtml(html);
          return;
        }
        
        // Fall back to plain text
        if (types.includes('text/plain')) {
          const blob = await clipboardItem.getType('text/plain');
          const text = await blob.text();
          await this.handlePastedText(text);
          return;
        }
      }
    } catch (error) {
      // Fall back to text-only paste
      try {
        const text = await navigator.clipboard.readText();
        await this.handlePastedText(text);
      } catch (fallbackError) {
        console.error('Clipboard paste failed:', fallbackError);
      }
    }
  }

  private async handlePastedHtml(html: string) {
    // Parse the HTML and extract data
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const table = doc.querySelector('table');
    
    if (table) {
      const data = this.extractDataFromTable(table);
      await this.applyPastedData(data);
    }
  }

  private async handlePastedText(text: string) {
    const rows = text.split('\n').map(row => row.split('\t'));
    await this.applyPastedData(rows);
  }

  private extractDataFromTable(table: HTMLTableElement): string[][] {
    const data: string[][] = [];
    const rows = table.querySelectorAll('tr');
    
    rows.forEach(row => {
      const rowData: string[] = [];
      const cells = row.querySelectorAll('td, th');
      cells.forEach(cell => rowData.push(cell.textContent?.trim() || ''));
      data.push(rowData);
    });
    
    return data;
  }

  private getSelectionBounds() {
    if (this.selectedCells.size === 0) return null;

    let minRow = Infinity, maxRow = -Infinity;
    let minCol = Infinity, maxCol = -Infinity;

    this.selectedCells.forEach(cell => {
      const [row, col] = cell.split('-').map(Number);
      minRow = Math.min(minRow, row);
      maxRow = Math.max(maxRow, row);
      minCol = Math.min(minCol, col);
      maxCol = Math.max(maxCol, col);
    });

    return { minRow, maxRow, minCol, maxCol };
  }

  private createSelectionArray(bounds: { minRow: number, maxRow: number, minCol: number, maxCol: number }): string[][] {
    const selectedArray: string[][] = [];

    for (let row = bounds.minRow; row <= bounds.maxRow; row++) {
      const rowData: string[] = [];
      for (let col = bounds.minCol; col <= bounds.maxCol; col++) {
        if (this.selectedCells.has(`${row}-${col}`)) {
          const quant = this.quants[row];
          const column = this.allColumns[col];
          if (quant && column) {
            rowData.push(this.formatCellValue(this.getValue(quant, column)));
          } else {
            rowData.push('');
          }
        } else {
          rowData.push('');
        }
      }
      selectedArray.push(rowData);
    }

    return selectedArray;
  }

  private formatCellValue(value: any): string {
    if (value === null || value === undefined) return '';
    if (typeof value === 'number') {
      return value.toLocaleString('it-IT');
    }
    return String(value);
  }

  private formatForClipboard(data: string[][]): string {
    return data.map(row => row.join('\t')).join('\n');
  }

  private createHtmlTable(data: string[][]): string {
    const table = document.createElement('table');
    
    data.forEach(rowData => {
      const row = table.insertRow();
      rowData.forEach(cellData => {
        const cell = row.insertCell();
        cell.textContent = cellData;
      });
    });
    
    return table.outerHTML;
  }

  private async applyPastedData(data: string[][]) {
    const targetCell = this.getTargetCell();
    if (!targetCell) return;

    // Implementation depends on your data model
    console.log('Applying pasted data starting at:', targetCell);
    console.log('Data:', data);
  }

  private getTargetCell(): { row: number, col: number } | null {
    if (this.selectedCells.size > 0) {
      const firstCell = Array.from(this.selectedCells)[0];
      const [row, col] = firstCell.split('-').map(Number);
      return { row, col };
    }
    
    if (this.lastSelection) {
      return { row: this.lastSelection.row, col: this.lastSelection.col };
    }

    return null;
  }
}