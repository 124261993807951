<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand"> Distinta pacchi </a>
</app-navbar>

<div class="d-flex flex-column" [ngClass]="{'nostalgic-mode': nostalgic}">
  <!-- Controls bar -->
  <div class="d-flex align-items-center gap-3 p-2 border-bottom">
    <!-- Column selector -->
    <div class="btn-group">
      <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
        Seleziona colonne
      </button>
      <ul class="dropdown-menu" style="z-index: 5000">
        @for (col of allColumns; track col.field){
          <div class="dropdown-item">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [checked]="col.selected" (change)="toggleColumn(col)">
              <label class="form-check-label">
                {{col.label}}
              </label>
            </div>
          </div>
        }
      </ul>
    </div>

    <!-- photo shower -->
    <div class="form-check form-switch">
      <input 
        class="form-check-input" 
        type="checkbox" 
        id="photoToggle" 
        [(ngModel)]="showPhotos"
        (ngModelChange)="onShowPhotos()">
      <label class="form-check-label" for="photoToggle">Mostra info pacco</label>
    </div>
    
    <!-- Refresh button -->
    <button class="btn btn-outline-primary" (click)="refreshQuants()" title="Aggiorna dati">
      <i class="fa-solid fa-rotate-right"></i>
    </button>
  </div>

  <!-- Table container with Excel-like selection -->
  <div class="table-responsive" [ngClass]="{'nostalgic-mode': nostalgic}">
    <table class="table table-bordered table-hover table-sm border-dark selectable-table">
      <!-- Main header - this contains col label and search bar -->
      <thead class="sticky-top">
        <tr class="bg-light">
          <th *ngFor="let col of allColumns" [hidden]="!col.selected">
            <app-table-search-header
              [col]="col"
              (search)="handleSearch($event)"
              (orderChange)="handleOrder($event)">
            </app-table-search-header>
          </th>
        </tr>


      <!-- second thead for totals, here we display total of all data and total selected style nostalgic if selected-->
   
        <!-- Total row -->
        <tr class="bg-muted" [ngClass]="{'nostalgic-mode': nostalgic}">
          <th *ngFor="let col of allColumns" [hidden]="!col.selected">
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'number'">
                {{col.total | number:'1.0-1':'it-IT'}}
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                {{col.total | number:'1.2-2':'it-IT'}} €
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{col.total}}
              </ng-container>
            </ng-container>
          </th>
        </tr>
        <!-- Selected total row -->
        <tr class="bg-muted" [ngClass]="{'nostalgic-mode': nostalgic}">
          <th *ngFor="let col of allColumns" [hidden]="!col.selected">
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="'number'">
                {{col.totalSelected | number:'1.0-1':'it-IT'}}
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                {{col.totalSelected | number:'1.2-2':'it-IT'}} €
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{col.totalSelected}}
              </ng-container>
            </ng-container>
          </th>
        </tr>
        
      </thead> 
    
      <tbody>
        <tr *ngFor="let quant of quants; let rowIndex = index" class="text-nowrap">
          <ng-container *ngFor="let col of allColumns; let colIndex = index">
            <td *ngIf="col.selected"
                [attr.data-row]="rowIndex"
                [attr.data-col]="colIndex"
                (mousedown)="onCellMouseDown($event, rowIndex, colIndex)"
                (mouseenter)="onCellMouseEnter($event, rowIndex, colIndex)"
                (mouseup)="onCellMouseUp()"
                [class]="getCellClasses(quant, col, rowIndex, colIndex)"
                [class.row-select-area]="colIndex === 0"
                [class.selected]="isCellSelected(rowIndex, colIndex)">
              <ng-container *ngIf="!hasSameValueAsPrevious(quant, col)">
                <!-- product template with clickable name -->
                <ng-container *ngIf="col.field === 'product_tmpl_id.name'">
                  <a [href]="getProductUrl(quant.product_tmpl_id.id)" 
                     target="_blank" 
                     [class]="getCellClasses(quant, col, rowIndex, colIndex)"
                     style="cursor: pointer; text-decoration: none; background: inherit; color: inherit;">
                    {{getValue(quant, col)}}
                  </a>
                </ng-container>
                <!-- Package name cell with link -->
                <ng-container *ngIf="col.field === 'package_id.name'">
                  <div class="d-flex align-items-center gap-2">
                    <ng-container *ngIf="getValue(quant, col); else noPack">
                      <a [href]="getPackageUrl(quant.package_id.id)" 
                         target="_blank" 
                         [class]="getCellClasses(quant, col, rowIndex, colIndex)"
                         style="cursor: pointer; text-decoration: none; background: inherit; color: inherit;">
                        {{getValue(quant, col)}}
                      </a>
                    </ng-container>
                    <ng-template #noPack>
                      <span>Senza pacco</span>
                    </ng-template>
                    <div (click)="$event.stopPropagation()" 
                        (mousedown)="$event.stopPropagation()">
                        <app-packs-photos 
                        class = "text-white"
                            *ngIf="quant.package_id?.id && showPhotos && loadedPacks && quant.package_id.value" 
                            from='package'   
                            [productWithQuants]="quant.product_id"                                    
                            [singleQuant]="quant">
                        </app-packs-photos>
                    </div>
                  </div>
                </ng-container>
      
                <!-- Other columns -->
                <ng-container *ngIf="col.field !== 'package_id.name' && col.field !== 'product_tmpl_id.name'">
                  <ng-container [ngSwitch]="col.type">
                    <ng-container *ngSwitchCase="'number'">
                      {{getValue(quant, col) | number:'1.0-1':'it-IT'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                      {{getValue(quant, col) | date:'dd/MM/yyyy'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'currency'">
                      {{getValue(quant, col) | number:'1.2-2':'it-IT'}} €
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{getValue(quant, col)}}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>