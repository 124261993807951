// table-search-header.component.ts
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ColumnType } from '../package-list.component';
@Component({
  selector: 'app-table-search-header',
  template: `
    <!-- Label -->

    <div class="d-flex justify-content-between align-items-center gap-1">
      <span>{{col.label}}</span>
      <!-- Sort button -->
      <button 
        class="btn btn-sm"
        [ngClass]="getButtonClass()"
        (click)="toggleOrder()">
        <i class="fa-light" [ngClass]="getIconClass()"></i>
      </button>
    </div>
    <!-- Search -->
    <div class="d-flex align-items-center gap-1">
      <!-- Search input -->
      <input 
        type="text" 
        class="form-control form-control-sm" 
        [(ngModel)]="searchValue"
        
        (blur)="onSearch(searchValue)">
    </div>

  `
})

export class TableSearchHeaderComponent implements OnInit {
  @Input() col: ColumnType;
  @Output() search = new EventEmitter<{field: string, value: string}>();
  @Output() orderChange = new EventEmitter<{field: string, sort: 'asc' | 'desc'}>();
  searchValue = '';

  ngOnInit() {
    this.searchValue = this.col.searchInput || '';
  }

  getButtonClass() {
    return {
      'btn-outline-info text-muted': !this.col.sort,
      'btn-info': this.col.sort
    };
  }

  getIconClass() {
    return {
      'fa-arrow-up-a-z':  this.col.sort === 'asc',
      'fa-arrow-down-z-a': this.col.sort === 'desc',
      'fa-arrows-up-down': !this.col.sort
    };
  }

  onSearch(value: string) {
    if (value !== this.col.searchInput) {  // Only emit if value has changed
      this.col.searchInput = value;
      this.search.emit({
        field: this.col.field,
        value: value
      });
    }
  }


  toggleOrder() {
    const newSort = !this.col.sort || this.col.sort === 'desc' ? 'asc' : 'desc';
    this.col.sort = newSort;
    
    this.orderChange.emit({
      field: this.col.field,
      sort: newSort
    });
  }
}