import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductWithOnlyVariants } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { first, firstValueFrom } from 'rxjs';
import { StockQuant } from '../models/stock-quant';
import { ODOO_IDS } from '../models/deal';
import { StockQuantPackage } from '../models/stock-quant-package';
import { IrAttachment, MailMessage } from '../models/mail.message';

@Component({
  selector: 'app-packs-photos',
  templateUrl: './packs-photos.component.html',
  styles: [`
    .dropdown-menu {
      width: auto;
      min-width: 200px;
    }
    .dropdown-menu.expanded {
      width: min(600px, calc(100vw - 100px));
    }
  `]
})

export class PacksPhotosComponent implements OnInit {

  @Input() from: 'product' | 'package'; 
  @Input() singleQuant?: StockQuant;  //if wre are showing only one package (ex package list) we pass the quant
  @Input() productWithQuants: ProductWithOnlyVariants;

  attachments:  boolean = false;
  messages: boolean = false;
  loaded: boolean = false;

  PacksToShow: StockQuantPackage[] = [];

  constructor(
    private odooEm: OdooEntityManager,
  ) {}

async ngOnInit() {
  this.loaded = false;
  if (this.singleQuant) {
  }

  await this.getValidPackages();
}

async getValidPackages() { //here we search packages with available quantity for the product
  if (this.from === 'package') { //if we are coming from package we have to show only that package
  if (this.singleQuant.package_id.id ) { 
    this.PacksToShow.push(this.singleQuant.package_id.value);
  }
  }
  else {
    if (this. from === 'product') { //if we are coming from product we have to check all quants

 //otherwise push into the array all packs with available quantity of the product if
  this.productWithQuants.stock_quant_ids.values?.forEach( async (quant: StockQuant) => {
    if (quant.package_id.id 
      && quant.available_quantity > 0
      && !ODOO_IDS.exclude_packs_location_ids.includes(quant.location_id.id)
      && quant.product_id.id === this.productWithQuants.id)  {
      this.PacksToShow.push(quant.package_id.value);
    }
  }

  );
  }
}
  if(this.PacksToShow.length > 0) {
    this.assignQuantities();
  }
  this.loaded = true;
}

async assignQuantities() { //assign the quantity of the product in the pack

  if (this.singleQuant) { //if single package we only need to show that one, so we have only one quant
    this.singleQuant.package_id.value._productQuantity = this.singleQuant.available_quantity;
    return;
  } 

  else {

  this.productWithQuants.stock_quant_ids.values?.forEach( async (quant: StockQuant) => { //else we have to search through all quants
    if (quant.package_id.id){
      let pack = this.PacksToShow.find(pack => pack.id === quant.package_id.id);
      if (pack) {
      pack._productQuantity = quant.available_quantity;
      }
    }
  })
}
  
}

async onClickPack(pack: StockQuantPackage, event: Event) {
  // Prevent the main dropdown from closing
  event.preventDefault();
  event.stopPropagation();
  //find attachments (photos)

    if (pack.message_attachment_count > 0) {
      pack._attachments = await firstValueFrom(
        this.odooEm.search<IrAttachment>(
          new IrAttachment(), 
          [
            ['res_id', '=', pack.id], 
            ['res_model', '=', 'stock.quant.package']
          ]
        )
      );
    this.attachments = true  
    }
    
    //set messages to true if there are messages
      if (pack.message_ids && pack.message_ids.ids.length > 0) {
  await firstValueFrom(this.odooEm.resolve(pack.message_ids));
    this.messages = true;
    if (this.messages) {
      console.log("MESSAGES: ", pack);
    }
  }
    
 
    
  }
}
