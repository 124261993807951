import { Component, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MailActivity, MailMessage } from 'src/app/models/mail.message';
import { MailNotification } from 'src/app/models/mail.notification';
import { User } from 'src/app/models/user.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';


//chrismaaassss

interface SnowflakeConfig {
  delay: number;
  duration: number;
  left: number;
  size: number;
}



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  @Input() backroute?:string
  @Input() loading
  @Input() leadId?:string

  unreadNotificationsLength?: number;
  activitiesLength?: number;
  userId: number;
  user: User;
  isMobileView: boolean = true;
  hideCalendar: boolean = false;


  // chrismaaaassss
  showSnowAnimation = false;
  snowflakeConfigs: SnowflakeConfig[] = [];
  pressTimer: any = null;
  progressInterval: any = null;
  isPressing = false;
  pressProgress = 0;
  readonly PRESS_DURATION = 2000; // 2 seconds in milliseconds



  activities: MailActivity[];
  notifications: MailNotification[];
  loaded: boolean = false;


  constructor(private odooEm: OdooEntityManager) {
    // Generate random configurations for 50 snowflakes
    this.generateSnowflakes();
  }

  //hide unnecessary eleemnts on mobile that load up 

  async ngOnInit()  {
    this.checkScreenSize();
  }


  private checkScreenSize() {
    this.isMobileView = window.innerWidth < 768; // Bootstrap's md breakpoint
    console.log('isMobileView', this.isMobileView);
  }



  // chrismaaaassss
  generateSnowflakes() {
    this.snowflakeConfigs = Array(100).fill(null).map(() => ({
      delay: Math.random() * 3,
      duration: 8 + Math.random() * 4,
      left: Math.random() * 100,
      size: 0.8 + Math.random() * 0.8
    }));
  }

  startPressTimer() {
    this.isPressing = true;
    this.pressProgress = 0;
    const startTime = Date.now();

    // Update progress every 50ms
    this.progressInterval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      this.pressProgress = Math.min(elapsed / this.PRESS_DURATION, 1);
      
      if (this.pressProgress >= 1) {
        this.clearProgressInterval();
      }
    }, 50);

    this.pressTimer = setTimeout(() => {
      this.toggleSnowAnimation();
      this.isPressing = false;
      this.pressProgress = 0;
    }, this.PRESS_DURATION);
  }

  clearProgressInterval() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
  }

  clearPressTimer() {
    if (this.pressTimer) {
      clearTimeout(this.pressTimer);
      this.pressTimer = null;
    }
    this.clearProgressInterval();
    this.isPressing = false;
    this.pressProgress = 0;
  }

  toggleSnowAnimation() {
    this.showSnowAnimation = true;
    this.generateSnowflakes();
    setTimeout(() => {
      this.showSnowAnimation = false;
    }, 8000);
  }
}