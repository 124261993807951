<div class="dropdown" style="z-index: 30000">
    <button class="btn btn-link position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown"
        data-bs-auto-close="false" aria-expanded="false" (click)="toggleOpen()" [disabled]="!loaded">

        <div class="position-relative d-inline-block" style="width: 24px; height: 24px;">
            <i class="fa-solid fa-lg fa-bell text-light"></i>

            <!-- Activities badge -->
            <span class="position-absolute badge text-white translate-middle" style="top: -5px; left: 5px;"
                *ngIf="activitiesLength > 0 && loaded" [ngClass]="{
                    'bg-danger': isLate,
                    'bg-primary': !isLate
                }">
                {{activitiesLength}}
            </span>

            <!-- Notifications badge -->
            <span class="position-absolute badge bg-warning text-white translate-middle" style="top: -5px; left: 20px;"
                *ngIf="unreadNotificationsLength > 0 && loaded">
                {{unreadNotificationsLength}}
            </span>

               
            <!-- if not loaded show spinner -->
            <span *ngIf="!loaded" class="spinner-border spinner-border-sm position-absolute " style="top: -5px; left: 10px;" role="status">
               </span>
              
        </div>
    </button>

    <div class="dropdown-menu dropdown-menu-end" style="width: 450px;" aria-labelledby="dropdownMenuButton">



        <div class="dropdown-header d-flex align-items-center justify-content-between border-bottom overflow-auto"
            style="z-index: 30000000; max-height: 40%">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'activities'"
                        (click)="setActiveTab('activities', $event ); ">
                        Attività <span class="badge ms-1" [ngClass]="{
                            'bg-danger': isLate,
                            'bg-primary': !isLate,
                        }">
                            {{activities.length}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [class.active]="activeTab === 'notifications'"
                        (click)="setActiveTab('notifications', $event)">
                        Notifiche <span class="badge bg-warning ms-1">{{unreadNotificationsLength}}</span>
                    </a>
                </li>
            </ul>
        </div>

        <!-- Activities Tab -->
        <div class="tab-content overflow-auto" style="z-index: 30000000;">

            <div class="tab-pane fade" [class.show]="activeTab === 'activities'"
                [class.active]="activeTab === 'activities'">

                <div class="px-3 py-2 border-bottom d-flex justify-content-between align-items-center sticky sticky-top bg-white">
                    <a href="/calendar" target="_blank" class="nav-link">
                      <i class="fa-solid fa-calendar me-2"></i>
                      Apri calendario
                    </a>
                  </div>

                  
                <!-- Loading State -->
                <div *ngIf="loading || !activitiesloaded" class="text-center p-3">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Carico...</span>
                    </div>
                </div>

                <!-- Empty State -->
                <div *ngIf="!loading &&  activitiesLength===0 " class="text-center p-3">
                    <p class="text-muted mb-0">Nessuna attività in programma</p>
                </div>
                @if (activitiesloaded){

                <!-- Activities Item - Updated Styling -->
                <div *ngFor="let activity of activities"
                    class="p-3 border-bottom mx-2 my-2 bg-white rounded shadow-sm hover:bg-gray-50 transition-all">
                    <!-- First Row: Date and Actions -->
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <span class="badge" [ngClass]="{
           'bg-danger': activity.state === 'overdue',
           'bg-warning': activity.state === 'today', 
           'bg-info': activity.state === 'planned'
           }">
                            {{activity.date_deadline | date:'dd/MM/yyyy'}}
                        </span>
                        <span class="d-flex align-items-center gap-2">
                            @if(findUser(activity.create_uid.name)?.image_256) {
                                <img 
                                    [src]="'data:image/png;base64,' + findUser(activity.create_uid.name).image_256" 
                                    class="rounded-circle flex-shrink-0" 
                                    style="width: 36px; height: 36px; object-fit: cover;"
                                    [alt]="activity.create_uid.name"
                                >
                            } @else {
                                <div class="rounded-circle bg-secondary d-flex align-items-center justify-content-center text-white flex-shrink-0"
                                    style="width: 36px; height: 36px; font-size: 20px;">
                                    {{activity.create_uid.name.charAt(0).toUpperCase()}}
                                </div>
                            }
                            <span class="text-truncate">{{activity.create_uid.name}}</span>
                        </span>

                        <div>
                            <button class="btn btn-link p-1 fs-5" (click)="openActivity(activity, $event)" title="Apri">
                                <i class="fa-solid fa-arrow-up-right-from-square text-primary"></i>
                            </button>
                            <!-- <button class="btn btn-link p-1 fs-5" (click)="deleteActivity(activity, $event)"
           title="Elimina attività">
           <i class="fa-solid fa-trash text-primary"></i>
       </button> -->
                            <button class="btn btn-link p-1 fs-5" (click)="completeActivity(activity, $event)"
                                title="Segna come completata">
                                <i class="fa-solid fa-check text-success"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Second Row: Output - Enhanced -->
                    <div class="text-primary fs-5 fw-medium mb-2" *ngIf="activity.res_model">
                        {{getOutput(activity)}}
                    </div>

                    <!-- Third Row: Display Name -->
                    <div class="fw-medium">{{activity.display_name}}</div>
                </div>
                }
            </div>
        </div>


        <!-- Notifications Tab -->
        <div class="tab-pane fade" style="z-index: 30000000;" [class.show]="activeTab === 'notifications'"
            [class.active]="activeTab === 'notifications'">
            <!-- Filter Toggle and Mark All Read -->
            <div
                class="px-3 py-2 border-bottom d-flex justify-content-between align-items-center sticky sticky-top bg-white">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="showUnreadOnly" [(ngModel)]="showUnreadOnly">
                    <label class="form-check-label" for="showUnreadOnly">Mostra solo non lette</label>
                </div>
                <button class="btn btn-link p-0" (click)="markAllAsRead()">
                    <small>Segna tutte come lette</small>
                </button>
            </div>

            <!-- Loading State -->
            <div *ngIf="loading || !notificationsloaded" class="text-center p-3">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Carico...</span>
                </div>
            </div>

            <!-- Empty State -->
            <div *ngIf="!loading && getFilteredNotifications().length === 0" class="text-center p-3">
                <p class="text-muted mb-0">Nessuna notifica da leggere</p>
            </div>
            @if (notificationsloaded){

            <!-- Notifications Item - Updated Styling -->
            <div *ngFor="let notification of getFilteredNotifications()"
                class="p-3 border-bottom mx-2 my-2 bg-white rounded shadow-sm hover:bg-gray-50 transition-all">
                <!-- First Row: Date and Actions -->
                <div class="d-flex justify-content-between align-items-center mb-2">
                    <span class="badge bg-light text-dark">
                        {{notification.mail_message_id.value?.date | date:'dd/MM/yyyy'}}
                    </span>

                    <!-- from name and image -->
                    <span class="d-flex align-items-center gap-2">
                        @if(findUser(notification.mail_message_id.value?.author_id.name)?.image_256) {
                            <img 
                                [src]="'data:image/png;base64,' + findUser(notification.mail_message_id.value?.author_id.name).image_256" 
                                class="rounded-circle flex-shrink-0" 
                                style="width: 36px; height: 36px; object-fit: cover;"
                                [alt]="notification.mail_message_id.value?.author_id.name"
                            >
                        } @else {
                            <div class="rounded-circle bg-secondary d-flex align-items-center justify-content-center text-white flex-shrink-0"
                                style="width: 36px; height: 36px; font-size: 20px;">
                                {{notification.mail_message_id.value?.author_id.name.charAt(0).toUpperCase()}}
                            </div>
                        }
                        <span class="text-truncate">{{notification.mail_message_id.value?.author_id.name}}</span>
                    </span>


                    <div>
                        <button class="btn btn-link p-1 fs-5" (click)="openNotification(notification, $event)"
                            title="Apri">
                            <i class="fa-solid fa-arrow-up-right-from-square text-primary"></i>
                        </button>
                        <button class="btn btn-link p-1 fs-5" *ngIf="!notification.is_read"
                            (click)="flagNotification(notification, $event)" title="Segna come letta">
                            <i class="fa-solid fa-check text-success"></i>
                        </button>
                        <button class="btn btn-link p-1 fs-5" *ngIf="notification.is_read"
                            (click)="flagNotification(notification, $event)" title="Segna come non letta">
                            <i class="fa-solid fa-rotate-left text-muted"></i>
                        </button>
                    </div>
                </div>

                <!-- Second Row: Output - Enhanced -->
                <div class="text-primary fs-5 fw-medium mb-2" *ngIf="notification.mail_message_id">
                    {{getOutput(notification)}}
                </div>

                <!-- Third Row: Message Body but swap "has just assigned you the following activity:" with "ti ha assegnato un'attività:" -->
                <div class="fw-medium"
                    [innerHTML]="notification.mail_message_id.value?.body.replace('has just assigned you the following activity:', 'ti ha assegnato un\'attività:')">
                </div>
            </div>

            }
        </div>
    </div>
</div>