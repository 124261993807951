<!-- Navbar -->
<app-navbar [loading]="loading" backroute="..">
  <div class="d-flex justify-content-between align-items-center w-100">

    <a class="navbar-brand">
      <span> Cerca </span>
    </a>
    <div *ngIf="package">
      <button class="btn  " (click)="toggleMessage()"
        [ngCLass]="{'text-primary': openMessage, 'text-light': !openMessage}">

        <i class="fa-solid fa-lg fa-comment fa-lg text-light"></i>
      </button>
    </div>
  </div>

</app-navbar>


<app-message-widget *ngIf="openMessage" [id]="package.id" [action]="834">
</app-message-widget>
<!-- Scanner -->
<app-scanner *ngIf="!package && !product" (onCode)="onCode($event)"></app-scanner>

<!-- Product Quantity Editor -->
<app-product-quantity-editor *ngIf="showQuantityEditor" [product]="product" (onSave)="onQuantity($event)"
  (onCancel)="showQuantityEditor = false">
</app-product-quantity-editor>



  <!-- Product View -->
  <div class="container-fluid px-3 py-4" *ngIf="product">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-start mb-3">
      <div>
        <h1 class="display-6 mb-0">{{ product.display_name }}</h1>
        <p class="text-muted mb-0" *ngIf="productAvailable">{{ quants[0]?.location_id?.name }}</p>
      </div>
      <span class="dropdown" *ngIf="productAvailable">
        <button class="btn btn-primary" type="button" data-bs-toggle="dropdown">
          <i class="fa-solid fa-bars"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end">
          <li>
            <h6 class="dropdown-header">Consuma</h6>
          </li>
          <li><a class="dropdown-item" (click)="onConsume(quants[0], O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume)">Case</a>
          </li>
          <li><a class="dropdown-item" (click)="onConsume(quants[0], O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume)">Massello/pavimenti</a>
          </li>
          <li><a class="dropdown-item" (click)="onConsume(quants[0], O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume)">Montaggio</a>
          </li>
          <li><a class="dropdown-item" (click)="onConsume(quants[0], O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenzioni_consume)">Lavori interni</a></li>
          <li>
          <h5 class="dropdown-header text-center">Modifica</h5>
        </li>
    
        <li>
          <a class="dropdown-item"
            (click)=" onCreateScrap(quants[0])">
            Segnala offerta
          </a>
        </li>
        </ul>
      </span>
    </div>

    <hr class="mb-4">

    <!-- Content -->
    <div *ngIf="!productAvailable">
      <h6 class="text-muted">Nessuna disponibiltà per il prodotto selezionato</h6>
    </div>

    <div *ngIf="productAvailable">
      <div class="row g-4 mb-3">
        <div class="col-6">
          <p class="text-muted mb-1">Quantità</p>
          <h5 class="mb-1">{{ quants[0]?.quantity | number : '1.0-2':'it-IT' }} {{ product.uom_id.name }}</h5>
          <small class="text-muted">{{ getDescriptiveBarcode(product, quants[0]?.quantity) }}</small>
        </div>
        <div class="col-6">
          <p class="text-muted mb-1">Disponibile</p>
          <h5 class="mb-1">{{ quants[0]?.available_quantity | number : '1.0-2':'it-IT'}} {{ product.uom_id.name }}</h5>
          <small class="text-muted">{{ getDescriptiveBarcode(product, quants[0]?.available_quantity) }}</small>
        </div>
      </div>
    </div>
  </div>

  <!-- Package Section -->
  <div class="container-fluid px-3 py-4" *ngIf="package">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-start mb-3 me-2">
      <div>
        <h1 class="display-6 mb-0">{{ package.name }}</h1>
        <p class="text-muted mb-0">{{ package.location_id.name }}</p>
      </div>
      <div class="dropdown">
        <button (click)="loadLocations()" class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown">
          Stocca
        </button>
        <div class="dropdown-menu dropdown-menu-dark" style="max-height: 80vh; overflow: visible">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item" *ngFor="let g of locationGroups | keyvalue">
              <h2 class="accordion-header">
                <button (click)="$event.stopPropagation()" class="accordion-button" type="button"
                  data-bs-toggle="collapse" [attr.data-bs-target]="'#loc' + g.key.replaceAll(' ', '')">
                  {{ g.key }}
                </button>
              </h2>
              <div id="loc{{ g.key.replaceAll(' ', '') }}" class="accordion-collapse collapse"
                data-bs-parent="#accordionExample">
                <div class="accordion-body p-0">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" *ngFor="let v of g.value" (click)="moveTo2(v)">
                      {{ v.display_name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="mb-4">

    <!-- Products -->
    <div class="row g-3">
      <div class="col-12" *ngFor="let q of package?.quant_ids?.values">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-start mb-3">
              <div class="d-flex flex-column mb-2">
                <h6 class="mb-1 me-2">{{ getBaseProductName(q.product_id?.name) }}</h6>
                <small class="text-muted" *ngIf="hasParentheses(q.product_id?.name)">
                  {{ getParenthesesContent(q.product_id?.name) }}
                </small>
              </div>
              <div class="dropdown">
                <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="dropdown">
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end">
                  <li>
                    <h5 class="dropdown-header text-center">Consuma</h5>
                  </li>
                  <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume, package)">Case</a>
                  </li>
                  <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume, package)">Massello/pavimenti</a>
                  </li>
                  <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume, package)">Montaggio</a>
                  </li>
                  <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenizoni_consume, package)">Lavori interni</a></li>
                  <li>
                    <h5 class="dropdown-header text-center">Modifica</h5>
                  </li>
                  <li>
                    <a class="dropdown-item" [class.disabled]="!isModifiableProduct(q.product_id.value)"
                      [style.text-decoration]="!isModifiableProduct(q.product_id.value) ? 'line-through' : 'none'"
                      (click)="isModifiableProduct(q.product_id.value) && onModifyAttrs(q, package)">
                      Attributi
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item"
                      (click)=" onCreateScrap(q, package)">
                      Segnala offerta
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <p class="text-muted mb-1">Quantità</p>
                <h6 class="mb-1">{{ q.quantity | number:'1.0-2':'it-IT' }} {{ q.product_uom_id.name }}</h6>
                <small class="text-muted">{{ getDescriptive(q, q.quantity) }}</small>
              </div>
              <div class="col-6">
                <p class="text-muted mb-1">Disponibile</p>
                <h6 class="mb-1">{{ q.available_quantity | number:'1.0-2':'it-IT' }} {{ q.product_uom_id.name }}</h6>
                <small class="text-muted">{{ getDescriptive(q, q.available_quantity) }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-modify-attributes (onDone)="onAttrsEditDone($event)" *ngIf="modifyingAttrsOfQuant" [quant]="modifyingAttrsOfQuant"
    (onCancel)="onCancel($event)"></app-modify-attributes>
  
  
    <app-scraps *ngIf="showingScraps"
    [quant]="scrapQuant"
    [sourcePackage]="scrapSourcePackage"
    (onCancel)="showingScraps = false"
    (onConfirm)="onScrapConfirm($event)">
  </app-scraps>

  <!-- Transfers Section -->
  <div class="container-fluid px-3 pb-5" *ngIf="package || product">
    <div class="d-flex justify-content-end mb-3">
      <button class="btn btn-outline-primary btn-sm" (click)="toggleTransfers()">
        {{ showTransfers ? 'Nascondi' : 'Mostra' }} impegni
      </button>
    </div>
    <!-- Package Transfers -->
<div *ngIf="showTransfers && moveLines?.length > 0 && package">
  <hr class="mb-4">
  <div class="row g-3">
    <div class="col-12" *ngFor="let t of moveLines">
      <div class="card">
        <div class="card-body">
          <!-- Product name and variants -->
          <div class="d-flex flex-column mb-2">
            <h6 class="mb-1 me-2">{{ getBaseProductName(q.product_id?.name) }}</h6>
            <small class="text-muted" *ngIf="hasParentheses(q.product_id?.name)">
              {{ getParenthesesContent(q.product_id?.name) }}
            </small>
          </div>
          <!-- Quantity and operation -->
          <div class="d-flex justify-content-between mb-2">
            <p class="mb-0">
              {{ getMLQuantity(t) | number:'1.0-2':'it-IT' }}
              <small class="text-muted">{{ t.product_uom_id?.name }}</small>
            </p>
            <span class="text-muted">{{ GetMLOperationType(t.picking_type_id.name)}}</span>
          </div>
          <!-- Contact info -->
          <p class="mb-0 text-muted">
            <small>
              {{ t.move_id?.value?.group_id?.name || '' }}
              {{ t.move_id?.value?.partner_id?.name ? '- ' + t.move_id?.value?.partner_id?.name : '' }}
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Product Transfers -->
<div *ngIf="showTransfers && moves?.length > 0 && product">
  <hr class="mb-4">
  <div class="row g-3">
    <div class="col-12" *ngFor="let t of moves">
      <div class="card">
        <div class="card-body">
          <!-- Quantity and operation -->
          <div class="d-flex justify-content-between mb-2">
            <p class="mb-0">
              {{ t.product_uom_qty | number:'1.0-2':'it-IT' }}
              <small class="text-muted">{{ t.product_uom.name }}</small>
            </p>
            <span class="text-muted">{{ GetMLOperationType(t.picking_type_id.name)}}</span>
          </div>
          <!-- Contact info -->
          <p class="mb-0 text-muted">
            <small>
              {{ t.group_id?.name || '' }}
              {{ t.partner_id?.name ? '- ' + t.partner_id?.name : '' }}
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Footer -->
  <div class="navbar-footer bg-dark fixed-bottom d-flex p-2 gap-2" *ngIf="!modifyingAttrsOfQuant">
    <button class="btn btn-primary flex-grow-1 text-white" (click)="package = null; product = null">
      Scansiona ancora
    </button>
  </div>