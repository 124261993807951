<div class="card shadow-sm mb-4">
  <!-- Card header -->
  <div class="card-header px-3 d-flex align-items-center justify-content-between" style="height: 48px">
    <h5 class="card-title mb-0">Controllo costi</h5>
    <div>
      <button class="btn btn-link" title="Espandi" (click)="toggleComponent()">
        <i class="fa-solid fa-arrows-up-down fa-lg"></i>
      </button>
    </div>
  </div>

  @if (showComponent) {
  <div *ngIf="ctrlMessage" class="p-2 text-primary">{{ctrlMessage}}</div>

  <!-- Warning messages section -->
  @if (!hideUndelivered && SomeUndelivered) {
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    <strong>Nota:</strong> Per i prodotti non ancora consegnati, il costo è stimato sulla quantità ordinata.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  }
  @if (filteredCostlines.length > 0) {
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    <strong>Nota:</strong> Questa vista mostra solo prodotti e servizi nelle note. Per includere servizi, pose e altri
    elementi, utilizzare la funzione "Esporta".
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
  }

  <!-- Filters and actions -->
  <div class="p-3 border-bottom">
    <div class="d-flex gap-2 flex-wrap justify-content-end align-items-center">

      <button class="btn btn-outline-muted" (click)="toggleAllGroups()">
        {{ showDetails ? 'Comprimi gruppi' : 'Espandi gruppi' }}
      </button>

      <!-- View mode radio buttons -->
      <div class="btn-group" role="group">
        <input type="radio" class="btn-check" name="viewMode" id="categoryView" [(ngModel)]="viewMode" value="category"
          (change)="toggleView('category')">
        <label class="btn btn-outline-muted" for="categoryView">Per Categoria</label>

        <input type="radio" class="btn-check" name="viewMode" id="orderView" [(ngModel)]="viewMode" value="order"
          (change)="toggleView('order')">
        <label class="btn btn-outline-muted" for="orderView">Per Ordine</label>
      </div>

      <!-- Orders filter dropdown -->
      <div class="dropdown">
        <button class="btn btn-outline-muted dropdown-toggle" type="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          Filtra ordini
          <span class="badge bg-primary ms-1">{{getFilteredOrdersCount()}}</span>
        </button>
        <div class="dropdown-menu p-3" style="min-width: 300px;">
          <!-- Select/Deselect all -->
          <div class="d-flex justify-content-between mb-2 border-bottom pb-2">
            <button class="btn btn-sm btn-outline-primary" (click)="selectAllOrders(true)">
              Seleziona tutti
            </button>
            <button class="btn btn-sm btn-outline-muted" (click)="selectAllOrders(false)">
              Deseleziona tutti
            </button>
          </div>
          <!-- Orders list -->
          <div style="max-height: 300px; overflow-y: auto;">
            <div *ngFor="let order of orders" class="form-check mb-2">
              <input class="form-check-input" type="checkbox" [id]="'order-' + order.order" [(ngModel)]="order.selected"
                (change)="recalculateFiltered()">
              <label class="form-check-label text-nowrap" [for]="'order-' + order.order">
                {{order.order}} - {{order.title}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Show undelivered toggle -->
      <button class="btn" [class.btn-muted]="hideUndelivered" [class.btn-outline-muted]="!hideUndelivered"
        (click)="toggleUndelivered()">
        {{ hideUndelivered ? 'Includi non consegnati' : 'Escludi non consegnati' }}
      </button>
      <!-- esporta -->
      <button class="btn btn-outline-muted" (click)="printCtrl(allCostlines)">
        <i class="fa-solid fa-file-export me-2"></i>Esporta
      </button>
    </div>
  </div>


  <!-- Table container -->
  <div class="table-responsive" *ngIf="filteredCostlines.length > 0">
    <table class="table table-hover">
      <thead class="table-light border-bottom">
        <tr>
            <th class="bg-light">Gruppo</th>
            <th class="bg-light">Prodotto</th>
            <th class="bg-light">UdM</th>
            <th class="bg-light text-end">Richiesto</th>
            <th class="bg-light text-end">Consegnato</th>
            <th class="bg-light text-end">Fonte</th>
            <th class="bg-light text-end">Costo</th>
            <th class="bg-light text-end">Totale</th>
        </tr>
    </thead>

      <tbody>
        <ng-container *ngFor="let group of selectedGroups">
          <!-- Group summary row -->
          <tr (click)="group.isOpen = !group.isOpen" style="cursor: pointer">
            <td>
              <i [class]="group.isOpen ? 'fa-solid fa-caret-down me-2' : 'fa-solid fa-caret-right me-2'"></i>
              <strong>{{group.groupName}}</strong>
            </td>
            <td></td>
            <td></td>
            <td class="text-end">{{group.totalRequested | number:'1.0-2':'en-EN'}}</td>
            <td class="text-end">{{group.totalDelivered | number:'1.0-2':'en-EN'}}</td>
            <td></td>
            <td></td>
            <td class="text-end"><strong>{{formatCurrency(group.totalTotal)}}</strong></td>
          </tr>

          <!-- Detailed product rows -->
          <ng-container *ngIf="group.isOpen">
            <tr *ngFor="let line of group.lines">
              <td>
                @if (viewMode === 'category') {
                {{line.order}} - {{line.ga_title}}
                }
                @if (viewMode === 'order') {
                {{line.category}}
                }
              </td>
              <td>{{line.product}}</td>
              <td>{{line.uom}}</td>
              <td class="text-end">{{line.requested | number:'1.0-2':'en-EN'}}</td>
              <td class="text-end">{{line.delivered | number:'1.0-2':'en-EN'}}</td>
              <td class="text-end">{{line.origin}}</td>
              <td class="text-end">{{formatCurrency(line.cost)}}</td>
              <td class="text-end">{{formatCurrency(line.total)}}</td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
      <!-- Footer with distinct styling -->
      <tfoot class="border-top">
        <tr class="table-secondary">
          <td class="fw-bold">TOTALE RIGHE FILTRATE</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end fw-bold">{{formatCurrency(filteredTotal)}}</td>
        </tr>
        <tr class="table-primary">
          <td class="fw-bold">TOTALE COMPLESSIVO</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end fw-bold">{{formatCurrency(allTotal)}}</td>
        </tr>
      </tfoot>
    </table>

    <!-- Empty state -->
    <div *ngIf="filteredCostlines.length === 0" class="p-4 text-center text-muted">
      <p>Nessun dato disponibile. Clicca "Aggiorna dati" per caricare i costi.</p>
    </div>
  </div>
  }
</div>