<div class="d-flex flex-column overflow-none">
    <!-- Navbar -->
    <app-navbar [loading]="loading" backroute=".." claas="overflow-hidden">
        <a class="navbar-brand">
            Calendario di {{user?.name}}
        </a>
    </app-navbar>

    <!-- Split View Container -->
    <div class="split-view">
        <!-- Side Panel -->
        <div class="side-panel">
            <!-- Year Section -->
            <h5>Anno</h5>
            <div class="year-selector">
                <select [(ngModel)]="currentYear" (change)="selectYear()">
                    <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
                </select>
            </div>

            <!-- Month Section -->
            <h5>Mese</h5>
            <div class="month-grid">
                <button *ngFor="let month of months; let i = index" [class.active]="currentDate.getMonth() === i"
                    (click)="selectMonth(i)">
                    {{ month.substring(0, 3) }}
                </button>
            </div>

            <!-- Filters Section -->
            <div class="filters-section">
                <!-- Activity Types -->
                <div class="filter-group">
                    <label>Tipo di Attività</label>
                    <div class="checkbox-list">
                        <div class="form-check" *ngFor="let type of activityTypes">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="type.selected"
                                (change)="applyFilters()">
                            <label class="form-check-label">{{ type.name }}</label>
                        </div>
                    </div>
                </div>

                <!-- Model Types -->
                <div class="filter-group">
                    <label>Tipo di Oggetto</label>
                    <div class="checkbox-list">
                        <div class="form-check" *ngFor="let model of modelTypes">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="model.selected"
                                (change)="applyFilters()">
                            <label class="form-check-label">{{ model.label }}</label>
                        </div>
                    </div>
                </div>

                <!-- Status -->
                <div class="filter-group">
                    <label>Stato</label>
                    <div class="checkbox-list">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="filters.showOverdue"
                                (change)="applyFilters()">
                            <label class="form-check-label">In Ritardo</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="filters.showToday"
                                (change)="applyFilters()">
                            <label class="form-check-label">Oggi</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="filters.showPlanned"
                                (change)="applyFilters()">
                            <label class="form-check-label">Pianificate</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main Content -->
        <div class="main-content ">
            <!-- Calendar View -->
            <div class="calendar-section overflow-scroll">

                <!-- Main Calendar Area -->
                <div class="calendar-main">
                    <!-- Calendar Header -->
                    <div class="calendar-header">
                        <div class="nav-section">
                            <div class="nav-buttons">
                                <button (click)="previousMonth()">
                                    <i class="fa-solid fa-chevron-left"></i>
                                </button>
                                <button (click)="nextMonth()">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                            <h2>{{ currentMonth }} {{ currentYear }}</h2>
                        </div>
                        <button class="today-button" (click)="goToToday()">Oggi</button>
                    </div>

                    <!-- Calendar Grid -->
                    <div class="calendar-grid">
                        <div class="weekdays">
                            <div class="weekday" *ngFor="let day of weekDays">{{ day }}</div>
                        </div>
                        <div class="days">
                            <div *ngFor="let day of calendarDays" class="day" [class.other-month]="day.otherMonth"
                                [class.today]="isToday(day.date)">
                                <div class="day-number">{{ day.date.getDate() }}</div>
                                <div class="activities">
                                    <div *ngFor="let activity of getFilteredActivitiesForDay(day.date)" class="activity"
                                        [class.overdue]="activity.state === 'overdue'"
                                        [class.today]="activity.state === 'today'"
                                        [class.planned]="activity.state === 'planned'"
                                        [class.open]="selectedActivity?.id === activity.id"
                                        (click)="toggleActivityDetails($event, activity)">
                                        <!-- Simplified Activity Display with output a truncated activity display-->
                                        <div class="activity-title">
                                            {{ getOutput(activity) }}
                                        </div>
                                        <div class="activity-description text-muted">
                                            {{ activity.display_name.substring(0, 50) }}
                                            {{ activity.display_name.length > 50 ? '...' : '' }}
                                        </div>

                                        <!-- Activity Dropdown -->
                                        <div *ngIf="selectedActivity?.id === activity.id" class="activity-dropdown"
                                            (click)="$event.stopPropagation()">
                                            <div class="activity-details">
                                                <!-- Header with date and status -->
                                                <div class="details-header">
                                                    <span class="badge" [ngClass]="{
                                                        'bg-danger': activity.state === 'overdue',
                                                        'bg-warning': activity.state === 'today',
                                                        'bg-info': activity.state === 'planned'
                                                    }">
                                                        {{activity.date_deadline | date:'dd/MM/yyyy'}}
                                                    </span>
                                                    <!-- Creator info -->
                                                    <div class="creator-info">
                                                        <img *ngIf="findUser(activity.create_uid.name)?.image_256"
                                                            [src]="'data:image/png;base64,' + findUser(activity.create_uid.name).image_256"
                                                            class="avatar">
                                                        <span>{{activity.create_uid.name}}</span>
                                                    </div>
                                                </div>

                                                <!-- Activity Content -->
                                                <div class="details-content">
                                                    <p class="activity-description">
                                                        {{getOutput(activity)}}
                                                    </p>
                                                </div>

                                                  <!-- Activity summary -->
                                                  <div class="details-content">
                                                    <p class="activity-description">
                                                        {{activity.display_name}}
                                                    </p>
                                                </div>


                                                <!-- Action Buttons -->
                                                <div class="details-actions">
                                                    <button class="btn btn-sm btn-outline-primary"
                                                        (click)="openActivity(activity)">
                                                        <i class="fa-solid fa-arrow-up-right-from-square me-1"></i>
                                                        Apri
                                                    </button>
                                                    <button class="btn btn-sm btn-success"
                                                        (click)="completeActivity(activity)">
                                                        <i class="fa-solid fa-check me-1"></i>
                                                        Completa
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Activities List -->
                <div class="activities-list-section">
                    <!-- List Header -->
                    <div class="list-header">
                        <h5 class="mb-0">Tutte le Attività</h5>
                        <div class="header-actions">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm" placeholder="Cerca..."
                                    [(ngModel)]="searchTerm" (ngModelChange)="filterActivities()">
                                <button class="btn btn-outline-secondary btn-sm"
                                    (click)="searchTerm = ''; filterActivities()">
                                    <i class="fa-solid fa-times"></i>
                                </button>
                            </div>
                            <button class="btn btn-sm" [class.btn-primary]="showList"
                                [class.btn-outline-primary]="!showList" (click)="toggleList()">
                                <i class="fa-solid" [class.fa-chevron-up]="showList"
                                    [class.fa-chevron-down]="!showList"></i>
                            </button>
                        </div>
                    </div>

                    <!-- List Content -->
                    <div class="list-content" *ngIf="showList">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th style="width: 120px" (click)="sortActivities('date_deadline')">
                                        Data
                                        <i class="fa-solid" [class.fa-sort]="sortField !== 'date_deadline'"
                                            [class.fa-sort-up]="sortField === 'date_deadline' && !sortDesc"
                                            [class.fa-sort-down]="sortField === 'date_deadline' && sortDesc"></i>
                                    </th>
                                    <th style="width: 100px">Stato</th>
                                    <th style="width: 120px">Tipo</th>
                                    <th>Dettagli</th>
                                    <th style="width: 120px" (click)="sortActivities('create_uid.name')">
                                        Creato da
                                        <i class="fa-solid" [class.fa-sort]="sortField !== 'create_uid.name'"
                                            [class.fa-sort-up]="sortField === 'create_uid.name' && !sortDesc"
                                            [class.fa-sort-down]="sortField === 'create_uid.name' && sortDesc"></i>
                                    </th>
                                    <th style="width: 100px">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let activity of filteredActivities"
                                    (click)="navigateToActivityDate(activity)" class="cursor-pointer"
                                    [class.table-danger]="activity.state === 'overdue'"
                                    [class.table-warning]="activity.state === 'today'"
                                    [class.table-info]="activity.state === 'planned'">
                                    <td>{{activity.date_deadline | date:'dd/MM/yyyy'}}</td>
                                    <td>
                                        <span class="badge" [ngClass]="{
                    'bg-danger': activity.state === 'overdue',
                    'bg-warning': activity.state === 'today',
                    'bg-info': activity.state === 'planned'
                  }">
                                            {{getStatusLabel(activity.state)}}
                                        </span>
                                    </td>
                                    <td>{{getActivityTypeLabel(activity.activity_type_id.id)}}</td>
                                    <td>{{activity.display_name}}</td>
                                    <td>
                                        <div class="d-flex align-items-center gap-2">
                                            <img *ngIf="findUser(activity.create_uid.name)?.image_256"
                                                [src]="'data:image/png;base64,' + findUser(activity.create_uid.name).image_256"
                                                class="avatar-sm">
                                            {{activity.create_uid.name}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex gap-2">
                                            <button class="btn btn-sm btn-outline-primary"
                                                (click)="openActivity(activity); $event.stopPropagation()">
                                                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                                            </button>
                                            <button class="btn btn-sm btn-success"
                                                (click)="completeActivity(activity); $event.stopPropagation()">
                                                <i class="fa-solid fa-check"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>