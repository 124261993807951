<div class="container-fluid" *ngIf="contact?.child_ids">
  <!-- Contacts Section -->
  <div class="row mb-4">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="mb-0">Contatti</h5>
        <button class="btn btn-sm btn-outline-primary" (click)="newContact()">
          <i class="fa fa-plus me-2"></i>Nuovo Contatto
        </button>
      </div>

      <div class="row g-3" *ngIf="contactChilds?.length">
        <div class="col-12 col-md-6 col-lg-4" *ngFor="let contact of contactChilds">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <!-- Card Header with Name and Actions stays the same -->
              <div class="border-bottom pb-2 mb-3">
                <!-- Top row with name and menu -->
                <div class="d-flex align-items-center justify-content-between">
                  <div class="flex-grow-1">
                    <!-- Contact display  -->
                    <h5 class="mb-1 text-dark fw-bold text-truncate">{{contact.name}}</h5>
                    <div class="text-muted small mb-0">{{contact.function || '&nbsp;'}}</div>
                  </div>
                  <!-- Actions menu -->
                  <div class="dropdown ms-2">
                    <button class="btn btn-link text-secondary p-0" type="button" data-bs-toggle="dropdown">
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li *ngIf="!editingContact || openContact !== contact">
                        <a class="dropdown-item" href="#" (click)="$event.preventDefault(); startEdit(contact)">
                          <i class="fa fa-edit me-2"></i>Modifica
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item text-danger" href="#"
                          (click)="$event.preventDefault(); onDelete(contact)">
                          <i class="fa fa-trash me-2"></i>Elimina
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Contact Info Display/Edit -->
              <div [ngSwitch]="openContact === contact && editingContact">
                <!-- Edit Form -->
                <form *ngSwitchCase="true" ngNoForm>
                  <div class="row g-3">

                    <!-- Type -->
                    <div class="col-12">
                      <label class="form-label">Tipo</label>
                      <select class="form-select" [(ngModel)]="contact.type" (change)="update(contact, 'type')">
                        <option value="contact">Contatto</option>
                        <option value="invoice">Indirizzo Fatturazione</option>
                        <option value="delivery">Indirizzo Spedizione</option>
                        <option value="private">Indirizzo Privato</option>
                        <option value="other">Altro Indirizzo</option>
                      </select>
                    </div>

                    <!-- Name -->
                    <div class="col-12">
                      <label class="form-label">Nome</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.name"
                        (change)="update(contact, 'name')">
                    </div>

                    <!-- Function (only for contacts) -->
                    <div class="col-12" *ngIf="isContactType(contact)">
                      <label class="form-label">Funzione</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.function"
                        (change)="update(contact, 'function')">
                    </div>

                    <!-- Contact Info -->
                    <div class="col-12">
                      <label class="form-label">Email</label>
                      <input type="email" class="form-control" [(ngModel)]="contact.email"
                        (change)="update(contact, 'email')">
                    </div>

                    <div class="col-12 col-sm-6">
                      <label class="form-label">Telefono</label>
                      <input type="tel" class="form-control" [(ngModel)]="contact.phone"
                        (change)="update(contact, 'phone')">
                    </div>

                    <div class="col-12 col-sm-6">
                      <label class="form-label">Mobile</label>
                      <input type="tel" class="form-control" [(ngModel)]="contact.mobile"
                        (change)="update(contact, 'mobile')">
                    </div>

                    <!-- Address with Google Places -->
                    <div class="col-12">
                      <label class="form-label">Indirizzo</label>
                      <input #placesRef="ngx-places" ngx-google-places-autocomplete [options]="placesOptions"
                        class="form-control" [value]="getFullAddress(contact)"
                        (onAddressChange)="handleAddressChange($event, contact)">
                    </div>

                    <div class="col-sm-8">
                      <label class="form-label">Città</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.city"
                        (change)="update(contact, 'city')" readonly>
                    </div>

                    <div class="col-sm-4">
                      <label class="form-label">CAP</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.zip"
                        (change)="update(contact, 'zip')" readonly>
                    </div>

                    <!-- Notes -->
                    <div class="col-12">
                      <label class="form-label">Note</label>
                      <div #commentEditor class="form-control h-auto" [innerHTML]="contact.comment"
                        contenteditable="true" (blur)="updateComment(contact, commentEditor.innerHTML)"
                        style="min-height: 100px;">
                      </div>
                    </div>
                  </div>
                  <!-- footer with salva and annulla -->
                  <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-primary me-2" (click)="saveContact(contact)">Chiudi</button>
                    <button class="btn btn-secondary" (click)="cancelEdit()">Annulla</button>
                  </div>
                </form>


                <!-- Contact details with icons -->
                <a *ngIf="contact.email" href="mailto:{{contact.email}}"
                  class="text-decoration-none text-body d-flex align-items-center">
                  <i class="fa fa-envelope text-primary me-2"></i>
                  <span class="text-truncate">{{contact.email}}</span>
                </a>

                <ng-container *ngIf="contact.phone || contact.mobile">
                  <a href="tel:{{contact.phone || contact.mobile}}"
                    class="text-decoration-none text-body d-flex align-items-center">
                    <i class="fa fa-phone text-primary me-2"></i>
                    <span>
                      {{contact.phone}}
                      <ng-container *ngIf="contact.phone && contact.mobile"> / </ng-container>
                      {{contact.mobile}}
                    </span>
                  </a>
                </ng-container>

                <a *ngIf="contact.street" class="text-decoration-none text-body d-flex align-items-center">
                  <i class="fa fa-location-dot text-primary me-2"></i>
                  <span>{{contact.street}}{{contact.zip || contact.city ? ' - ' : ''}}{{contact.zip}}
                    {{contact.city}}</span>
                </a>

                <div *ngIf="contact.comment" class="text-decoration-none text-body d-flex align-items-start">
                  <i class="fa fa-comment text-primary me-2 mt-1"></i>
                  <div [innerHTML]="contact.comment"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Divider -->
  <hr class="my-4" *ngIf="contactChilds?.length && addressChilds?.length">
  <!-- Addresses Section -->
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="mb-0">Indirizzi</h5>
        <button class="btn btn-sm btn-outline-primary" (click)="newAddress()">
          <i class="fa fa-plus me-2"></i>Nuovo Indirizzo
        </button>
      </div>

      <div class="row g-3" *ngIf="addressChilds?.length">
        <div class="col-12 col-md-6 col-lg-4" *ngFor="let contact of addressChilds">
          <div class="card shadow-sm h-100">
            <div class="card-body">
              <!-- Card Header with Name and Actions -->
              <div class="border-bottom pb-2 mb-3">
                <!-- Top row with name and menu -->
                <div class="d-flex align-items-center justify-content-between">
                  <div class="flex-grow-1">
                    <!-- Contact display  -->
                    <h5 class="mb-1 text-dark fw-bold text-truncate">{{contact.name}}</h5>
                    <div class="text-muted small mb-0">{{getTypeLabel(contact.type) || '&nbsp;'}}</div>
                  </div>
                  <!-- Actions menu -->
                  <div class="dropdown ms-2">
                    <button class="btn btn-link text-secondary p-0" type="button" data-bs-toggle="dropdown">
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li *ngIf="!editingContact || openContact !== contact">
                        <a class="dropdown-item" href="#" (click)="$event.preventDefault(); startEdit(contact)">
                          <i class="fa fa-edit me-2"></i>Modifica
                        </a>
                      </li>
                        <li>
                          <a class="dropdown-item text-danger" href="#"
                            (click)="$event.preventDefault(); onDelete(contact)">
                            <i class="fa fa-trash me-2"></i>Elimina
                          </a>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Contact Info Display/Edit -->
              <div [ngSwitch]="openContact === contact && editingContact">
                <!-- Edit Form -->
                <form *ngSwitchCase="true" ngNoForm>
                  <div class="row g-3">


                    <!-- Type -->
                    <div class="col-12">
                      <label class="form-label">Tipo</label>
                      <select class="form-select" [(ngModel)]="contact.type" (change)="update(contact, 'type')">
                        <option value="contact">Contatto</option>
                        <option value="invoice">Indirizzo Fatturazione</option>
                        <option value="delivery">Indirizzo Spedizione</option>
                        <option value="private">Indirizzo Privato</option>
                        <option value="other">Altro Indirizzo</option>
                      </select>
                    </div>
                    <!-- Name -->
                    <div class="col-12">
                      <label class="form-label">Nome</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.name"
                        (change)="update(contact, 'name')">
                    </div>

                    <!-- Function (only for contacts) -->
                    <div class="col-12" *ngIf="isContactType(contact)">
                      <label class="form-label">Funzione</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.function"
                        (change)="update(contact, 'function')">
                    </div>

                    <!-- Contact Info -->
                    <div class="col-12">
                      <label class="form-label">Email</label>
                      <input type="email" class="form-control" [(ngModel)]="contact.email"
                        (change)="update(contact, 'email')">
                    </div>

                    <div class="col-12 col-sm-6">
                      <label class="form-label">Telefono</label>
                      <input type="tel" class="form-control" [(ngModel)]="contact.phone"
                        (change)="update(contact, 'phone')">
                    </div>

                    <div class="col-12 col-sm-6">
                      <label class="form-label">Mobile</label>
                      <input type="tel" class="form-control" [(ngModel)]="contact.mobile"
                        (change)="update(contact, 'mobile')">
                    </div>

                    <!-- Address with Google Places -->
                    <div class="col-12">
                      <label class="form-label">Indirizzo</label>
                      <input #placesRef="ngx-places" ngx-google-places-autocomplete [options]="placesOptions"
                        class="form-control" [value]="getFullAddress(contact)"
                        (onAddressChange)="handleAddressChange($event, contact)">
                    </div>

                    <div class="col-sm-8">
                      <label class="form-label">Città</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.city"
                        (change)="update(contact, 'city')" readonly>
                    </div>

                    <div class="col-sm-4">
                      <label class="form-label">CAP</label>
                      <input type="text" class="form-control" [(ngModel)]="contact.zip"
                        (change)="update(contact, 'zip')" readonly>
                    </div>

                    <!-- Notes -->
                    <div class="col-12">
                      <label class="form-label">Note</label>
                      <div #commentEditor class="form-control h-auto" [innerHTML]="contact.comment"
                        contenteditable="true" (blur)="updateComment(contact, commentEditor.innerHTML)"
                        style="min-height: 100px;">
                      </div>
                    </div>
                  </div>
                  <!-- footer with salva and annulla -->
                  <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-primary me-2" (click)="saveContact(contact)">Chiudi</button>
                    <button class="btn btn-secondary" (click)="cancelEdit()">Annulla</button>
                  </div>
                </form>


                <!-- Contact details with icons -->
                <a *ngIf="contact.email" href="mailto:{{contact.email}}"
                  class="text-decoration-none text-body d-flex align-items-center">
                  <i class="fa fa-envelope text-primary me-2"></i>
                  <span class="text-truncate">{{contact.email}}</span>
                </a>

                <ng-container *ngIf="contact.phone || contact.mobile">
                  <a href="tel:{{contact.phone || contact.mobile}}"
                    class="text-decoration-none text-body d-flex align-items-center">
                    <i class="fa fa-phone text-primary me-2"></i>
                    <span>
                      {{contact.phone}}
                      <ng-container *ngIf="contact.phone && contact.mobile"> / </ng-container>
                      {{contact.mobile}}
                    </span>
                  </a>
                </ng-container>

                <a *ngIf="contact.street" class="text-decoration-none text-body d-flex align-items-center">
                  <i class="fa fa-location-dot text-primary me-2"></i>
                  <span>{{contact.street}}{{contact.zip || contact.city ? ' - ' : ''}}{{contact.zip}}
                    {{contact.city}}</span>
                </a>

                <div *ngIf="contact.comment" class="text-decoration-none text-body d-flex align-items-start">
                  <i class="fa fa-comment text-primary me-2 mt-1"></i>
                  <div [innerHTML]="contact.comment"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>