

export enum DealStatus {
  NEW = 0,
  QUOTE = 1,
  PROJECT = 2,
  PRODUCTION = 3
}

export const PRINT_ATTENDANCE_CFG = {
  spool_folder_id: "1XIHgFV1yrwpJFoRcc1K1Q8JgTt45RzYA",
  template_sheet_id: "11pFKW7kMQ3b6fJkWdKlmnXWdhikcNVl6T5_GFXqlVXM"
}

export const PRINTS_CFG = { //for subappalto
  spool_folder_id: "1eeUoKxx9HaTYuMGuF-xcGfC6bVkHrwoK",
  template_sheet_id: "1ZiajqLxt66Ftq0KyyTqqRgru9GzszzTdQvvNsDPbewY",
  template_purchase_embedded_sheet_id: "1ZiajqLxt66Ftq0KyyTqqRgru9GzszzTdQvvNsDPbewY"
}

export const PRINTS_SALEORDER_CFG = { 
  spool_folder_id: "1eeUoKxx9HaTYuMGuF-xcGfC6bVkHrwoK",
  template_sheet_id: "1k_nznz5fMeW7FP8WXmq8uqBeAXqPIR2R_FkaYtjn020",
  template_embedded_sheet_id: "1k_nznz5fMeW7FP8WXmq8uqBeAXqPIR2R_FkaYtjn020"
}
export const PRINTS_COSTLINES_CFG = {
  spool_folder_id: "1eeUoKxx9HaTYuMGuF-xcGfC6bVkHrwoK",
  template_sheet_id: "1cybDrNVO5qYDG0YMhimnDdO2nKe0LRHqIucN5E9mFpE",
}

export const PRINTS_PURCHASE_CFG = {
  // spool_folder_id: "https://docs.google.com/spreadsheets/d/1wRtfJCjPvESj4IqVMuGn7aQvrlr3qwW5moivncv_Mi8/edit#gid=498268299",
  spool_folder_id: "1eeUoKxx9HaTYuMGuF-xcGfC6bVkHrwoK",
  template_sheet_id: "1wRtfJCjPvESj4IqVMuGn7aQvrlr3qwW5moivncv_Mi8"
}

export const PRINTS_PICKING_CFG = {
  // spool_folder_id: "https://docs.google.com/spreadsheets/d/1wRtfJCjPvESj4IqVMuGn7aQvrlr3qwW5moivncv_Mi8/edit#gid=498268299",
  spool_folder_id: "1eeUoKxx9HaTYuMGuF-xcGfC6bVkHrwoK",
  // template_sheet_id: "12AOeIvDlu3qVmepZ6KE_o-OeJG0W8hHN6e0yj1tZzN8"
}

export const FIDI_CFG = {
  trelloListId: "6102821e14f9f88b6dfbcbc9",
  trelloBoardId : "610281f5c1c66e492f118c7f"
}

export const WRITE_DEALINFO_CFG = {
  template_sheet_id: "1s83bYrKpmRUt4bjbGZzwXtl4EwPrqx1cthxVJ_RxDNg"
}

export const ODOO_IDS = {
  // LOCATIONS
  PIAZZALE: 49,
  M: 54, // magazzino M
  stock_location_H: 43, // H
  stock_cap_case: 48, // case
  stock_location_stock: 8, // giacenza
  stock_location_customer: 5, // cliente
  stock_location_vendor: 4, // fornitore
  stock_location_sent: 301, // inviato (carico trasporto )
  stock_location_shipment: 41, // spedizioni
  location_case_consume: 47, // location consumo case
  location_montaggio_consume: 308, // location consumo montaggio
  location_massello_consume: 307, // location consumo massello
  location_manutenzioni_consume: 310, // location consumo manutenzioni
  exclude_packs_location_ids: [301, 47, 307, 308, 310, 4, 5, 48, 43 ],

  // PICKING TYPES
  picking_type_receipt: 1, // arrivi
  picking_type_shipment: 2, // carico trasporto
  picking_type_shipments: [2, 35],
  picking_type_stock: 23,
  picking_type_internal_id: 5,
  picking_type_ddt: 35, // bolle
  picking_type_consume_case_id: 41, // picking per consumo case
  picking_type_consume_massello_id: 53, // picking per consumo massello
  picking_type_consume_montaggio_id: 54, // picking per consumo montaggio
  picking_type_consume_manutenzioni_id: 55, // picking per consumo manutenzioni
  picking_type_restolegno: 45,
  picking_type_generic:29,
  packaging_picking_type_ids: [20],
  pickpack_picking_type_ids: [22, 2],

  // ATTRIBUTES AND TAGS
  length_attribute_id: 33,
  length_editable_tag_id: 15, // product tag
  width_editable_tag_id: 16, // product tag
  height_editable_tag_id: 17, // product tag
  tag_template_id: 16, // for leads template
  tag_magazzino: 15,
  tag_variant_search: 18, // tag "RIC.ESTESA": id del tag
  tag_to_prepare: 19,
  dimensionalAttributes: [5, 6, 4, 33, 68],
  nonModifiableAttributes: [27, 69],
  crmTagsFornitura:[13,14],
  crmTagsCertificazione:[20,21,22],
  crmTagPEFC: 20,
  crmTagstrutturale: 22,
  prodTagsFondi: {
        prodTagFondo: 20,
        prodTagConsumaPrima: 1
        },


  // ROUTES AND ACTIVITIES
  diretto_route_id: 71,
  routes_to_hide_ids: [10, 8, 73, 34, 74,2, 3, 56, 54,55],
  activity_todo: 4,
  inline_production_route_ids: [79],

  // PRODUCTS AND CATEGORIES
  fondo_id: 20, // id dell'additional product per i fondi
  mainCateg_ids: [41, 104, 75, 46, 108, 52, 97, 152, 105, 178, 85, 161, 114, 111, 61, 64, 67], // ID delle categorie madri per controllo costi

  // PRICELISTS
  pricelist_commessa: 6,

  // PICKING SEARCH OPTIONS
  picking_search_options: {
    "Arrivi": [["picking_type_id", "=", 1], ['state', 'in', ['assigned', 'confirmed', 'waiting']]],
  
    "Prelievo travatura": [
      '&',  // Main AND
      ['state', 'in', ['assigned', 'confirmed']],
      '|',  //Main OR
      '&',  // AND for picking types 40 or 52 with activity (prelievo travatura h e pronto h)
      '|',  // OR for picking types
          ['picking_type_id', '=', 40],
          ['picking_type_id', '=', 52],
      ['sale_id.activity_ids.activity_type_id', '=', 4],
      //OR condition for picking type 13 (picking componenti)
      ['picking_type_id', '=', 13]
  ],
    "Linea taglio": [['sale_id.activity_ids.activity_type_id', '=', 4], ["location_id", "=", 43], "|", ['state', 'in', ['assigned', 'confirmed']], "&", ["backorder_id", "!=", false], ['state', 'in', ['assigned', 'confirmed', 'waiting']]],
    "E": [['sale_id.activity_ids.activity_type_id', '=', 4], ["location_id", "=", 8], ["location_dest_id", "=", 41], ['state', 'in', ['assigned', 'confirmed', 'waiting']]],
    "Case produzione": [['sale_id.activity_ids.activity_type_id', '=', 4], ["location_id", "=", 8], ["location_dest_id", "=", 48], ['state', 'in', ['assigned', 'confirmed']]],
    "Spedizioni": ['|', ["picking_type_id", "=", 2], ["picking_type_id", "=", 28], ['sale_id.activity_ids.activity_type_id', '=', 4], ['state', 'in', ['assigned', 'confirmed']]],
    "Resi": [['sale_id.activity_ids.activity_type_id', '=', 4], ["location_dest_id", "=", 8],['is_return_picking', '!=', false], ["picking_type_id", "!=", 1], ['state', 'in', ['assigned', 'confirmed']]],
  
  },

  //DEAL STAGES
  export_trigger_stages_id: [3, 6],

  // ACCOUNTS
  contratti_id: 12,
  contratti_categ_id: 185,
  fatture_categ_id: 184,
  conto_impegni_id: 21,
  sal_id: 11,
  garanzia_id:67934

}

export const AREAS_CFG = [
  {
    "name": "Aziendale",
    "src" : "1jm1b9W_slszC3CvhNjNTKJ3upddsUlU8",
    "dst" : "147jeUg9ZeW3EOoXyk06ZNbzY2DXFM3y0",
    "preventivo_trello_board": null,
    "preventivo_trello_list": null,

    "project_src" : "1jm1b9W_slszC3CvhNjNTKJ3upddsUlU8",
   "project_trello" : "66f51537082cf73b17a2d494",
    "project_trello_list": "600819652c976414affae318",
    "project_trello_board": "5c910bee7ed7a80d416e7ab9",

    "produzione_src": "1kBrd1sqhcCbb8RKDwbKhidSiK-fdaJdY",
    "produzione_dst": "1QMWhFiMKgEZh_yL6wfB5gH3MkytiwB0J",
    "produzione_trello" : "600819932c0f41014f9ba175",
    "produzione_trello_board" : "5c910c36323dbb560103a1ff",
    "produzione_link_name" : "04 - Produzione linea tetti",
   
    "pos_src" : null,
    "pos_dst" : null,
    "pos_trello_board": null,
    "pos_trello_list": null,
    "pos_template_card": null,
    "pos_link_name" : "05 - Sicurezza cantiere",
    
    "purchase_drive_src" : "1ljjKRuu0PR58ZGNMknkw3UVa4AfFce5W",
    "purchase_drive_dst" : "1oLEAo4uHnBoObsDlelxkRwgQVNsWpY48",
    "purchase_link_name" : "Acquisto"
  },
  {
    "name": "Case",
    "src" : "1ZaGNzWJ7EinXMVKuP5VCQzD5af1dqfQ0",
    "dst" : "1xeQGk0aU_Z0rJPoShG3lY0E5DqxokLog",
    "preventivo_trello_board": "565f1c15aefe2e1ea3e322b0",
    "preventivo_trello_list": "565f1c15aefe2e1ea3e322b1",
    
    "project_src" : "1_q32bZBlfLJyeRhCrmuAnlaf3833WqTM",
    "project_trello" : "66f51537082cf73b17a2d494",
    "project_trello_list": "600819652c976414affae318",
    "project_trello_board": "5c910bee7ed7a80d416e7ab9",

    "produzione_src": "1kBrd1sqhcCbb8RKDwbKhidSiK-fdaJdY",
    "produzione_dst": "1QMWhFiMKgEZh_yL6wfB5gH3MkytiwB0J",
    "produzione_trello" : "600819932c0f41014f9ba175",
    "produzione_trello_board" : "5c910c36323dbb560103a1ff",
    "produzione_link_name" : "04 - Produzione linea tetti",

    "pos_src" : "1_6yF8NILEYdFkM4orAP9T4uSqgqaPalo",
    "pos_dst" : "1S5NK0iCuw6pU159XZhhMWE7BsrtUbVCR",
    "pos_trello_board": "65e1d8f494b56f332707797e",
    "pos_trello_list": "65e1d8f494b56f3327077980",
    "pos_template_card": "65e1d8f494b56f3327077999",
    "pos_link_name" : "05 - Sicurezza cantiere",

    "purchase_drive_src" : "1ljjKRuu0PR58ZGNMknkw3UVa4AfFce5W",
    "purchase_drive_dst" : "1oLEAo4uHnBoObsDlelxkRwgQVNsWpY48",
    "purchase_link_name" : "07 - Acquisto"
  },
  {
    "name": "Facciate e Decking",
    "src" : "1RsqhfTS3tauQA0byFO0HronzlFLkkEHO",
    "dst" : "1GSXV73U_QBk0e4FYQq9-YXAbMLt40HT3",
    "preventivo_trello_board": "54ad1493f2846888caafe1e8",
    "preventivo_trello_list": "573db24f4076c61e50a49109",
    "project_src" : "1pb41fczPeUC3VyHwlfigBN0WY7T0Ju2C",
    "project_trello" : "66f51537082cf73b17a2d494",
    "project_trello_list": "600819652c976414affae318",
    "project_trello_board": "5c910bee7ed7a80d416e7ab9",
    "produzione_src": "1kBrd1sqhcCbb8RKDwbKhidSiK-fdaJdY",
    "produzione_dst": "1QMWhFiMKgEZh_yL6wfB5gH3MkytiwB0J",
    "produzione_trello" : "600819932c0f41014f9ba175",
    "produzione_trello_board" : "5c910c36323dbb560103a1ff",
    "produzione_link_name" : "04 - Produzione linea tetti",
    "pos_src" : "1_6yF8NILEYdFkM4orAP9T4uSqgqaPalo",
    "pos_dst" : "1S5NK0iCuw6pU159XZhhMWE7BsrtUbVCR",
    "pos_trello_board": "65e1d8f494b56f332707797e",
    "pos_trello_list": "65e1d8f494b56f3327077980",
    "pos_template_card": "65e1d8f494b56f3327077999",
    "pos_link_name" : "05 - Sicurezza cantiere ",

    "purchase_drive_src" : "1ljjKRuu0PR58ZGNMknkw3UVa4AfFce5W",
    "purchase_drive_dst" : "1oLEAo4uHnBoObsDlelxkRwgQVNsWpY48",
    "purchase_link_name" : "Acquisto"
  },
  {
    "name": "Massello",
    "src" : "1QvOsDhTSQ7DYpFDgWrD9NJKxYZoj_c1u",
    "dst" : "10xZzE2hIdnHtEfEqmbyzzlDZaWa_bY6V",
    "preventivo_trello_list": null,
    "preventivo_trello_board": null,
    "pos_src" : null,
    "pos_dst" : null,
    "pos_trello_board": null,
    "pos_trello_list": null,
    "pos_template_card": null,
    "pos_link_name" : null,
  },
  {
    "name": "Pavimenti",
    "src" : "1OC0xPh3OQlK48z-pH_VZlTS2mmYYKmU5",
    "dst" : "1lJMMmMgzZD9yqzP1hhVL4PAUIdK0BOH5",
    "preventivo_trello_list": "56b8a64520a6da342e99ad27",
    "preventivo_trello_board": "565f1c15aefe2e1ea3e322b0",
    
    "pos_src" : "1_6yF8NILEYdFkM4orAP9T4uSqgqaPalo",
    "pos_dst" : "1S5NK0iCuw6pU159XZhhMWE7BsrtUbVCR",
    "pos_trello_board": "65e1d8f494b56f332707797e",
    "pos_trello_list": "65e1d8f494b56f3327077980",
    "pos_template_card": "65e1d8f494b56f3327077999",
    "pos_link_name" : "05 - Sicurezza cantiere ",
  },
  {
  
    "name": "Tetti",  
    "src" : "1kzU_Fx6NmdrkveiM6ulJ7ZL0SI0iucBw", //id drive preventivo/progetto sorgente
    "dst" : "1F5dxs4dhmOIdV4AyNWFNJm-8EY-BE9Xc",  //id drive preventivo/progetto destinazione
    "preventivo_trello_board": "52f3787566d34a102141fb9f",
    "preventivo_trello_list": "600847ca43ea131ef8690b53",
    
    "project_src" : "14J3D_2DowKwGFkSp7a7vVryPKLx1LC69", 
    "project_trello" : "66f51537082cf73b17a2d494",
    "project_trello_list": "600819652c976414affae318",
    "project_trello_board": "5c910bee7ed7a80d416e7ab9",
    
    "produzione_src": "1kBrd1sqhcCbb8RKDwbKhidSiK-fdaJdY",
    "produzione_dst": "1QMWhFiMKgEZh_yL6wfB5gH3MkytiwB0J",
    "produzione_trello" : "600819932c0f41014f9ba175",
    "produzione_trello_board" : "5c910c36323dbb560103a1ff",
    "produzione_link_name" : "04 - Produzione linea tetti",
    
    "pos_src" : "1_6yF8NILEYdFkM4orAP9T4uSqgqaPalo",
    "pos_dst" : "1S5NK0iCuw6pU159XZhhMWE7BsrtUbVCR",
    "pos_trello_board": "65e1d8f494b56f332707797e",
    "pos_trello_list": "65e1d8f494b56f3327077980",
    "pos_template_card": "65e1d8f494b56f3327077999",
    "pos_link_name" : "05 - Sicurezza cantiere",
    
    "purchase_drive_src" : "1ljjKRuu0PR58ZGNMknkw3UVa4AfFce5W",
    "purchase_drive_dst" : "1oLEAo4uHnBoObsDlelxkRwgQVNsWpY48",
    "purchase_link_name" : "Acquisto",

    "production_product_id" : 64232
  }
]
