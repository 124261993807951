import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StockQuant } from '../models/stock-quant';
import { StockQuantPackage } from '../models/stock-quant-package';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { ProductTag } from '../models/product.tag.model';
import { first, firstValueFrom, Observable } from 'rxjs';
import { ODOO_IDS } from '../models/deal';
import { Product } from '../models/product.model';
import { ProductTemplate } from '../models/product.template.model';
import { MrpProduction } from '../models/mrp-production';
import { StockMove } from '../models/stock-move';
import { StockMoveLine } from '../models/stock-move-line';
import { StockPicking } from '../models/stock-picking';


@Component({
  selector: 'app-scraps',
  templateUrl: './scraps.component.html',
  styleUrl: './scraps.component.scss'
})
export class ScrapsComponent {
  @Input() quant: StockQuant;
  @Input() sourcePackage?: StockQuantPackage;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter<any>();

  showQuantityEditor = true;
  selectedQuantity: number = null;
  destinationPackage: StockQuantPackage = null;
  selectedTag: ProductTag = null;
  showScanner = false;
  loading: boolean = false;
  oldTag: ProductTag = null;
  isAlreadyScrap: boolean = false;

  existingScrap: Product = null;
  newProductName: string = "";
  newListPrice: number = 0;
  originalPrice: number = 0;

  infoMessage: string = "";
  fondoTags: ProductTag[] = [];

  productionOrder: MrpProduction;

  constructor(private odooEm: OdooEntityManager) { }

  async ngOnInit() {
    //if cming from product we have a quant with some unserolved fields, so solve them
    await this.solveProductQuant();
    console.log('Quant:', this.quant);
    console.log('Source Package:', this.sourcePackage);
    this.laodTags();
  }

  async solveProductQuant() {
   if (!this.quant.product_id.value) {
    await firstValueFrom(this.odooEm.resolveSingle(new Product(), this.quant.product_id));
  }
}

  async laodTags() {
    //load possbile fondo tags with name that contains "fondo"
    let tags = await firstValueFrom(this.odooEm.search<ProductTag>(new ProductTag(), [['name', 'ilike', 'offerta']]));
    this.fondoTags = tags;
    console.log('Fondo Tags:', this.fondoTags);
    await firstValueFrom(this.odooEm.resolve(this.quant.product_id.value.additional_product_tag_ids))
    this.oldTag = this.quant.product_id.value.additional_product_tag_ids.values.find(t => this.fondoTags.find(ft => ft.id === t.id));
    if (this.oldTag) {
      this.isAlreadyScrap = true;
    }
  }

  onQuantitySelected(quantity: number) {
    console.log('Quantity selected:', quantity);
    this.selectedQuantity = quantity;
    this.showQuantityEditor = false;
  }

  onDestinationScanned(pkg: StockQuantPackage) { //this is only when a new pack is created form the scanner
    this.destinationPackage = pkg;
    console.log('Destination Package:', this.destinationPackage);
    this.showScanner = false;
  }

  async onCodeScanned(code: string) { //here we have to check if the code is a package or if it does not exist. if it does not exist we have to create a new package
    this.loading = true
    console.log('Code Scanned:', code);
    this.destinationPackage = null;
    if (code === 'nopacco') {
      this.destinationPackage = null;
    }
    else {
      //search in packs that are not in odoo_ids.exclude_packs_location_ids
      var rs = await firstValueFrom(this.odooEm.search<StockQuantPackage>(
        new StockQuantPackage(), [['name', '=like', code + '%'], ['location_id', 'not in', ODOO_IDS.exclude_packs_location_ids]])) // =ilike BAD TEMP FIX FOR BARCODES with location
      if (rs && rs.length > 0) {
        this.destinationPackage = rs[0];
      }
      else { //if the pack does not exist we ask if we want to create it
        if (confirm("Il pacco non esiste, vuoi crearlo?")) {
          let p = await firstValueFrom(
            this.odooEm.create<StockQuantPackage>(new StockQuantPackage(), {
              name: code,
            })
          );
          if (p) {
            this.destinationPackage = p;
          }
        }
      }
    }
    this.showScanner = false;
    this.loading = false;
    console.log('Destination Package:', this.destinationPackage);
  }

  async onFondo() {
    if (!this.selectedTag) {
      this.infoMessage = "";
      return;
    }
    let prodName: string = await this.buildName();
    if (prodName) {
      this.newProductName = prodName;

      //search the product in odoo, filtering by name and tag id selected
      let res = await firstValueFrom(this.odooEm.search<Product>(new Product(), [['name', '=', this.newProductName], ['additional_product_tag_ids', 'in', this.selectedTag.id]]));
    
   this.existingScrap = res[0];
    }
   this.originalPrice = await this.getOriginalProductPrice();
   this.newListPrice = await this.getNewListPrice();

  }

  async buildName(): Promise<string> {
    //first we analyze the product.
    let product = this.quant.product_id.value;
    let plus = '';
    let minus = '';

    console.log('Product:', product);
    //create the string for the new product
    let prodName = product.display_name;
    if (this.isAlreadyScrap) {
      //subract the tag from the product
      minus = this.oldTag.name.replace('FERTA', '');
      if (minus) {
        minus = ' (' + minus + ') ';
      }
    }
    //we now find the code to add to the product based on the tag selected
    if (this.selectedTag.id) {
      console.log('Selected Tag:', this.selectedTag);
      let tag = this.fondoTags.find(t => t.id === this.selectedTag.id);
      if (tag) {
        plus = tag.name.replace('FERTA', '');
        if (plus) {
          plus = ' (' + plus + ') ';
        }
        console.log('Adding tag:', tag);
      }
    }
    // we build the new product name: remove the minus and add the plus
    prodName = prodName.replace(minus, '');
    prodName = prodName + plus;
    console.log('New Product Name:', prodName, " made by subtracting ", minus, " and adding ", plus);
    return prodName;
  }

  getOriginalProductPrice(): number {
    let product = this.quant.product_id.value;
    let price = product.list_price;
    let oldDiscount = 0
    if (this.isAlreadyScrap) {
      let discounUnit = this.oldTag.name.replace('OFFERTA', '');
      oldDiscount = parseFloat(discounUnit)*10;
      console.log('Old Discount:', oldDiscount);
      price = product.list_price / (1 - oldDiscount/100);
    }
    return price;
  }

  getNewListPrice(): number { //here we set the list price of the new product as a % of the original variant based on new product tag
    let newDiscount = 0
    if (this.selectedTag) {
      let discounUnit = this.selectedTag.name.replace('OFFERTA', '');
      newDiscount = parseFloat(discounUnit)*10;
      console.log('New Discount:', newDiscount);
    }
    return this.originalPrice * (1 - newDiscount/100);

}

  async createScrap() {
    this.loading = true;
    if (!this.existingScrap) {
      this.infoMessage = "Creazione prodotto in corso...";
      this.existingScrap = await this.createProductScrap();
    }
  
    this.infoMessage = "Sposto la quantità all'articolo in offerta...";
    this.productionOrder = await this.createProductionOrder();
    await this.createAndLinkMove();
    await this.completeProduction();
    this.infoMessage = "Sposto la quantità nel collo di destinazione...";
    await this.createRestockingPicking();
    this.loading = false;
    this.infoMessage = "Operazione completata!";
    this.onConfirm.emit();
  }

  async createProductScrap() {
    await firstValueFrom(this.odooEm.resolveSingle(new ProductTemplate(), this.quant.product_id.value.product_tmpl_id));
    let variant = this.quant.product_id.value;
    let template = variant.product_tmpl_id.value;
    await firstValueFrom(this.odooEm.resolve(template.product_tag_ids));
    await firstValueFrom(this.odooEm.resolve(variant.product_template_variant_value_ids));
    await firstValueFrom(this.odooEm.resolve(variant.packaging_ids))
   
    let productFields = {
      name: this.newProductName,
      type: variant.detailed_type,
      uom_id: variant.uom_id.id,
      uom_po_id: variant.uom_id.id,
      list_price: this.newListPrice,
      categ_id: variant.categ_id.id,
      product_tag_ids: [[6, 0, template.product_tag_ids.ids]], 
      additional_product_tag_ids: [[6, 0, [this.selectedTag.id]]],
      purchase_ok: false,
      route_ids: [[6, 0, variant.route_ids.ids]],
      packaging_ids: [[6, 0, variant.packaging_ids.ids]]
    };
   
    let p = await firstValueFrom(this.odooEm.create<Product>(new Product(), productFields));

    // NEED TO CHECH
    // ADDING ATTRIBUTE FUCKS EVERYTHING UP
    console.log('Created Product:', p);
    return p;
  }

  private async createProductionOrder(): Promise<MrpProduction> {
    const productionData = {
      product_qty: this.selectedQuantity,
      product_id: this.existingScrap.id,
      product_uom_id: this.quant.product_uom_id.id,
      location_src_id: this.quant.location_id.id,
      location_dest_id: this.quant.location_id.id,
      origin: "creazione offerta"
    };
    return await firstValueFrom(this.odooEm.create<MrpProduction>(new MrpProduction(), productionData));

  }

private async createAndLinkMove() {
  const moveData = {
    product_id: this.quant.product_id.id,
    product_uom_qty: this.selectedQuantity,
    location_id: this.quant.location_id.id,
    location_dest_id: this.quant.location_id.id,
    name: `${this.quant.product_id.name} -> ${this.existingScrap.name}`,
    raw_material_production_id: this.productionOrder.id,
    product_uom: this.quant.product_uom_id.id
  };

  const move = await firstValueFrom(this.odooEm.create<StockMove>(new StockMove(), moveData));

  const moveLineData = {
    product_id: this.quant.product_id.id,
    location_id: this.quant.location_id.id,
    location_dest_id: this.quant.location_id.id,
    product_uom_id: this.quant.product_uom_id.id,
    package_id: this.sourcePackage?.id || false,
    qty_done: this.selectedQuantity,
    move_id: move.id
  };

  await firstValueFrom(this.odooEm.create<StockMoveLine>(new StockMoveLine(), moveLineData));
  await firstValueFrom(this.odooEm.update<MrpProduction>(this.productionOrder, {
    move_raw_ids: [[4, move.id]]
  }));
}

private async completeProduction(): Promise<void> {
  await firstValueFrom(this.odooEm.update<MrpProduction>(this.productionOrder, {
    qty_producing: this.selectedQuantity
  }));
  await this.odooEm.call2(new MrpProduction().ODOO_MODEL, "button_mark_done", [[this.productionOrder.id]]);
}

private async createRestockingPicking(): Promise<void> {
  const pickingData = {
    picking_type_id: ODOO_IDS.picking_type_generic,
    origin: "Creazione offerta",
    location_id: this.quant.location_id.id,
    location_dest_id: this.destinationPackage ? this.destinationPackage.location_id.id : this.quant.location_id.id,
  };

  const picking = await firstValueFrom(this.odooEm.create<StockPicking>(new StockPicking(), pickingData));

  const moveData = {
    name: this.existingScrap.name,
    location_id: this.quant.location_id.id,
    location_dest_id: this.destinationPackage ? this.destinationPackage.location_id.id : this.quant.location_id.id,
    product_id: this.existingScrap.id,
    product_uom_qty: this.selectedQuantity,
    picking_id: picking.id,
    product_uom: this.quant.product_uom_id.id
  };

  const move = await firstValueFrom(this.odooEm.create<StockMove>(new StockMove(), moveData));

  await firstValueFrom(this.odooEm.create<StockMoveLine>(new StockMoveLine(), {
    product_id: this.existingScrap.id,
    picking_id: picking.id,
    qty_done: this.selectedQuantity,
    result_package_id: this.destinationPackage?.id || false,
    location_id: this.quant.location_id.id,
    location_dest_id: this.destinationPackage ? this.destinationPackage.location_id.id : this.quant.location_id.id,
    move_id: move.id
  }));

  await this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [[picking.id]]);
}
  

}
