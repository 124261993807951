<div class="card rounded-0 overflow-hidden border-0">
  <!-- input search -->
  <div *ngIf="!contact" class="card-body border-bottom p-0 mb-2">
    <form autocomplete="off" class="d-flex flex-fill overflow-hidden">
      <div class="input-group mb-2">
        <input type="text" class="form-control " placeholder="Cerca per nome o partita iva"
          [(ngModel)]="inputSearch" (input)="onSearchChange()" (focus)="onFocusInput(true)"
          (focusout)="onFocusInput(false)" name="cx">
        <button *ngIf="this.mode !== 'embedded'" type="button"
          [ngClass]="{'bg-primary text-white': filterCompany$.value == false}" (click)="filterCompany$.next(false)"
          class="btn btn-outline-primary">
          <i class="fa fa-building"></i>
        </button>
        <button *ngIf="this.mode !== 'embedded'" type="button"
          [ngClass]="{'bg-primary text-white': filterCompany$.value == true}" (click)="filterCompany$.next(true)"
          class="btn btn-outline-primary">
          <i class="fa fa-user"></i>
        </button>
      </div>
    </form>
  </div>

  <!-- list -->
  <div class="table-responsive " style="max-height: calc(100vh - 300px);"> 
    <ul class="list-group list-group-flush shadow-sm" *ngIf="!contact && contacts">
      <ng-container *ngFor="let c of contacts">
        <li (click)="$event.stopPropagation(); onContact(c); contact = c"
          class="list-group-item d-flex align-items-center py-2 cursor-pointer hover-bg-light">
          <!-- Added hover effect -->
          <div class="d-flex w-100 justify-content-between align-items-center">
            <div>
              <span class="fw-medium">{{c?.name}}</span>
              @if (c.city){
              <br>
              <span class="text-muted ms-2">{{ c.city }}</span>
              }
            </div>
            <i class="fa-solid fa-chevron-right text-muted"></i>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- selected contact -->
  <div *ngIf="contact" class="card-body border-bottom bg-white shadow-sm">
    <div class="d-flex align-items-center justify-content-between">
      <div class="w-100">
        <h6 class="mb-1">{{contact.name}}</h6>
        <small class="text-muted" *ngIf="contact?.vat">{{contact.vat}}</small>
        <div class="text-muted" *ngIf="contact?.street">
          {{contact.street}} - {{contact.city}}
        </div>
      </div>
      <button class="btn btn-link text-dark" (click)="$event.stopPropagation();onContact(null);">
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>

  <!-- address -->
  <div class=" mt-3" *ngIf="contact && showAddresses">
    <strong>Seleziona o aggiungi indirizzo spedizione</strong>
  </div>

  <ul class="list-group list-group-flush" *ngIf="contact && !address && addresses.length">
    <ng-container *ngFor="let a of addresses">
      <li class="list-group-item cursor-pointer hover-bg-light"
        (click)="$event.stopPropagation();address = a; onAddress(a)">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            {{a?.street}}
            <span class="badge bg-primary ms-2" *ngIf="a.parent_id.id == null">Sede</span>
          </div>
          <i class="fa-solid fa-chevron-right text-muted"></i>
        </div>
      </li>
    </ng-container>
  </ul>

  <!-- Selected address -->
  <div class="card-body border-bottom bg-white" *ngIf="address">
    <div class="d-flex justify-content-between align-items-center">
      <div class="me-3">
        {{address.street}}
      </div>
      <button class="btn btn-link text-dark" (click)="$event.stopPropagation();onAddress(null)">
        <i class="fa fa-close"></i>
      </button>
    </div>
  </div>


  <!-- Add new address footer -->
  <div class="card-footer bg-white" *ngIf="contact && !address && showAddresses" (click)="isAddingAddress = true">
    <div *ngIf="!isAddingAddress" class="text-primary cursor-pointer">
      <i class="fa fa-plus me-2"></i>
      Nuovo indirizzo
    </div>
    <div *ngIf="isAddingAddress" class="input-group">
      <input #placesRef="ngx-places" ngx-google-places-autocomplete required class="form-control"
        (onAddressChange)="onAddressChange($event)">
    </div>
  </div>
</div>

<!-- No results message -->
<div *ngIf="contacts?.length == 0" class="text-center p-4 text-muted">
  <i class="fa fa-search fa-2x mb-2"></i>
  <h6 class="mt-2">Nessun risultato</h6>
</div>