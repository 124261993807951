export  function parseConditionAttributes(value: string | number, text: string) {
    // If text is empty, return true
    if (!text?.trim()) return true;
    

    // Helper function to escape regex special characters
    const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    

    // Helper function to evaluate a single condition
    const evaluateSingleCondition = (value: string | number, condition: string): boolean => {

        //turn "," into "." for numbers
        if(typeof value === 'string'){
            value = value.replace(',','.');
        }
        const numValue = Number(value);
        const isNumeric = !isNaN(numValue);
        
        // Handle wildcard pattern matching for a single condition
        if (condition.includes('%')) {
            const regexStr = condition
                .split('%')
                .map(escapeRegex)
                .join('.*');
            const regex = new RegExp(`^${regexStr}$`, 'i');
            return regex.test(String(value));
        }

        // Define operators and find matching one
        const operators = {
            '<>': '!=',
            '>=': '>=',
            '<=': '<=',
            '>': '>',
            '<': '<',
            '=': '='
        };

        let operator = '=';  // default operator
        let compareValue = condition.trim();

        for (const [op] of Object.entries(operators)) {
            if (condition.includes(op)) {
                operator = op;
                compareValue = condition.replace(op, '').trim();
                break;
            }
        }

        // Convert comparison value to number if possible
        const numCompareValue = Number(compareValue);
        const isCompareNumeric = !isNaN(numCompareValue);

        // If both values are numeric, do numeric comparison
        if (isNumeric && isCompareNumeric) {
            switch (operator) {
                case '<>': return numValue !== numCompareValue;
                case '>=': return numValue >= numCompareValue;
                case '<=': return numValue <= numCompareValue;
                case '>': return numValue > numCompareValue;
                case '<': return numValue < numCompareValue;
                case '=': return numValue === numCompareValue;
                default: return false;
            }
        }

        // For non-numeric values, do string comparison
        const strValue = String(value).toLowerCase();
        const strCompareValue = String(compareValue).toLowerCase();

        switch (operator) {
            case '<>': return strValue !== strCompareValue;
            case '=': return strValue === strCompareValue;
            default: return false;
        }
    };

    // First split by OR, then by AND
    const orParts = text.split(/\s+or\s+/i);
    return orParts.some(orPart => {
        const andParts = orPart.split(/\s+and\s+/i);
        return andParts.every(condition => 
            evaluateSingleCondition(value, condition.trim())
        );


    });
}

export function parseConditionFields(column: string, text: string) {
    // Trim spaces and normalize numbers
    text = text.trim();
    text = text.replace(',','.');
    
    // Handle OR conditions first
    const orMatch = /^(.+)\s+or\s+(.+)$/i.exec(text);
    if (orMatch) {
        const [_, leftPart, rightPart] = orMatch;
        // For OR conditions with LIKE patterns, return flat array with | operator
        if (leftPart.includes('%') || rightPart.includes('%')) {
            return '|' as any;  // This will be flattened with the conditions
        }
    }
    
    // Handle AND conditions
    const andMatch = /^(.+)\s+and\s+(.+)$/i.exec(text);
    if (andMatch) {
        const [_, leftPart, rightPart] = andMatch;
        return ['&', 
            parseConditionFields(column, leftPart),
            parseConditionFields(column, rightPart)
        ];
    }

    // Handle single LIKE condition
    if (text.includes('%')) {
        return [column, 'ilike', text];
    }
    // Handle basic comparison operators
    const operatorMatch = /^\s*(<?[=>]>?|!=)?\s*(.+)$/.exec(text);
    if (operatorMatch) {
        const [_, operator, value] = operatorMatch;
        
        // Convert value to number if possible
        const numValue = isNaN(Number(value)) ? value : Number(value);
        
        switch (operator?.trim()) {
            case '>':
                return [column, '>', numValue];
            case '<':
                return [column, '<', numValue];
            case '>=':
                return [column, '>=', numValue];
            case '<=':
                return [column, '<=', numValue];
            case '<>':
            case '!=':
                return [column, '!=', numValue];
            default:
                return [column, '=', numValue];
        }
    }

    // Default case
    return [[column, '=', text]];
}