import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductWithOnlyVariants } from '../../models/product.model';
import {  debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom} from 'rxjs';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { SaleOrderEditorComponent } from 'src/app/sale-order/sale-order-editor/sale-order-editor.component';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { ProductTemplateAttributeLine } from 'src/app/models/product.template.attribute.line';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PriceList } from 'src/app/models/price.list.model';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { ODOO_IDS } from 'src/app/models/deal';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { StockQuant } from 'src/app/models/stock-quant';



@Pipe({ name: 'sortAsNumber' })
export class SortAsNumber implements PipeTransform {
  transform(value: any[], order = '', column: string = ''): any[] {
    return value.sort((a,b) => Number(a.name) - Number(b.name))
  }
}

@Component({
  selector: 'app-order-inventory',
  templateUrl: './order-inventory.component.html'
})

export class OrderInventoryComponent implements OnInit, AfterViewInit {
  @Output() addOrderLine = new EventEmitter<Product>()
  @Input() sales: SaleOrder[]
  @Input() saleIds: string[]
  @Input() saleEditor: SaleOrderEditorComponent
  @Output() loading: EventEmitter<boolean> = new EventEmitter(false)
  inventoryClosed = true;
  @ViewChild(TemplateRef) template: TemplateRef<any>;
  @Input() noDrag:boolean = false
  @Input() noTemplate:boolean = false
  @Input() domain = []
  @Output() toggleInventory = new EventEmitter<boolean>();

  
  ODOO_IDS = ODOO_IDS

  
  inputSearch:BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeTemplate: ProductTemplate = null;
  productToShow: ProductTemplate = new ProductTemplate(45643,'Travatura in abete lamellare');
  showPhotos: boolean = true;

  products: ProductWithOnlyVariants[];
  groups: any;
  // TODO bad way to init prefs
  criteria: {
    toCreate?: any;attributeLine:ProductTemplateAttributeLine, attributeValue:ProductTemplateAttributeValue
    }[] = [];
  isMouseDown: boolean;
  lastValues: ProductTemplateAttributeValue[];
  name: any;
  // selectedTemplate: ProductTemplate = new ProductTemplate(45643,'Travatura in abete lamellare');
  runningSearch$: any;
  refresh$: BehaviorSubject<boolean>= new BehaviorSubject(false);
  searchGroups: {};
  productTemplates: any;
  lastSearch: number;
  showAttrs: boolean = true;
  loaded: boolean = false;
  loadedTemplates: boolean = false;
  loadingAttributes: boolean = false
  onlyFondi: boolean = false;

  constructor(
    private odooEm: OdooEntityManager,
    private elRef:ElementRef,
    private odoorpcService: OdoorpcService
  ) {}

  async noComms(n:string) {
    // replace ", " fonund in string with ""
    return n.replace(", ","")
  }
    

  async ngOnInit() {

    this.refresh$.pipe(debounceTime(400)).subscribe(async () => {
      await this.refresh()



    })

    // await this.loadSearchGroups();

    window.addEventListener("mousedown",e => {
      if (
        this.elRef.nativeElement.querySelector("table").contains(e.target) ||
        this.elRef.nativeElement.querySelector("input") == e.target ||
        this.elRef.nativeElement.querySelector("#inventory") == e.target ||
        this.elRef.nativeElement.querySelector("button") == e.target
      ) {
          this.inventoryClosed = false
        } else {
          this.inventoryClosed = true
        this.isMouseDown = false
      }
    })

    this.inputSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(async x => {
      this.refresh$.next(true)
    });
  }


  openQuants(p:Product) {
    this.odooEm.call2("product.product","action_open_quants",[p.id])
  }

  closeInventory() {
    this.toggleInventory.emit(false);
  }

  async ngAfterViewInit(): Promise<void> {
    let ts = await firstValueFrom(this.odooEm.search<ProductTemplate>(
      new ProductTemplate(), [['product_tag_ids', 'in', [ODOO_IDS.tag_variant_search]]], 30)
    )
    // hack to group by first word in categ_id
    ts.forEach(t => {
      (t as any)._firstCat = t.categ_id.name.split(' ')[0]
    })
    this.productTemplates = this.groupItemBy(ts,'_firstCat')
    this.loadedTemplates = true
  }

  public groupItemBy = function(array, property) {
    var hash = {},
        props = property.split('.');
    for (var i = 0; i < array.length; i++) { 
        var key = props.reduce(function(acc, prop) {
            return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
    }
    return hash;
  } 


  async loadPrice(res:ProductWithOnlyVariants[]) {
    var kwargs = kwargs || {};
    kwargs.context = [];

    var params = {
      model: new PriceList().ODOO_MODEL,
      method: "get_products_price",
      args: [[2],res.map(x => x.id)],
      kwargs: {
        context: null
      },
    };
    var r: any = await this.odoorpcService.sendRequest('/api/web/dataset/call_kw/' + params.model + "/" + params.method, params)
    return r.result ? r.result : []
    
  }


  getFree(p: Product) {
    let available = 0
    // if available - outgoing >0 return available - outgoing, else 0
    if (p.detailed_type === 'consu')
      available = 9999

    else if (p.detailed_type === 'product') {
      available = p.qty_available - p.outgoing_qty
      if (available < 0)
        available = 0
    }

    return available
  }

  getCost(p: ProductWithOnlyVariants) {
    // if it's a fondo, it costs the 50% of list price! basically discounted by 50%
    if (p.additional_product_tag_ids.ids?.includes(20))
      return p.list_price * 0.5
    else 
     return p.list_price
  }

  isFondo(p: ProductWithOnlyVariants) {
    return p.additional_product_tag_ids.ids?.includes(ODOO_IDS.prodTagsFondi.prodTagFondo);
  }

  isAlmostFondo(p: ProductWithOnlyVariants) {
    return p.additional_product_tag_ids.ids?.includes(ODOO_IDS.prodTagsFondi.prodTagConsumaPrima);
  }

  toggleFondi() {
    this.onlyFondi = !this.onlyFondi
    this.refresh()
  }
  

  getReorderingClass(p: ProductWithOnlyVariants) {
    if (p.reordering_min_qty > 0)
      return 'fa-star text-success';
    if (this.isFondo(p))
      return 'fa-trash-clock text-danger';
    if (this.isAlmostFondo(p))
      return 'fa-trash-clock text-warning';
    if (!(p.reordering_min_qty > 0) && !this.isFondo(p) && !this.isAlmostFondo(p) && !p.free_qty)
      return 'fa-cart-shopping text-body-secondary';
    if (!(p.reordering_min_qty > 0) && !this.isFondo(p) && !this.isAlmostFondo(p) && p.free_qty)
      return 'fa-down-to-line text-warning';
    return '';
  }

  
  dragstart(p:Product, ev) {
    console.log("overr1", ev)
    ev.dataTransfer.setData("app-product", "dassad")
    return true
  }
  
  async drop(el:CdkDragDrop<any>) {

  } 

  mouseup() {
    this.isMouseDown = false
  }

  mousedown(ev) {
    this.isMouseDown = true
  }

  mouseover(ev, a:ProductAttributeValue) {
  }

  async getProducts(input): Promise<ProductWithOnlyVariants[]> {
    let filters = [];
    
    if (this.activeTemplate)
      filters.push(['product_tmpl_id', "=", this.activeTemplate.id])
    
    var domain = []

    var cs = this.criteria.filter(c => {
      return (!c.toCreate || !c.attributeLine.display_name.startsWith('Lunghe')) 
    })

    // translate criteria in odoo query
    cs.forEach((x,i) => {
        domain.push('&')
        domain.push(['name', '=', x.attributeValue.name])
        domain.push('&')
        domain.push(['product_tmpl_id', '=', this.activeTemplate?.id])
        domain.push(['attribute_id', '=',x.attributeValue.attribute_id.id])
    });
    
    // odoo use logic operator 
    cs.slice(0,-1).forEach(x => {
      domain.unshift("|")
    })

    if (domain.length) {
      this.lastValues = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), domain))
      this.lastValues.map(x => x.id).forEach(v => {
          filters.push(['product_template_attribute_value_ids','in',v])
        })
    }
    
    if (input)
      input.split(' ').forEach(v => {
        filters.push(
          ['sale_ok', '=', 'true'],
          ['active', '=', 'true'],
          '|',
          ['name', 'ilike', v.replace(/[\s\*]/g, "%25")],
          ['product_template_attribute_value_ids', "ilike", v.replace(/[\s\*]/g, "%25")]
        );

        if (this.domain)
          filters = filters.concat(this.domain)
      });

    // Add filter for fondi products if this.onlyFondi is true
    if (this.onlyFondi) {
      filters.push('|',
        ['additional_product_tag_ids', 'in', [ODOO_IDS.prodTagsFondi.prodTagConsumaPrima]],
        ['additional_product_tag_ids', 'in', [ODOO_IDS.prodTagsFondi.prodTagFondo]]
      );
    }

    let products = await this.odooEm.search<ProductWithOnlyVariants>(new ProductWithOnlyVariants(), filters, 300,null,"free_qty").toPromise();
      
    return products
}

   getInPzFast(p) {
    let description = p.display_name;
    // Trova la parte della stringa tra parentesi
    const regex = /\(([^)]+)\)/;
    const matches = description.match(regex);
  
    if (matches && matches[1]) {
      // Estrae i numeri all'inizio della stringa trovata
      const numbers = matches[1].split(',', 3);

        //il primo numero è la larghezza, il secondo l'altezza, il terzo la lunghezza in mm. li converto da stringhe a numero
      let la = parseInt(numbers[0])
      let al = parseInt(numbers[1])
      let lu = parseInt(numbers[2])
      
      let pz = 0;

      //calcolo il numero di pezzi dalla quantità disponibile
      //se unità misura = metri cubi (uom id = 11) moltiplico tutte e 3 le dimensioni e divido per 1000000000
      if (p.uom_id.id == 11 && la && al && lu) {
         pz = la * al * lu / 1000000000;}
      //se unità misura = metri quadrati (uom id = 9) moltiplico le prime due dimensioni e divido per 1000000
      if (p.uom_id.id == 9 && la && lu) {
         pz = la * lu / 1000000;}
      //se unità misura = metri lineari (uom id = 5) divido la lunghezza per 1000
      if (p.uom_id.id == 5 && lu) {
         pz = lu / 1000;}
      if (pz > 0 && this.getFree(p)>0){ 
        const result = Math.round((this.getFree(p) / pz) * 100) / 100;
        return result + " pz";}
      }
    // Restituisce null se non sono stati trovati tre numeri all'inizio della descrizione tra parentesi
    return null;
  }

  canCreateVariant() {
    if (!this.activeTemplate)
      return false
    // todo fix -4
    return (this.criteria.length == this.activeTemplate.attribute_line_ids.ids.length)
  }

  async createVariant() {
    var domain = []
    
    // translate criteria in odoo query
    this.criteria.forEach((x,i) => {
      domain.push('&')
      domain.push(['name', '=', x.attributeValue.name])
      domain.push('&')
      domain.push(['product_tmpl_id', '=', this.activeTemplate.id])
      domain.push(['attribute_id', '=',x.attributeValue.attribute_id.id])
    });

    // odoo use logic operator 
    this.criteria.slice(0,-1).forEach(x => {
      domain.unshift("|")
    })

    var val = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), domain))
    var aa = val.map(v => v.id)

    // aa = aa.concat(3389,3401,3402)

  

    var r:any = await this.odooEm.odoorpcService.sendRequest('/api/sale/create_product_variant', {
      "product_template_attribute_value_ids": JSON.stringify(aa),
      "product_template_id": this.activeTemplate.id
    });
    
    //pezzo pack
    await this.odooEm.run(666, r.result, "product.product" )
    this.refresh()
  }

  hasCriteria(c) {
    return this.criteria.find(x => {
      return x.attributeLine.id == c.attributeLine.id && x.attributeValue.id == c.attributeValue.id
    })
  }

  getIconClass(a: any): string {
    if (!this.canCreateVariant() && this.products.length === 0 && this.loaded) {
      const hasCriteria = this.criteria.find(x => x.attributeLine.id === a.id);
      if (!hasCriteria) {
        return 'fa-times text-danger';
      }
      return 'fa-check text-success';
    }
    return this.criteria.find(x => x.attributeLine.id === a.id) ? 
      'fa-check text-primary' : '';
  }
  
  getTextClass(a: any): string {
    if (!this.canCreateVariant() && this.products.length === 0 && this.loaded) {
      const hasCriteria = this.criteria.find(x => x.attributeLine.id === a.id);
      if (!hasCriteria) {
        return 'text-danger';
      }
      return 'text-success';
    }
    return this.criteria.find(x => x.attributeLine.id === a.id) ? 
      'text-primary' : '';
  }


  getCriteriaName(a: ProductTemplateAttributeLine): string {
    let selectedValue = '';
    a.value_ids.values.forEach(v => {
      if (this.hasCriteria({attributeLine: a, attributeValue: v})) {
        selectedValue = v.name;
      }
    });
    return selectedValue || `Qualsiasi`;
  }
  
  async toggleCriteria(c) {
    console.log("TOGGLE CRITER",c)
    var index = this.criteria.findIndex(
      x => x.attributeLine.id == c.attributeLine.id
        && x.attributeValue.id == c.attributeValue.id)    
    if (index === -1) {
      // clear last active
      this.criteria = this.criteria.filter(x => x.attributeLine.id != c.attributeLine.id)
      this.criteria.push(c);
    } else
      this.criteria.splice(index, 1)
    var x = []
    this.criteria.forEach(c => 
      x.push({a:c.attributeLine.id, v:c.attributeValue.id})
    )
    window.localStorage.setItem("order-inventory-criteria", JSON.stringify(x))
  }
  
  async toggleProductTemplate(id:number) {
    this.loading.next(true)
    //if already selected, deselect it
    if (this.activeTemplate && this.activeTemplate.id) {
      
    if (this.activeTemplate.id === id) {
      this.productToShow = this.activeTemplate
      this.activeTemplate = null
      this.loading.next(false)
      return
    }
  }
  this.loadingAttributes = true

    //if no activeTemplate, put the last used one
    if (!id) {
      id = this.productToShow.id
    }
    //select the new template
    this.activeTemplate = null  
    var res = await firstValueFrom(this.odooEm.search<ProductTemplate>(new ProductTemplate(), [['id', '=', id]], 1)) 
    this.activeTemplate = res[0]
    await this.odooEm.resolve(res[0].attribute_line_ids).toPromise()
    await this.odooEm.resolveArray(new ProductAttributeValue(), res[0].attribute_line_ids.values, "value_ids").toPromise()
 this.loadingAttributes = false
    
      this.criteria = []
      // prefill hidden criterias
      this.activeTemplate.attribute_line_ids.values.map(a => {
        if (a.value_ids.ids.length == 1 || a.attribute_id.name.startsWith('Lunghezza'))
        this.toggleCriteria({attributeLine: a, attributeValue: a.value_ids.values[0],toCreate: true})
        // return 
      })
  
    this.productToShow = this.activeTemplate
    console.log("xxxxxactiveTemplate is 2", this.activeTemplate)
    
    this.inventoryClosed = false
    this.refresh$.next(true)
    this.loading.next(false)

  }


  close() {
    this.inventoryClosed = true  
  }

  async refresh() {
    this.loaded = false
    if (this.inputSearch.value == "" && !this.activeTemplate && !this.onlyFondi) {
      this.products = []
      return
    }
   
    this.loading.next(true)

    let x= Math.random()
    this.lastSearch = x
    var products = await this.getProducts(this.inputSearch.value)
    if (this.lastSearch != x) {
      return
    }

        // Assign sort sequence number to each product
        products.forEach(p => {
          if (this.isFondo(p) && p.free_qty) {
              p._sortSequence = 1; // First priority - products to be consumed
          } else if (this.isAlmostFondo(p) && p.free_qty) {
              p._sortSequence = 2; // Second priority - products to be consumed
          } else if (!(p.reordering_min_qty > 0) && !p.additional_product_tag_ids.ids?.includes(20) && p.free_qty) {
              p._sortSequence = 3; // third priority - non-standard products with stock
          } else if (p.reordering_min_qty > 0 ) {
              p._sortSequence = 4; // fourth priority - standard products
          } else {
              p._sortSequence = 5; // Everything else
          }
      });
  
      // Sort products by sequence and free_qty
      products.sort((a, b) => {
          // First compare by sort sequence
          if (a._sortSequence !== b._sortSequence) {
              return a._sortSequence - b._sortSequence;
          }
          // Within same sequence, sort by free_qty descending
          return b.free_qty - a.free_qty;
      });
    
    this.products = products;

    var prices = await this.loadPrice(this.products)
    this.products.forEach(p => {
      p._lst_price = prices[p.id]
    })
    this.loading.next(false)


    console.log("aaaaa fetched products before resolve", this.products)
    
//here i basically can start working, i only need to solve adding the stock quants for app photos
await firstValueFrom(this.odooEm.resolveArray(new StockQuant(), this.products, "stock_quant_ids"))
let quants = this.products.map(p => p.stock_quant_ids.values).flat() 
if (quants.length > 0){
  console.log("quants", quants)
await firstValueFrom(this.odooEm.resolveArrayOfSingle(new StockQuantPackage(), quants, "package_id"))
}

console.log("fetched products after resolve", this.products)
this.loaded = true //uset to show pack photos
}

  async insert(p:Product) {

    if (!this.sales && this.addOrderLine) {
      this.addOrderLine.next(p)
      return
    }

    var s = this.sales.find(x => x._open)
    if (s) {
      this.saleEditor.insertProduct(s,p,10000)
    }
  }
}
