import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild,} from "@angular/core";
import { OdooEntityManager } from "../shared/services/odoo-entity-manager.service";
import "moment/locale/it";
import { ActivatedRoute } from "@angular/router";
import { OdoorpcService } from "../shared/services/odoorpc.service";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-message-widget",
  templateUrl: "./message-widget.component.html",
  styleUrls: ["./message-widget.component.scss"],
})
export class MessageWidgetComponent implements OnInit, AfterViewInit {

  @ViewChild("emebedodoo") private embedOdoo: ElementRef;

  @Input() id: string = "0";
  @Input() action: string = "0";

  odooUrl: SafeResourceUrl = "";

  constructor(
    private odooEm: OdooEntityManager,
    private http: HttpClient,
    public route: ActivatedRoute,
    public odoorpcService: OdoorpcService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    console.log("openned message widget", this.id, this.action);
    // this.initializeComponent();
     this.odooEm.odoorpcService.getSessionInfo().then(x => {
       this.odooUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://o3.galimberti.eu/web?#cids=1&action="+this.action+"&model=crm.lead&view_type=form&id=" + this.id);
     })
  }

  onLoadIframe() {
    console.log('iframe loaded', this.embedOdoo.nativeElement);
    // iniject inline style to hide the header
    this.embedOdoo.nativeElement.contentWindow.document.head.insertAdjacentHTML(
      'beforeend',
      '<style>.o_navbar { display: none !important;}</style>'
    );
    console.log('iframe loaded');
  }

  // ALL COMMEMNTED CODE IS FROM HERE CAUSE SUBSTITUTED BY THE IFRAME

ngAfterViewInit(): void {
    // this.setupPasteEventListener();
  }
  /*
  


  




  private async initializeComponent(): Promise<void> {
    const result: any = await this.odooEm.odoorpcService.getSessionInfo();
    this.userId = result.result.partner_id;
    await this.load();
    // this.scrollToBottom();
  }

  private setupPasteEventListener(): void {
    this.messageInput.nativeElement.addEventListener(
      "paste",
      (e: ClipboardEvent) => {
        e.preventDefault();

        // Get both plain text and HTML content
        const htmlText = e.clipboardData.getData("text/html");     
       document.execCommand("insertHTML", false, htmlText);
      }
    );
  }  

  formatFileSize(bytes: number): string {
    if (bytes < 1024) return bytes + " B";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
    else return (bytes / 1048576).toFixed(1) + " MB";
  }

  autoResize(textarea: HTMLTextAreaElement): void {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  getMessageTypeIcon(type: string): string { //""|"email" | "comment" | "notification" | "user_notification" | "sms" | "snailmail" = ""
    switch (type) {
      case 'email':
        return 'fa-solid fa-envelope'; // email
      case 'sms':
        return 'fa-solid fa-paper-plane'; // Outgoing SMS
      case 'comment':
        return 'fa-solid fa-comment'; // Regular comment
      case 'notification':
        return 'fa-solid fa-bell'; // System notification
      case 'snailmail':
        return 'fa-solid fa-robot'; // Snail mail
      case 'user_notification':
        return 'fa-solid fa-circle-info'; // User specific notification
      default:
        return 'fa-solid fa-message';
    }
  }

  @HostListener("document:show.bs.dropdown", ["$event"])
  onShow(event: any): void {
    // setTimeout(() => this.scrollToBottom(), 1);
  }

  toggleReactions(messageId: number): void {
    this.currentReactionMessageId =
      this.currentReactionMessageId === messageId ? null : messageId;
  }

  customFromNow(date: string): string {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const dateMoment = moment(date);

    if (dateMoment.isSame(today, "d")) return "Oggi";
    if (dateMoment.isSame(yesterday, "d")) return "Ieri";
    const daysAgo = today.diff(dateMoment, "days");
    return `${daysAgo} giorni fa`;
  }

  async load(): Promise<void> {
    try {
      // 1. Fetch the messages
      let messages = await firstValueFrom(
        this.odooEm.search<MailMessage>(
          new MailMessage(),
          [
            ["model", "=", this.model],
            ["res_id", "=", this.res_id],
          ],
          null,
          null,
          "date desc"
        )
      );

      //when deleting a comment, odoo resets to null the body and attachment_ids but does not delete the record (why ????)
      //to avoid empty records we have to fetch: messages that are not type === comment and comments that have a body or an attachment
      messages = messages.filter((m) => {
        if (m.message_type !== "comment") return true;
        return m.body || m.attachment_ids.ids.length > 0;
      });


      // 2. Resolve all relations in parallel using resolveArray/resolveArrayOfSingle
      await Promise.all([
        // Resolve authors for all messages
        firstValueFrom(
          this.odooEm.resolveArrayOfSingle(new Partner(), messages, "author_id")
        ),

        // Resolve attachments for all messages
        firstValueFrom(
          this.odooEm.resolveArray(
            new IrAttachment(),
            messages,
            "attachment_ids"
          )
        ),

        // Resolve reactions for all messages
        firstValueFrom(
          this.odooEm.resolveArray(
            new MailMessageReaction(),
            messages,
            "reaction_ids"
          )
        ),

        // Resolve tracking values
        firstValueFrom(
          this.odooEm.resolveArray(
            new MailTracking(),
            messages,
            "tracking_value_ids"
          )
        ),
      ]);

      let trackings = this.odooEm.search<MailTracking>(new MailTracking());
      console.log("Before resolution", trackings);

      console.log(
        "After resolution - checking tracking values:",
        messages.map((m) => ({
          messageId: m.id,
          trackingIds: m.tracking_value_ids.ids,
          trackingValues: m.tracking_value_ids.values,
        }))
      );

      // 3. Format dates and group messages
      const groupedMessages = this.groupMessagesByDate(messages);
      this.groupMessage = Object.entries(groupedMessages).map(
        ([dateFromNow, messages]) => ({
          dateFromNow,
          messages,
        })
      );

      console.log("Messages with resolved relations:", messages);
    } catch (error) {
      console.error("Error loading messages:", error);
    }
  }

  private groupMessagesByDate(messages: any[]): { [key: string]: any[] } {
    const groupedMessages = {};
    messages.forEach((message) => {
      message.dateHour = moment(message.date).format("HH:mm");
      message.dateFromNow = this.customFromNow(message.date);

      if (!groupedMessages[message.dateFromNow]) {
        groupedMessages[message.dateFromNow] = [];
      }
      groupedMessages[message.dateFromNow].push(message);
    });
    return groupedMessages;
  }

  async getSuggestion(key: string): Promise<void> {
    try {
      const params = {
        model: new Partner().ODOO_MODEL,
        method: "get_mention_suggestions",
        kwargs: {
          search: key,
          context: {
            lang: "it_IT",
            tz: "Europe/Rome",
            uid: 59,
            allowed_company_ids: [1],
          },
        },
        args: [],
      };

      const response: any = await this.odoorpcService.sendRequest(
        "/api/web/dataset/call_kw/" + params.model + "/" + params.method,
        params
      );
      this.suggestions = Array.isArray(response.result) ? response.result : [];
      this.showSuggestions = this.suggestions.length > 0;
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      this.suggestions = [];
      this.showSuggestions = false;
    }
  }

  filterMessages(messages: any[]): any[] {
    if (this.hideNotifications) {
      return messages.filter(
        (message) => message.message_type !== "notification"
      );
    }
    return messages;
  }

  getFilteredGroups(): { dateFromNow: string; messages: any[] }[] { //if i toggle notification filter there's the risk of leaving empty broups, so i can delete them 
    if (!this.groupMessage) return [];

    return this.groupMessage
      .map(group => ({
        dateFromNow: group.dateFromNow,
        messages: this.hideNotifications 
          ? group.messages.filter(message => message.message_type !== "notification")
          : group.messages
      }))
      .filter(group => group.messages.length > 0); // Remove empty groups
  }

  getMessageBody(message: any): string {
    //come funziona su odoo:
    // message type ti dice che tipo di messaggio è (notifica di sistema, commento, email, ecc)
    // se è un commento fatto da un user, il body è il testo del commento (easy)
    // se è una notifica di sistema, il subtype_id è il tipo di notifica (es. "modifica della fase, opp vinta, cliente modificato...")
    // il subtype può essere sostituito con il field dei tracking values che sarebbe la descrizione del campo!
    // la notifica ha valori vecchi e valori nuovi che sono i tracking value ids

    if (message.message_type === "notification") {
      let body = "";
      //we have to build the message body with the tracking values....
      // body += message.subtype_id.name; changed this to field name in tracking values!!!
      body += message.tracking_value_ids.values[0]?.field.name;
      // if there's and old value or a new value, we can show the change
      if (this.getOldValue(message) || this.getNewValue(message)) {
      body += ": ";
      body += this.getOldValue(message);
      body += " ➔ ";
      body += this.getNewValue(message);
      }
      return body;
    }
    //if it is a comment, we just return the body
    return message.body;
  }

  getOldValue(message: any): string {
    // we have liek 10 tracking values, we have to check if they are not empty and then build the body.... each field is a tracking value. 
    //For now we use only char and monetary fields

    let body = "";
    for (let i = 0; i <= message.tracking_value_ids.ids.length - 1; i++) {
      
      if (
        message.tracking_value_ids.values[i]?.old_value_char !== "" ||
        message.tracking_value_ids.values[i]?.old_value_char !== null ||
        message.tracking_value_ids.values[i]?.old_value_char !== false
      ) {
        //save if not false
        body += message.tracking_value_ids.values[i].old_value_char ? message.tracking_value_ids.values[i].old_value_char : "";
      }
      if (
        message.tracking_value_ids.values[i]?.old_value_monetary !== "" ||
        message.tracking_value_ids.values[i]?.old_value_monetary !== null ||
        message.tracking_value_ids.values[i]?.old_value_monetary !== false
      ) {
        body += message.tracking_value_ids.values[i].old_value_monetary ? message.tracking_value_ids.values[i].old_value_monetary.toFixed(2) : "";
      }
    
    }
    return body;
  }

  getNewValue(message: any): string {
    let body = "";
    for (let i = 0; i <= message.tracking_value_ids.ids.length - 1; i++) {
      if (
        message.tracking_value_ids.values[i]?.new_value_char !== "" ||
        message.tracking_value_ids.values[i]?.new_value_char !== null ||
        message.tracking_value_ids.values[i]?.new_value_char !== false
      ) {
        //save if not false
        body += message.tracking_value_ids.values[i].new_value_char ? message.tracking_value_ids.values[i].new_value_char : "";
      }
      if (
        message.tracking_value_ids.values[i]?.new_value_monetary !== "" ||
        message.tracking_value_ids.values[i]?.new_value_monetary !== null ||
        message.tracking_value_ids.values[i]?.new_value_monetary !== false
      ) {
        body += message.tracking_value_ids.values[i].new_value_monetary ? message.tracking_value_ids.values[i].new_value_monetary.toFixed(2) : "";
      }
    }

    return body;
  }

  selectSuggestion(suggestion: any): void {
    const atPosition = this.message.lastIndexOf("@");
    if (atPosition !== -1) {
      this.message = `${this.message.substring(0, atPosition)}@${
        suggestion.name
      } `;
      this.suggestions = [];
      this.showSuggestions = false;
      this.atSignDetected = false;
    }
  }

  isImage(attachment: any): boolean {

    //this is a simple check to see if the attachment is an image. attachment.mimetype is "image/gif"
    return attachment.mimetype.includes("image");
  }

  onKeyup(event: KeyboardEvent): void {
    const inputValue: string = (event.target as HTMLInputElement).value;
    const atPosition = inputValue.lastIndexOf("@");

    if (atPosition !== -1) {
      this.atSignDetected = true;
      const query = inputValue.substring(atPosition + 1);
      this.getSuggestion(query);
    } else {
      this.resetSuggestionState();
    }
  }

  private resetSuggestionState(): void {
    this.atSignDetected = false;
    this.suggestions = [];
    this.showSuggestions = false;
  }

  async fetchCSRFToken(): Promise<string> {
    try {
      const url = "/api/get_csrf_token";
      const headers = new HttpHeaders({ "Content-Type": "application/json" });
      const response: any = await this.http
        .post(url, {}, { headers })
        .toPromise();
      return response.result.csrf_token;
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      return null;
    }
  }

  async confirmMessage(): Promise<void> {
    if (!this.message.trim() && this.attachmentIds.length === 0) return;

    try {
      // First get all partner information for the mentions
      const { partnerIds, partnerInfo } =
        await this.extractMentionedPartnerInfo(this.message);

      // Format message with proper Odoo-style mentions
      let formattedMessage = this.message;

      // Replace each @mention with proper HTML link
      for (const partner of partnerInfo) {
        const mentionRegex = new RegExp(`@${partner.name}\\b`, "g");
        const odooLink = this.createOdooMentionLink(partner.id, partner.name);
        formattedMessage = formattedMessage.replace(mentionRegex, odooLink);
      }

      // Convert line breaks after replacing mentions
      formattedMessage = formattedMessage
        .split("\n")
        .map((line) => line || " ")
        .join("<br>\n");

      // Send message using odooEm.postMessage. here i can collect body, tags and attachments. 


      /// MISSING PROCETURE TO FETCH ATTACHMENTS!!!! -->>>> GIULIO
      const attachments = this.attachmentIds || [];
      await this.odooEm.postMessage(
        attachments,
        partnerIds,
        formattedMessage,
        Number(this.res_id),
        this.model
      );
      this.resetMessageState();
      await this.load();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  }

  private createOdooMentionLink(
    partnerId: number,
    partnerName: string
  ): string {
    const baseUrl = window.location.origin;
    const href = `${baseUrl}/web#model=res.partner&id=${partnerId}`;
    return `<a href="${href}" class="o_mail_redirect" data-oe-id="${partnerId}" data-oe-model="res.partner" target="_blank">@${partnerName}</a>`;
  }

  private async extractMentionedPartnerInfo(
    message: string
  ): Promise<{ partnerIds: number[]; partnerInfo: any[] }> {
    const mentionRegex = /@([^\s]+)/g;
    const mentions = message.match(mentionRegex);

    if (!mentions) return { partnerIds: [], partnerInfo: [] };

    const mentionedNames = mentions.map((mention) => mention.substring(1));

    try {
      const params = {
        model: new Partner().ODOO_MODEL,
        method: "get_mention_suggestions",
        kwargs: {
          search: mentionedNames.join(","),
          context: {
            lang: "it_IT",
            tz: "Europe/Rome",
            uid: this.userId,
            allowed_company_ids: [1],
          },
        },
        args: [],
      };

      const response: any = await this.odoorpcService.sendRequest(
        "/api/web/dataset/call_kw/" + params.model + "/" + params.method,
        params
      );

      // Filter and map the response to get both IDs and full partner info
      const partnerInfo = response.result.filter((partner) =>
        mentionedNames.includes(partner.name)
      );

      return {
        partnerIds: partnerInfo.map((p) => p.id),
        partnerInfo: partnerInfo,
      };
    } catch (error) {
      console.error("Error resolving partner information:", error);
      return { partnerIds: [], partnerInfo: [] };
    }
  }

  private resetMessageState(): void {
    this.attachmentIds = [];
    this.selectedFiles = [];
    this.message = "";

    // Reset textarea height
    const textarea = this.messageInput.nativeElement;
    textarea.style.height = "auto";
  }

  async deleteMessage(message: MailMessage) {
    //to update the message, odoo resets to null its body and attachment_ids
    console.log("Deleting message", message);
    if(!message.id) return;
    if (!confirm("Eliminare il commento?"))
      return;
    await this.odooEm.update(message, {body: '', attachment_ids: [], }  );
    this.load();
  }


  async uploadFile(event: any): Promise<void> {
    const files: FileList = event.target.files;
    if (!files || files.length === 0) return;

    this.isUploading = true;
    try {
      // Fetch CSRF token if we don't have it yet
      if (!this.csrfToken) {
        try {
          const response: any = await this.http
            .post("/api/get_csrf_token", {})
            .toPromise();
          this.csrfToken = response.result.csrf_token;
        } catch (error) {
          console.error("Error fetching CSRF token:", error);
          throw new Error("Could not get CSRF token");
        }
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();

        // Add required data to formData
        formData.append("ufile", file, file.name);
        formData.append("model", this.model); // Add the current model
        formData.append("res_id", this.res_id.toString()); // Add the current record ID
        formData.append("csrf_token", this.csrfToken);
        formData.append("name", file.name);
        formData.append("mimetype", file.type || "application/octet-stream");
        formData.append("type", "binary");

        console.log(
          "Uploading file:",
          file.name,
          "to model:",
          this.model,
          "res_id:",
          this.res_id
        );

        try {
          // Use fetch API for upload
          const uploadResponse = await fetch("/api/mail/attachment/upload", {
            method: "POST",
            body: formData,
            credentials: "include", // Important for session cookie
          });

          if (!uploadResponse.ok) {
            throw new Error(`HTTP error! status: ${uploadResponse.status}`);
          }

          const responseData = await uploadResponse.json();
          console.log("Upload response:", responseData);

          if (responseData.id) {
            this.attachmentIds.push(responseData.id);
            this.selectedFiles.push(file);
          } else {
            console.error(
              "Upload succeeded but no attachment ID received:",
              responseData
            );
          }
        } catch (uploadError) {
          console.error("Error uploading file:", file.name, uploadError);
          // You might want to show an error message to the user here
        }
      }
    } catch (error) {
      console.error("Error in upload process:", error);
      // You might want to show an error message to the user here
    } finally {
      this.isUploading = false;
      // Reset file input so the same file can be selected again
      event.target.value = "";
    }
  }

  async setReaction(
    event: Event,
    message: MailMessage,
    body: string
  ): Promise<void> {
    event.stopPropagation();
    try {
      const result: any = await this.odooEm.odoorpcService.getSessionInfo();
      const reaction = {
        message_id: message.id,
        content: body,
        partner_id: result.result.partner_id,
      };

      const newReaction = await firstValueFrom(
        this.odooEm.create<MailMessageReaction>(
          new MailMessageReaction(),
          reaction
        )
      );
      message.reaction_ids.values.push(newReaction);
      this.toggleReactions(message.id);
    } catch (error) {
      console.error("Error setting reaction:", error);
      // Implement user feedback for error
    }
  }

  */
}
