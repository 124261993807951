import { ChangeDetectorRef, Component, OnInit, HostListener  } from '@angular/core';
import { SaleOrderFlash} from '../models/sale-order.model';
import { BehaviorSubject, debounceTime, firstValueFrom } from 'rxjs';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { Contact } from '../models/contact.model';
import { AREAS_CFG, ODOO_IDS } from '../models/deal';
import { DriveFolder } from '../models/drive.folder';
import { TrelloCardEntry2 } from '../models/trello-card';
import { MailActivity } from '../models/mail.message';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sale-search',
  templateUrl: './sale-search.component.html',
  styleUrls: ['./sale-search.component.scss']
})
export class SaleSearchComponent implements OnInit {
  sales: SaleOrderFlash[];
  loading: boolean;
  searchInput: BehaviorSubject<string> = new BehaviorSubject("");
  filterToShip: boolean = true;
  filterShipped: boolean = false;
  filterList: boolean = true;
  newSale: SaleOrderFlash = new SaleOrderFlash();
  users = []; // Array to hold HR employees
  selectedUser: string = ''; // Selected user ID
  startDate: string; // Start date for filtering
  endDate: string; // End date for filtering
  cfg: { name: string; src: string; dst: string; preventivo_trello_board: string; preventivo_trello_list: string; project_src: string; project_trello: string; project_trello_board: string; produzione_src: string; produzione_dst: string; produzione_trello: string; produzione_trello_board: string; produzione_link_name: string; pos_src: string; pos_dst: string; pos_trello_board: string; pos_trello_list: string; pos_template_card: string; pos_link_name: string; purchase_drive_src: string; purchase_drive_dst: string; purchase_link_name: string; production_product_id: number; } | { name: string; src: string; dst: string; preventivo_trello_board: string; preventivo_trello_list: string; project_src: string; project_trello: string; project_trello_board: string; produzione_src: string; produzione_dst: string; produzione_trello: string; produzione_trello_board: string; produzione_link_name: string; pos_src: string; pos_dst: string; pos_trello_board: string; pos_trello_list: string; pos_template_card: string; pos_link_name: string; purchase_drive_src: string; purchase_drive_dst: string; purchase_link_name: string; production_product_id?: undefined; } | { name: string; src: string; dst: string; preventivo_trello_list: string; preventivo_trello_board: string; pos_src: string; pos_dst: string; pos_trello_board: string; pos_trello_list: string; pos_template_card: string; pos_link_name: string; project_src?: undefined; project_trello?: undefined; project_trello_board?: undefined; produzione_src?: undefined; produzione_dst?: undefined; produzione_trello?: undefined; produzione_trello_board?: undefined; produzione_link_name?: undefined; purchase_drive_src?: undefined; purchase_drive_dst?: undefined; purchase_link_name?: undefined; production_product_id?: undefined; };
  isMobileView: boolean = false;
  
  constructor(
    private odooEm: OdooEntityManager,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  // Listen to window resize events
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isMobileView = window.innerWidth < 768; // Bootstrap's md breakpoint
    this.cdr.detectChanges(); // Ensure view updates
    console.log('isMobileView', this.isMobileView);
  }

  
  async ngOnInit(): Promise<void> {
    // Check screen size
    this.checkScreenSize();
    this.cfg = AREAS_CFG.filter(a => a.name == 'Tetti')[0];
    await this.load();   
    this.searchInput.pipe(debounceTime(250)).subscribe(() => {
      this.load();
    });
  }

  
  async load() {
    this.loading = true;
    const conditions: any[] =[]

    //if filterList is active, i only filter the sales without opportunity_i
    if (this.filterList) 
      conditions.push(['opportunity_id', '=', null])

    // Apply filters

    if (this.filterToShip && !this.filterShipped) {
      conditions.push(['delivery_status', '!=', 'full']);
    } else if (!this.filterToShip && this.filterShipped) {
      conditions.push(['delivery_status', '=', 'full']);
    } else if (this.filterToShip && this.filterShipped) {
      // Both filters active, no additional condition needed
    } else {
      // Neither filter active, show none
      conditions.push(['id', '=', 0]);
    }
    if (this.selectedUser) {
      conditions.push(['user_id.name', '=', this.selectedUser]);
    }
    if (this.searchInput.value) {
      conditions.push('|', '|',
        ['name', 'ilike', this.searchInput.value],
        ['partner_id', 'ilike', this.searchInput.value],
        ['ga_title', 'ilike', this.searchInput.value]
      );
    }
    if (this.startDate) {
      conditions.push(['date_order', '>=', this.startDate]);
    }
    if (this.endDate) {
      conditions.push(['date_order', '<=', this.endDate]);
    }

    try {
      this.sales = await firstValueFrom(this.odooEm.search<SaleOrderFlash>(new SaleOrderFlash(), conditions, 200));
      await this.loadActivities();
      await firstValueFrom(this.odooEm.resolveArray(new DriveFolder(), this.sales, "drive_folder_ids"));
      await firstValueFrom(this.odooEm.resolveArray(new TrelloCardEntry2(), this.sales, "trello_card_ids"));
    } catch (error) {
      console.error('Error loading sales:', error);
    } finally {
      this.users = this.sales.map(s => s.user_id.name).filter((v, i, a) => a.indexOf(v) === i);
      this.loading = false;
      this.cdr.detectChanges(); // Force change detection
    }


  }

  async loadActivities() {
    try {
      await firstValueFrom(this.odooEm.resolveArray(new MailActivity(), this.sales, "activity_ids"));
    } catch (error) {
      console.error('Error loading activities:', error);
    }
  }

  onContact(c:Contact) {
    this.newSale.partner_id.value = c
  }

  async create() {
    this.loading = true
    await firstValueFrom(this.odooEm.create(new SaleOrderFlash(), {
      partner_id: this.newSale.partner_id.value ? this.newSale.partner_id.value.id : null
    }))
    await this.load()
    this.loading = false
  }

  resetFilters() {
    this.searchInput.next('');
    this.selectedUser = '';
    this.startDate = null;
    this.endDate = null;
    this.filterToShip = true;
    this.filterShipped = false;
    this.filterList = true;
    this.load();
  }

  // Method to handle row clicks
  onRowClick(saleId: number) {
    console.log('Screen width:', window.innerWidth); // Debug log
    console.log('Is mobile view:', this.isMobileView); // Debug log
    if (this.isMobileView) {
      this.router.navigate(['/flash-sale', saleId]);
    } else {
      //if there is opportunity id, go to the lead page ['/leads', lead.id , 'sale' , s.id]"
      if(this.sales.find(s => s.id == saleId).opportunity_id.id){
        this.router.navigate(['/leads', this.sales.find(s => s.id == saleId).opportunity_id.id, 'sale', saleId]);
      }
      else{
      //else go to the sale page ['/immediate-sale/s', s.id]
      this.router.navigate(['/immediate-sale/s', saleId]);
      }
    }
  }

}
