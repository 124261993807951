import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ContactSearchComponent } from './contact/contact-search.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { PickingSearchComponent } from './picking-search/picking-search.component';
import { DealsDashboardComponent } from './deals/deals-dashboard/deals-dashboard.component';
import { DealDetailComponent } from './deals/deal-detail/deal-detail.component';
import { PackageDetailComponent } from './sale-picking/package-detail/package-detail.component';
import { SearchComponent } from './search/search.component';
import {CallbackComponent} from './callback/callback.component';
import { SaleOrderDetailComponent } from './components/sale-order-detail/sale-order-detail.component';
import { PurchaseOrderDetailComponent } from './components/purchase-order-detail/purchase-order-detail.component';
import { GmailImportComponent } from './gmail/gmail-import/gmail-import.component';
import { OdoorpcService } from './shared/services/odoorpc.service';
import { PickingEditorPage } from './picking/picking-editor-page/picking-editor-page.component';
import { SaleOrderEditorComponent } from './sale-order/sale-order-editor/sale-order-editor.component';
import { BadgeKioskComponent } from './badge-kiosk/badge-kiosk.component';
import { TimeOffComponent } from './time-off/time-off.component';
import { ForkliftPageComponent } from './forklift/forklift-page/forklift-page.component';
import { StatusComponent } from './status/status.component';
import { PurchasePrintComponent } from './purchase-print/purchase-print.component';
import { SalePrintComponent } from './sale-print/sale-print.component';
// import { PackPageComponent } from './picking/pack-page/pack-page.component';
import { BadgeWriteComponent } from './badge-kiosk/badge-write/badge-write.component';
import { BadgeReadComponent } from './badge-kiosk/badge-read/badge-read.component';
import { BadgeAdminComponent } from './badge-kiosk/badge-admin/badge-admin.component';
import { ExplorerComponent } from './explorer/explorer.component';
import { DashboardComponent } from './flow/dashboard.component';
import { MindmapComponent } from './flow/mindmap/mindmap.component';
import { ScannerZxingComponent } from './barcode-scanner/scanner-zxing/scanner-zxing.component';
import { MilvusComponent } from './milvus/milvus.component';
import { SaleSearchComponent } from './sale-search/sale-search.component';
import { CatalogueSearchComponent } from './catalogue/search/search.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { FlashSaleOrderComponent } from './flash-sale-order/flash-sale-order.component';
import { FlashSaleOrderEditorComponent } from './flash-sale-order-editor/flash-sale-order-editor.component';
import { DeliveryNoteSearchComponent } from './delivery-note-search/delivery-note-search.component';
import { DeliveryNoteViewerComponent } from './delivery-note-viewer/delivery-note-viewer.component';
import { PickingInComponent } from './picking/picking-in/picking-in.component';
import { PackageChooserComponent } from './picking/package-chooser/package-chooser.component';
import { PickingInternalComponent } from './picking/picking-internal/picking-internal.component';
import { TimelineComponent } from './timeline/timeline.component';
import { OddmentsComponent } from './oddments/oddments.component';
import { ProductionEditorComponent } from './production-editor/production-editor.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { ProductionsSearchComponent } from './productions/productions-search/productions-search.component';
import { DeliveryNoteViewer2Component } from './delivery-note-viewer2/delivery-note-viewer2.component';
import { InvoiceSearchComponent } from './invoice-search/invoice-search.component';
import { ActivityEditorComponent } from './activity-editor/activity-editor.component';
import { PackageListComponent } from './package-list/package-list.component';
import { CalendarComponent } from './calendar/calendar.component';

export const routes: Routes = [
  {
    path: 'productions',
    component: ProductionsSearchComponent,
    title: 'Produzioni'
  },
  {
    path: 'scantest',
    component: ScannerZxingComponent,
    title: 'Scanner'
  },
  {
    path: 'sid/:sid',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [OdoorpcService],
    title: 'Home'
  },
  {
    path: 'badge',
    component: BadgeKioskComponent,
  },
  {
    path: 'badge/admin',
    component: BadgeAdminComponent,
    canActivate: [OdoorpcService]

  },
  {
    path: 'badge/write/:id',
    component: BadgeWriteComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'badge/read/:id',
    component: BadgeReadComponent
  },
  {
    path: 'badge/login/:p',
    component: BadgeKioskComponent
  },
  {
    path: 'time-off/:employee_id',
    component: TimeOffComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [OdoorpcService],
    title: 'Home'
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  // {
  //   path : "contact/new",
  //   component: EditContactDetailComponent,
  //   canActivate: [OdoorpcService]
  // },
  // {
  //   path: 'contact/:id/new', component: EditContactDetailComponent,
  //   canActivate: [OdoorpcService]
  // },
  {
    path: 'forklift/:id', 
    component: ForkliftPageComponent
  },
  {
    path : 'status',
    component : StatusComponent
  },
  // {
  //   path : 'flow',
  //   component : FlowComponent
  // },
  {
    path : 'productions/:id',
    component : ProductionEditorComponent,
    title: 'Produzione'
  },
  {
    path : 'mindmap',
    component : MindmapComponent
  },
  {
    path : 'dashboard',
    component : DashboardComponent
  },
  {
    path: 'contact/:id',
    component: ContactDetailComponent,
    // canActivate: [OdoorpcService]
  },
  {
    path: 'contact', component: ContactSearchComponent,
    canActivate: [OdoorpcService],
    title: 'Contatti',
    children: [
      {
        path: ':pid/:id', component: ContactDetailComponent,
        canActivate: [OdoorpcService],
        title: 'Contatto'
      },
    ]
  },
  {
    path: 'leads/gmail/:mailid', component: GmailImportComponent,
    canActivate: [OdoorpcService]

  },
  {
    path: 'leads/:id/sales/:pid', component: SaleOrderDetailComponent,
    canActivate: [OdoorpcService],
    title: 'Ordine di Vendita'
    
  },
  // {
  //   path: 'leads/:id/purchases/:pid', component: PurchaseOrderDetailComponent,
  //   canActivate: [OdoorpcService]
  // },
  //activity editor
  
  {
    path: 'leads/:id/sale/:order_id',
    component: SaleOrderEditorComponent,
    canActivate: [OdoorpcService],
    title: 'Ordine di Vendita'  ,
    children: [
      {
        path: 'production/:production_id',
        component: ProductionEditorComponent,
        title: 'Produzione',
      },
      {
        path: 'picking/:picking_id', component: PickingEditorPage,
        canActivate: [OdoorpcService],
        title: 'Picking'
      },
      {
        path: 'purchase/:purchase_id', component: PurchaseOrderComponent,
        canActivate: [OdoorpcService],
        title: 'Ordine di Acquisto'
      },
    ]
  },

  // {
  //   path: 'leads/:id/sale/:order_id/purchase/:purchase_id',
  //   component: PurchaseOrderComponent,
  //   canActivate: [OdoorpcService]
  // },
  {
    path: 'leads/:id',
    component: DealDetailComponent,
    canActivate: [OdoorpcService],
    title: 'Commessa',
    children: [
        {
          path: 'activities/:aid', component: ActivityEditorComponent,
          canActivate: [OdoorpcService]
        },
    ]
  },
  {
    path: 'leads',
    component: DealsDashboardComponent,
    canActivate: [OdoorpcService],
    title: 'Commesse'
    
  },
  {
    path: 'pickings/oddments',
    component: OddmentsComponent,
    canActivate: [OdoorpcService],
    title: 'Resto Legno'
  },
 
 /*  {
    path: 'pickings//:picking_id',
    component: PickingInternalComponent,
    canActivate: [OdoorpcService]
  }, */
  {
    path: 'pickings/in/:picking_id',
    component: PickingInComponent,
    canActivate: [OdoorpcService],
    title: 'Carico',
    children: [
      {
        path: 'p/:package_id',
        component: PackageChooserComponent,
        canActivate: [OdoorpcService],
        title: 'Pacco'
      }
    ]
  },
  {
    path: 'pickings/:type/:picking_id',
    component: PickingInternalComponent,
    canActivate: [OdoorpcService],
    title: 'Picking'
  },
  
  // {
  //   path: 'pickings/pack/:id',
  //   component: PackPageComponent,
  //   canActivate: [OdoorpcService]
  // },
  {
    path: 'pickings',
    component: PickingSearchComponent,
    canActivate: [OdoorpcService],
    title: 'Trasferimenti'
  },
 
  {
    path: 'pickings/pick/:picking_id',
    component: PickingEditorPage,
    canActivate: [OdoorpcService],
    title: 'Picking'
  },
  {
    path: 'immediate-sale',
    component: SaleSearchComponent,
    canActivate: [OdoorpcService],
    title: 'Vendite'

  }, 
  {
    path: 'flash-sale',
    component: FlashSaleOrderComponent,
    canActivate: [OdoorpcService],
    title: 'Vendite Flash'
  }, 

  {
    path: 'flash-sale/:order_id',
    component: FlashSaleOrderEditorComponent,
    canActivate: [OdoorpcService],
    title: 'Vendita Flash'
  }, 
  {
    path: 'immediate-sale/s/:order_id',
    component: SaleOrderEditorComponent,
    canActivate: [OdoorpcService],
    title: 'Vendita su Lista'
  },


  {
    path: 'delivery-note',
    component: DeliveryNoteSearchComponent,
    canActivate: [OdoorpcService],
    title: 'Bolle di Consegna'
  },
 
  {
    path: 'delivery-note/:id',
    component: DeliveryNoteViewerComponent,
    canActivate: [OdoorpcService],
    title: 'Bolla di Consegna'
  },

  {
    path: 'delivery-note2',
    component: DeliveryNoteViewer2Component,
    canActivate: [OdoorpcService],
    title: 'Bolle di Consegna'
  },

  {
    path: 'invoice-search',
    component: InvoiceSearchComponent,
    canActivate: [OdoorpcService],
    title: 'Fatture'
  },

  {
    path: 'timeline',
    component: TimelineComponent,
    canActivate: [OdoorpcService]

  },
 
  {
    path: 'catalog',
    component: CatalogueComponent,
    title: 'Catalogo'
  },

  {
    path: 'packagelist',
    component: PackageListComponent,
    title: 'Pacchi'
  },

  {
    path: 'e',
    component: ExplorerComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'picking/:id/package/:idpackage', component: PackageDetailComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'search', component: SearchComponent,
    canActivate: [OdoorpcService],
    title: 'Ricerca'
  },
  {
    path: 'purchases/:id/a/print',
    component: PurchasePrintComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'sales/:order_id/a/print',
    component: SalePrintComponent,
    canActivate: [OdoorpcService]
  },
  {
    path: 'milvus',
    component: MilvusComponent,
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [OdoorpcService],
    title: 'Calendario'

  },

  {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
  }


];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
