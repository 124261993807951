<app-navbar [loading]="loading" backroute=".." *ngIf="mode != 'embedded'">
  <div class="d-flex justify-content-between align-items-center w-100">

  <a class="navbar-brand">
    <span >
        &nbsp;<b>Contatti</b>
    </span>
  </a>

  <ng-container>
    <div class="ms-auto dropdown d-inline" *ngIf="mode != 'embedded'">
      <a class="btn text-white btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        Nuovo
      </a>
      <ul class="dropdown-menu dropdown-menu-end">
        <form class="px-4 py-3" style="min-width: 440px;">
          <div class=" d-flex flex-column">
          

            <input 
              class="form-control mb-2" 
              placeholder="Nome" 
              [(ngModel)]="inputNew" 
              name="sn"
            >


            <input 
              class="form-control mb-2" 
              placeholder="Partita IVA" 
              [(ngModel)]="vatNew" 
              [ngModelOptions]="{'updateOn':'blur'}"
              name="vat"
              (ngModelChange)="onChangeVat(vatNew)"
            >
            
          

          <div *ngIf="alreadyExist" class="text-primary bold lead mb-2">
            <i class="fa-duotone fa-circle-exclamation"></i>
            Il contatto esiste già !
          </div>

            <div class="d-flex align-items-center">
              <div>
                <button 
                  class="ms-auto btn btn-primary text-white" 
                  (click)="new(inputNew)"
                  [disabled]="!inputNew || alreadyExist"
                >Crea</button>
              </div>
            </div>
          </div>
        </form>
      </ul>
    </div>
  </ng-container>
  </div>
</app-navbar>

<div class="navbar px-3 bg-info">

  <form autocomplete="off" class="d-flex flex-fill overflow-hidden">

    <div class="input-group">
      <input 
        type="text" 
        class="form-control border-info" 
        placeholder="Cerca per nome o partita iva" 
        [(ngModel)]="inputSearch"
        (input)="onSearchChange()" 
        (focus)="onFocusInput(true)" 
        (focusout)="onFocusInput(false)" 
        name="cx"
      >
      <button  
        *ngIf="this.mode !== 'embedded'"
        type="button" 
        [ngClass]="{'bg-primary': filterCompany$.value == false}"
        (click)="filterCompany$.next(false)" class="btn btn-dark border-info">
        <i class="fa fa-building"></i>
      </button>


    <button  
      *ngIf="this.mode !== 'embedded'"
      type="button" 
      [ngClass]="{'bg-primary': filterCompany$.value == true}"
      (click)="filterCompany$.next(true)" class="btn btn-dark border-info">
      <i class="fa fa-vcard"></i>
    </button>
    </div>
  </form>
</div>


<div *ngIf="showResults" data-test-id="searchResult" class="">

  <ul class="list-group">

    <ng-container *ngFor="let contact of contacts">
      <!-- <li 
        *ngIf="contact.parent_id.id"
        (click)="$event.stopPropagation();redirectContact(contact)"
        class="list-group-item lead" >
        <i class="fa-duotone fa-user me-3 text-info"></i>
        {{contact?.name?.toUpperCase()}} <span class="text-muted"> - {{contact?.parent_id.name?.toUpperCase()}}</span>
      </li> -->

      <li 
        (click)="$event.stopPropagation();redirectContact(contact)"
        class="list-group-item lead text-wrap">
        <i *ngIf="contact.is_company && !contact.parent_id.id" class="fa-duotone fa-building me-3 text-muted"></i>
        <i *ngIf="!contact.is_company && !contact.parent_id.id" class="fa-duotone fa-person me-3 text-muted"></i>
        
        <i *ngIf="contact.parent_id.id" class="fa-duotone fa-vcard me-3 text-muted"></i>
        <b *ngIf="contact.parent_id.id">{{contact.parent_id.name}} - </b>
        {{contact?.name?.toUpperCase()}} <span class="text-muted" *ngIf="contact?.city"> - {{contact?.city}}</span>
      </li>

    </ng-container>
  </ul>

  <ng-container *ngIf="contacts?.length == 0">
    <h5 class="text-center mt-4">Nessun risultato</h5>
  </ng-container>