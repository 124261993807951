import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductWithOnlyVariants } from '../../models/product.model';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { SaleOrderEditorComponent } from 'src/app/sale-order/sale-order-editor/sale-order-editor.component';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { ProductTemplateAttributeLine } from 'src/app/models/product.template.attribute.line';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PriceList } from 'src/app/models/price.list.model';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { ProductPackaging } from 'src/app/models/product.packaging.model';


@Pipe({ name: 'sortAsNumber' })
export class SortAsNumber implements PipeTransform {
  transform(value: any[], order = '', column: string = ''): any[] {
    return value.sort((a,b) => Number(a.name) - Number(b.name))
  }
}


@Component({
  selector: 'app-order-inventory-mini',
  templateUrl: './order-inventory-mini.component.html'
})

export class OrderInventoryMiniComponent implements OnInit, AfterViewInit {
  @Output() addOrderLine = new EventEmitter<Product>()
  @Input() sales: SaleOrder[]
  @Input() saleIds: string[]
  @Input() saleEditor: SaleOrderEditorComponent
  @Input() loading: boolean 
  inventoryClosed = true;
  @ViewChild(TemplateRef) template: TemplateRef<any>;
  @Input() noDrag:boolean = false
  @Input() noTemplate:boolean = false
  @Input() domain = []
  
  inputSearch:BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeTemplate: ProductTemplate|null;

  products: Product[];
  variants: Product[] = []
  groups: any;
  // TODO bad way to init prefs
  criteria: {
    toCreate?: any;attributeLine:ProductTemplateAttributeLine, attributeValue:ProductTemplateAttributeValue
    }[] = [];
  isMouseDown: boolean;
  lastValues: ProductTemplateAttributeValue[];
  name: any;
  selectedTemplate: { id: number; name: any; } = {id:45643, name:'Lamellare'};
  runningSearch$: any;
  refresh$: BehaviorSubject<boolean>= new BehaviorSubject(false);
  
  constructor(
    private odooEm: OdooEntityManager,
    private elRef:ElementRef,
    private odoorpcService: OdoorpcService
  ) {}


  ngAfterViewInit(): void {
  }

 
  ngOnInit() {

    this.refresh$.pipe(debounceTime(400)).subscribe(() => {
      this.refresh()
    })

    window.addEventListener("mousedown",e => {
      if (
        this.elRef.nativeElement.querySelector("table").contains(e.target) ||
        this.elRef.nativeElement.querySelector("input") == e.target ||
        this.elRef.nativeElement.querySelector("button") == e.target
      ) {
          this.inventoryClosed = false
        } else {
          this.inventoryClosed = true
          this.activeTemplate = null
        this.isMouseDown = false
      }

    })

    this.inputSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(async x => {
      this.refresh$.next(true)
    });
  }


  async loadPrice(res:Product[]) {

    var kwargs = kwargs || {};
    kwargs.context = [];

    var params = {
      model: new PriceList().ODOO_MODEL,
      method: "get_products_price",
      args: [[2],res.map(x => x.id)],
      kwargs: {
        context: null
      },
    };

    var r: any = await this.odoorpcService.sendRequest('/api/web/dataset/call_kw/' + params.model + "/" + params.method, params)

    return r.result ? r.result : []
  }

  dragstart(p:Product, ev) {
    console.log("overr1", ev)
    ev.dataTransfer.setData("app-product", "dassad")
    return true
  }

  async drop(el:CdkDragDrop<any>) {
  } 

  mouseup() {
    this.isMouseDown = false
  }

  mousedown(ev) {
    this.isMouseDown = true
  }

  mouseover(ev, a:ProductAttributeValue) {
  }

  async getProducts(input): Promise<Product[]> {
    let filters = [];
    
    if (this.activeTemplate)
      filters.push(['product_tmpl_id', "=", this.activeTemplate.id])
    
    var domain = []
    
    var cs = this.criteria.filter(c => {
      return (!c.toCreate || !c.attributeLine.display_name.startsWith('Lunghe')) 
    })

    // translate criteria in odoo query
    cs.forEach((x,i) => {
        domain.push('&')
        domain.push(['name', '=', x.attributeValue.name])
        domain.push('&')
        domain.push(['product_tmpl_id', '=', this.activeTemplate?.id])
        domain.push(['attribute_id', '=',x.attributeValue.attribute_id.id])
    });
    
    // odoo use logic operator 
    cs.slice(0,-1).forEach(x => {
      domain.unshift("|")
    })

    if (domain.length) {
      this.lastValues = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), domain))
      this.lastValues.map(x => x.id).forEach(v => {
          filters.push(['product_template_attribute_value_ids','in',v])
        })
    }
    
    if (input)
      input.split(' ').forEach(v => {
        filters.push(
          ['sale_ok', '=', 'true'],
          ['active', '=', 'true'],
          '|',
          ['name', 'ilike', v.replace(/[\s\*]/g, "%25")],
          ['product_template_attribute_value_ids', "ilike", v.replace(/[\s\*]/g, "%25")]
        );

        if (this.domain)
          filters = filters.concat(this.domain)
      });

      let products = await this.odooEm.search<Product>(new Product(), filters, 100,null,"name ASC").toPromise();
      //solve packagings
      await firstValueFrom(
        this.odooEm.resolveArray(new ProductPackaging(), products, "packaging_ids")
      );

      return products
  }


  close() {
    this.inventoryClosed = true  
  }

  async refresh() {
    if (this.inputSearch.value == "") {
      this.products = []
      return
    }
   
    this.loading = true
    var products = await this.getProducts(this.inputSearch.value)
    products.sort((a,b) => b.free_qty - a.free_qty)


    
    
    var prices = await this.loadPrice(products)
    products.forEach(p => {
      p._lst_price = prices[p.id]
    })
    
    this.products = products;
      
    // this.inventoryClosed = this.products?.length == 0
    this.loading = false
  }

  getFree(p: Product) {
    let available = 0
    // if available - outgoing >0 return available - outgoing, else 0
    if (p.detailed_type === 'consu')
      available = 9999

    else if (p.detailed_type === 'product') {
      available = p.qty_available - p.outgoing_qty
      if (available < 0)
        available = 0
    }

    return available
  }

  getDescriptive(p: Product, q:number) {
    if (p) {
     return "(" + this.odooEm.getDescriptive(p, q) + ")";
    }
}


  

  async insert(p:Product) {


    if (!this.sales && this.addOrderLine) {
      this.addOrderLine.next(p)
      return
    }

    var s = this.sales.find(x => x._open)
    if (s) {
      
      if (s.state == 'sale') {
        if (!confirm("Stai per retificare un ordine confermato e relative prenotazioni. Confermi ?"))
          return
      }
      var fr = s.order_line.values.find(l => l.route_id)

      var init = {
        product_id: p.id,
        product_uom_qty:0,
        order_id: s.id,
        route_id: fr ? fr.route_id.id : null,
        sequence: 10000
      }
      
      if (p.packaging_ids.ids && p.packaging_ids.ids.length > 0) {
        init['product_packaging_id'] = p.packaging_ids.ids[0]
      }

      await firstValueFrom(this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), init))
      await this.saleEditor.resolveSale(s)
    }
  }


}
