import { Component, ElementRef, HostListener, OnInit, Pipe, PipeTransform, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AREAS_CFG, ODOO_IDS, PRINTS_COSTLINES_CFG, PRINTS_PURCHASE_CFG } from '../../models/deal'
import { TrelloCardEntry2 } from 'src/app/models/trello-card';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { first } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import { orderBy } from 'lodash';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { DriveFolder } from 'src/app/models/drive.folder';
import { CalendarEvent } from 'src/app/models/calendar-event.model';
import { firstValueFrom } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CrmTag } from 'src/app/models/crm.tag.model';
import { CrmLeadPart } from 'src/app/models/crm.lead.part.model';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { MailActivity } from 'src/app/models/mail.message';
import { StockPicking } from 'src/app/models/stock-picking';
import { AccountMove } from 'src/app/models/account-move.model';
import { User } from 'src/app/models/user.model';
import { MailFollower } from 'src/app/models/mail.followers';
import { Partner } from 'src/app/models/partner';
import { Line_cost_data } from 'src/app/models/sale-order-line.model';
import { GapiService } from 'src/app/shared/services/g-api.service';


const DEAL_TABLE: string = "crm.lead"

declare var Trello: any;

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[], order): any[] {
    return orderBy(value, [order], ['asc']);
  }
}

@Component({
  selector: "app-deal-detail",
  templateUrl: "./deal-detail.component.html",
})
export class DealDetailComponent implements OnInit, AfterViewInit {

  // menu selection variables
  users: User[] = [];
  importableLeads: Lead[];
  stages: CrmStage[];
  followers: Partner[];
  activeTab: 'info' | 'activities' = 'info'; // Default tab

  // calendar event variables
  newCalendarEventName: string;
  newCalendarEventDate: string;
  newCalendarEventNote: string;
  updateCalendarList: boolean = false;
  activityToEdit: MailActivity | null = null;

  // lead variables
  lead: Lead;
  id: any;
  parts: CrmLeadPart[];
  partIds: string[];
  activities: MailActivity[];
  offersPart: CrmLeadPart;
  offers: SaleOrder[] = [];
  cfg: any;

  // New properties for posa data
  posaStartActivity: MailActivity | null = null;
  posaEndActivity: MailActivity | null = null;
  posaDuration: number = 0;
  tempDuration: number = 0; // Temporary duration for editing giorni di posa otherwise it would be saved immediately

  costlines: Line_cost_data[] = [];
  
  // UI variables
  canShowRight: boolean = false;
  canShowLeft: boolean = false;
  loading: boolean = false;
  tempRevenue: number = 0;
  copied: boolean = false;

  constructor(
    private odooEm: OdooEntityManager,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private odooRPCService: OdoorpcService,
    private gapiService: GapiService
  ) {}

  async ngOnInit() {
    this.stages = await this.odooEm.search<CrmStage>(new CrmStage()).toPromise();
    this.route.params.subscribe(async (params) => {
      this.id = params["id"];
      this.loading = true;
      await this.loadDeal();
      await this.loadPosaData(); // Add this new call
      this.canShowLeft = true; //on first opening, load components when they are ready
      await this.loadParts();
      this.loadUsers();
      this.loadPossibleFollowers();
      // await this.toggleResolvePart(this.offersPart) keep it closed forn now... reactivate if needed
      this.canShowRight = true; //on first opening, load components when they are ready
      this.loading = false;
      console.log("LEAD ", this.lead);
    });
    this.importableLeads = await this.odooEm.search<Lead>(new Lead(), [["tag_ids", "in", [ODOO_IDS.tag_template_id]]]).toPromise();
  }

  async ngAfterViewInit() {
  }

  //general update method
  updateDealToOdoo(field) {
    return new Promise<void>(async (res, rej) => {
      this.loading = true;
      var value = this.lead[field];
      // Set value ad number for field stage_id and stato_lavoro otherwhise the field value would be a string
      if (field == "stage_id") value = parseInt(this.lead[field].id);
      if (Array.isArray(value) && field != "settori") value = value[0];
      var fields = {};
      fields[field] = value;
      await this.odooEm.update(this.lead, fields).toPromise();
      this.loading = false;
      res();
    });
  }

  async updatePart(part: CrmLeadPart, field, value) {
    this.loading = true;
    var p = {};
    p[field] = value;
    await this.odooEm.update<CrmLeadPart>(part, p).toPromise();
    var id = part.id;
    await this.loadParts();
    this.toggleResolvePart(this.parts.find((x) => x.id == id));
    this.loading = false;
  }

  // ---------------------------------------------------------------------------------- Stage selection methods  

  getStageOrder() {
    return this.stages.map((s) => s.id).indexOf(this.lead.stage_id.id);
  }

  getStage() {
    var xx = this.stages.filter((x) => this.lead?.stage_id?.id == Number(x.id));
    return xx[0];
  }

  getNextStage(): CrmStage {
    var ind;
    this.stages.forEach((x, i) => {
      if (Number(x.id) == this.lead.stage_id.id) ind = i;
    });
    if (this.stages.length > ind) return this.stages[ind + 1];
    return null;
  }


  // New methods for stage-based visibility
  get currentStage(): string {
    return this.lead?.stage_id?.name?.toLowerCase() || "";
  }

  async toStatus(s) {
    //cannot skip form stage 0,1,2 to stage 4, 5, 6 
    if (this.getStageOrder() < 2 && s.sequence > 3 && s.sequence < 7) {
      return alert("Devi passare prima alla fase Inviato");
    }

    //cannot skip from stage to stage 6 if not form stage 5 (cannot put in terminata before produzione)
    if (this.getStageOrder() !=5 && s.sequence == 6) {
      return alert("Devi passare prima alla fase Produzione");
    }

    // Move the deal to a new stage and check if all required fields are filled

    if ((s.sequence === 1 ||s.sequence === 2)  && s.id !== 9) { // MOVING TO PREVENTIVO! name, partner, address and city are required
      console.log("CHECKING ", this.lead.name, this.lead.partner_id, this.lead.street, this.lead.city);
      if (!this.lead.name || !this.lead.partner_id.id || !this.lead.street || !this.lead.city){
       let missingfields = [];
        if (!this.lead.name) missingfields.push("descrizione");
        if (!this.lead.partner_id.id) missingfields.push("cliente");
        if (!this.lead.street) missingfields.push("indirizzo");
        if (!this.lead.city) missingfields.push("città");
        return alert("Non hai compilato tutti i campi. Manca da compilare: " + missingfields.join(", "));
      }
    }

    if (s.sequence === 3 && s.id !== 9) { // MOVING TO INVIATO! expected_revenue is required
      if (!this.lead.expected_revenue || this.lead.expected_revenue == 0)
        return alert("Non hai compilato tutti i campi. Compila l'importo preventivato");
      if (!confirm("Verrà rilevato per i KPI un valore di preventivo di € " + this.lead.expected_revenue + "."))
        return;
    }   

    if (s.sequence === 5 && s.id !== 9) { // MOVING TO PRODUZIONE! tag_ids are required for both fornitura and cetificazione
      let  fornituraTags = ODOO_IDS.crmTagsFornitura;
      let  certifciazioneTags = ODOO_IDS.crmTagsCertificazione;

      //at least one tag is requirer for fornitura and certificazione
      if (fornituraTags.every((t) => !this.lead.tag_ids.ids.includes(t)) && certifciazioneTags.every((t) => !this.lead.tag_ids.ids.includes(t)))
        return alert("Non hai compilato tutti i campi. Compila la modalità di fornitura e le certificazioni richieste");

      //if parner name is BANCO, block it
      if (this.lead.partner_id.name == "BANCO")
        return alert("Non puoi spostare la commessa in produzione se il cliente è BANCO");

      //check if posa is required and if it's filled
      if(this.hasTag("Fornitura e posa")) {
        if (!this.posaStartActivity || !this.posaEndActivity)
        return alert("La commessa prevede la posa. Compila i giorni di posa");
      }

      // Check and potentially update expected revenue
    await this.loadTotalContracts(this.lead);
    if (this.lead.expected_revenue !== this.lead._totalContractAmount) {
      const formattedContracts = new Intl.NumberFormat('it-IT', { 
        style: 'currency', 
        currency: 'EUR' 
      }).format(this.lead._totalContractAmount);
      
      const formattedExpected = new Intl.NumberFormat('it-IT', { 
        style: 'currency', 
        currency: 'EUR' 
      }).format(this.lead.expected_revenue);

      const message = 
        `Il totale dei contratti è ${formattedContracts}\n` +
        `Il valore atteso che verrà registrato per i KPI è ${formattedExpected}\n\n` +
        `Vuoi sincronizzare il valore atteso a ${formattedContracts} prima di procedere?\n\n` +
        `Premi OK per aggiornare e procedere con il totale contratti (${formattedContracts})\n` +
        `Premi Annulla per procedere con il valore atteso attuale (${formattedExpected})`;

      if (confirm(message)) {
        await this.updateExpectedRevenue(this.lead._totalContractAmount);
      }
    }

    }
    if (!confirm("Sei sicuro di spostare il fascicolo in " + s.name + " ?"))
      return;

    this.loading = true;
    try {
      // get the id from the sequence position
      this.lead.stage_id.id = s.id;
      await this.updateDealToOdoo("stage_id");

      // Write to Google Sheet after stage update if going to preventivo inviato or produzione
      if(s.sequence === 3 || s.sequence === 5) {
      try {
        await Lead._createdrive(this.lead, this.odooEm, this.posaStartActivity?.date_deadline, this.posaDuration, this.gapiService);
    } catch (error) {
        console.error("ERRORE NELLA SCRITTURA DEI DATI! CONTATTA SUBITO ML!", error);
        // Don't block the stage change if sheet write fails
    }
    }
      await this.loadDeal();
    } finally {
      this.loading = false;
    }
  }

  // ----------------------------------------------------------------------------------lead and general data methods

  async loadDeal() {
    // Fetch deal from Odoo using the id from the route
    this.loading = true;
            const filters = [
            '&',  // AND operator for combining id and active conditions
            ['id', '=', this.id],
            '|',  // OR operator for active conditions
                ['active', '=', true],
                ['active', '=', false]
        ];
    var lead: Lead = (await this.odooEm.search<Lead>(new Lead(), filters).toPromise())[0];

    await this.odooEm.resolve(lead.trello_card_ids).pipe(first()).toPromise()
    await this.odooEm.resolve(lead.drive_folder_ids).pipe(first()).toPromise()
    await firstValueFrom(this.odooEm.resolveSingle<CrmStage>(new CrmStage(), lead.stage_id))
    await firstValueFrom(this.odooEm.resolve<DriveFolder>(lead.drive_folder_ids))
    await firstValueFrom(this.odooEm.resolve<CrmTag>(lead.tag_ids))
    await firstValueFrom(this.odooEm.resolve<MailFollower>(lead.message_follower_ids))
    await firstValueFrom(this.odooEm.resolve<MailActivity>(lead.activity_ids))
    console.log("LEAD", lead);
    await this.loadTotalContracts(lead);
    console.log("LEAD", lead);

    this.lead = lead;
    this.cfg = AREAS_CFG.filter((a) => a.name == this.lead.area)[0];
    this.loading = false;
  }

  async loadUsers() {
    // Fetch users from Odoo
    this.users = await firstValueFrom(this.odooEm.search<User>(new User(), [["active", "=", true]]));
  }

  openContact(id: number) {
  //open new windows with the contact detail href="https://m3.galimberti.eu/contact/{{lead.partner_id.id}}"
  window.open("https://m3.galimberti.eu/contact/" + id, "_blank");
    
  
  }

  async loadPossibleFollowers() {
    try {
      // Search for partners with @galimberti.eu emails
      const partners = await firstValueFrom(
        this.odooEm.search<Partner>(new Partner(), [
          ['email', 'ilike', '@galimberti.eu']
        ])
      );
      this.followers = partners;
      console.log("lead partners", partners)
    } catch (error) {
      console.error('Error loading possible followers:', error);
    }
  }

  async loadPosaData() { //for sata methos i simulate hours to be 6:am to avoid timezone problems

    this.posaDuration = 0; // Reset duration to 0
    this.tempDuration = 0; // Reset tempDuration to 0
    this.posaStartActivity = null; // Reset start activity
    this.posaEndActivity = null; // Reset end activity
    // method to load data of expected posa time
    try {
      // Search for activities with specific IDs (18 and 19) inside this lead's activities
      this.posaStartActivity = this.lead.activity_ids.values?.find(a => a.activity_type_id.id === 18) || null;
      this.posaEndActivity = this.lead.activity_ids.values?.find(a => a.activity_type_id.id === 19) || null;

      // Calculate duration if both dates exist
      if (this.posaStartActivity?.date_deadline && this.posaEndActivity?.date_deadline) {
        const startTime = new Date(this.posaStartActivity.date_deadline);
        const endTime = new Date(this.posaEndActivity.date_deadline);
        startTime.setHours(6, 0, 0, 0);
        endTime.setHours(6, 0, 0, 0);

        this.posaDuration = Math.round((endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60 * 24));
        this.tempDuration = this.posaDuration;
      }
      console.log("Posa data", this.lead.activity_ids.values, this.posaStartActivity?.date_deadline, this.posaEndActivity?.date_deadline, this.posaDuration);
    } catch (error) {
      console.error('Error loading installation data:', error);
    }
  }

  async updatePosa(start: Date | string, days: number) {
    console.log("current posa data:", this.posaStartActivity, this.posaEndActivity, this.posaDuration);
    this.loading = true;
    console.log("xxxxxxUpdating POSA dates:", { start, days });
    try {
      // Normalize the start date to YYYY-MM-DD format, removing any time component
      const startDate = new Date(typeof start === 'string' ? start : start);
      startDate.setHours(6, 0, 0, 0);
      days = Number(days || 0);

      // Calculate end date properly as a Date object
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + days);
      endDate.setHours(6, 0, 0, 0);

      // Format dates to YYYY-MM-DD string format for Odoo
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      console.log("Updating POSA dates:", {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        days: days
      });

      // If not posastart activity create it
      if (!this.posaStartActivity) {
        console.log("xxxx posa start not found, creating new");
        let New = await firstValueFrom(this.odooEm.create<MailActivity>(new MailActivity(), {
          activity_type_id: 18,
          res_id: this.lead.id,
          res_model_id: 620,
          res_model: "crm.lead",
          //user with id 98 is posatore
          user_id:  98,
          date_deadline: formattedStartDate,
        }))
        this.posaStartActivity = New;
      }
      else {// if posa start exists update it
        console.log("xxxx posa start found, updating");
        await firstValueFrom(this.odooEm.update(this.posaStartActivity, {
          date_deadline: formattedStartDate
        }))
      }

      // If not posaend activity create it
      if (!this.posaEndActivity) {
        console.log("xxxx posa end not found, creating new");
        let New = await firstValueFrom(this.odooEm.create<MailActivity>(new MailActivity(), {
          activity_type_id: 19,
          res_id: this.lead.id,
          res_model_id: 620,
           //user with id 98 is posatore
           user_id:  98,
          res_model: "crm.lead",
          date_deadline: formattedEndDate,
        }))
        this.posaEndActivity = New;
        console.log("xxxx posa end created",);
      }
      else {// if posa end exists update it
        console.log("xxxx posa end found, updating");
        await firstValueFrom(this.odooEm.update(this.posaEndActivity, {
          date_deadline: formattedEndDate
        }))
      }

      // Wait a brief moment before reloading to ensure Odoo has processed the updates
      await new Promise(resolve => setTimeout(resolve, 100));

      // Reload activities and recalculate duration
      const activities = await firstValueFrom(
        this.odooEm.search<MailActivity>(
          new MailActivity(),
          [
            ['res_model', '=', 'crm.lead'],
            ['res_id', '=', this.lead.id],
            ['activity_type_id', 'in', [18, 19]]
          ]
        )
      );

      this.posaStartActivity = activities.find(a => a.activity_type_id.id === 18) || null;
      this.posaEndActivity = activities.find(a => a.activity_type_id.id === 19) || null;

      if (this.posaStartActivity?.date_deadline && this.posaEndActivity?.date_deadline) {
        const startTime = new Date(this.posaStartActivity.date_deadline);
        const endTime = new Date(this.posaEndActivity.date_deadline);

        startTime.setHours(6, 0, 0, 0);
        endTime.setHours(6, 0, 0, 0);

        this.posaDuration = Math.round((endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60 * 24));
        this.tempDuration = this.posaDuration;

        console.log("xxxxxxxUpdated POSA data:", {
          startDate: this.posaStartActivity.date_deadline,
          endDate: this.posaEndActivity.date_deadline,
          calculatedDuration: this.posaDuration
        });
      }

    } catch (error) {
      console.error('Error updating posa dates:', error);
    } finally {
      this.loading = false;
    }
  }

  async loadParts() {
    // Fetch parts from Odoo and set the offers part
    let allparts = await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [["lead_id", "=", this.lead.id],]));
    this.parts = allparts.filter((p) => p.name != "Preventivi"); // Filter out the offers part
    this.offersPart = allparts.find((p) => p.name == "Preventivi"); // Set the offers part
    this.partIds = this.parts.map((p) => "part-" + p.id);
  }

  async loadTotalContracts(lead: Lead) {
    //sum the untexed amount of all the invoices related to the lead that have journal_id = ODOO_IDS.contratti_id
    let Invoices = await firstValueFrom(this.odooEm.search<AccountMove>(new AccountMove(), [['invoice_origin', '=', lead.tracking_code] , ['journal_id', '=', ODOO_IDS.contratti_id]]));
    console.log('Invoices:', Invoices);
    // assign the total amount untaxed to the lead
    lead._totalContractAmount = Invoices.reduce((acc, inv) => acc + inv.amount_untaxed, 0);
  }

  shouldShowSection(
    section: "driveAndTrello" | "preventivi" | "billing" | "production" | "costCheck" | "all" 
  ): boolean {
    // Get the current stage sequence
    const sequence = this.stages.find(s => s.id === this.lead.stage_id.id)?.sequence || 0;
  
    switch (section) {
      case "driveAndTrello":
        return sequence >= 0; //then we block UI if the user is no data, but this way is better for the user
        
      case "preventivi":
        return sequence >= 1;
        
      case "billing":
        return sequence >= 3;
        
      case "production":
        return sequence >= 5;

      case "costCheck":
        return sequence >= 5;
        
      case "all":
        // Maybe keep this for backwards compatibility or adjust as needed
        return sequence >= 2;
        
      default:
        return false;
    }
  }

  copyLeadData() {
    let data = this.lead.city + " - " + this.lead.name + " - " + this.lead.tracking_code;
    navigator.clipboard.writeText(data)
        .then(() => {
            console.log('Text copied to clipboard');
            this.copied = true;
            setTimeout(() => {
              this.copied = false;
            }, 3000);
        })
        .catch(err => {
            console.error('Failed to copy text: ', err);
        });
}

  async onContact(c: Contact) {
    // Assign a contact to the lead and update all related records
    this.loading = true;
    if (!c) {
      return
    }
    if (!c.ga_arca) {
      alert("Il contatto non ha il codice ARCA. Impossibile associarlo alla commessa");
      return;
    }

    if (!confirm("Sei sicuro di voler associare il contatto alla commessa? Verrà modificato l'intestatario di fatture, ordini e trasferimenti già creati")) {
      return;
    }
    try {
      // Odoo automatically updates the address and city of the lead to the new contact address. We store them to set them back after the update
      let oldAddress = this.lead.street;
      let oldCity = this.lead.city;
      await this.odooEm.update(this.lead, { partner_id: c.id }).toPromise();

      //update ui cause i don't run the resolve method, it's too slow
      this.lead.partner_id.id = c.id;
      this.lead.partner_id.name = c.name;
      this.lead.street = oldAddress;
      this.lead.city = oldCity;

      //set back address and city cause Odoo automatically updates them t the new contact address
      await this.odooEm.update(this.lead, {
        street: oldAddress,
        city: oldCity,
        partner_name: c.name,
      }).toPromise(); //idk what partner_name is, but it's in the model so I update it

      // Fetch related records
      const sales = await this.odooEm.search<SaleOrder>(new SaleOrder(), [["opportunity_id", "=", this.lead.id],]).toPromise();
      const pickings = await this.odooEm.search<StockPicking>(new StockPicking(), [["origin", "in", sales.map((s) => s.name)],]).toPromise();
      const invoices = await firstValueFrom(this.odooEm.search<AccountMove>(new AccountMove(), [["invoice_origin", "=", this.lead.tracking_code.toString()],]));

      // Update all related records
      await Promise.all([
        this.odooEm.updateMulti(new SaleOrder(), sales, { partner_id: c.id }).toPromise(),
        this.odooEm.updateMulti(new StockPicking(), pickings, { partner_id: c.id }).toPromise(),
        this.odooEm.updateMulti(new AccountMove(), invoices, { partner_id: c.id }).toPromise(),
      ]);
      alert("Contatto associato correttamente alla commessa");
    } catch (error) {
      console.error("Error updating contact association:", error);
      alert("Si è verificato un errore durante l'associazione del contatto");
    } finally {
      this.loading = false;
    }
  }

  async assignUser(user: any) {
    // Assign a user to the lead
    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.update(this.lead, { user_id: user.id }));
      this.lead.user_id = user;
    } catch (error) {
      console.error("Error assigning user:", error);
    } finally {
      this.loading = false;
    }
  }

  isFollower(possibleFollower: any): boolean {
    return this.lead.message_follower_ids.values?.some(
      follower => follower.display_name === possibleFollower.name
    );
  }

  async addFollower(partner: any) {
    if (this.isFollower(partner))
      return;

    this.loading = true;
    try {
      // Create new follower
      const followerData = {
        res_model: 'crm.lead',
        res_id: this.lead.id,
        partner_id: partner.id
      };

      await firstValueFrom(this.odooEm.create(new MailFollower(), followerData));

      // Refresh the lead data to get updated followers
      await this.loadDeal();
    } catch (error) {
      console.error('Error adding follower:', error);
      alert('Si è verificato un errore durante l\'aggiunta del follower');
    } finally {
      this.loading = false;
    }
  }

  async removeFollower(follower: MailFollower) {
    if (!confirm(`Sei sicuro di voler rimuovere ${follower.partner_id.value?.name} dai follower?`)) {
      return;
    }

    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.delete(new MailFollower(), [follower.id]));
      // Refresh the followers list
      await this.loadDeal();
    } catch (error) {
      console.error('Error removing follower:', error);
      alert('Si è verificato un errore durante la rimozione del follower');
    } finally {
      this.loading = false;
    }
  }

  getAreaBadgeClass(area: string): string {
    // Get the badge class for the area
    switch (area) {
      case "Tetti":
        return "bg-success text-white";
      case "Case":
        return "bg-danger text-white";
      case "Facciate e Decking":
        return "bg-secondary text-white";
      case "Aziendale":
        return "bg-muted text-dark";
      default:
        return "bg-warning text-dark";
    }
  }

  getFormattedAddress(): string {
    // Get the formatted address
    const parts = [this.lead?.street, this.lead?.city].filter(Boolean);
    return parts.join(", ");
  }

  async assignArea(area: string) {
    // Assign an area to the lead
    this.loading = true;
    try {
      await firstValueFrom(this.odooEm.update(this.lead, { area: area }));
      this.lead.area = area;
    } catch (error) {
      console.error("Error assigning area:", error);
    } finally {
      this.loading = false;
    }
  }

  async onAddressChange($event) {
    for (var x = 0; x < $event.address_components.length; x++) {
      var e = $event.address_components[x];
      if (e.types[0] == "locality") {
        this.lead.city = e.long_name.replaceAll("'", " ");
        break;
      }
      if (e.types[0] == "administrative_area_level_3") {
        this.lead.city = e.long_name.replaceAll("'", " ");
        break;
      }
    }
    await this.updateDealToOdoo("city");
    await this.updateDealToOdoo("cordinates");

    this.lead.street = $event.formatted_address.replaceAll("'", " ");
    console.log("STREE ", this.lead.street);
    await this.updateDealToOdoo("street");
  }

  async updateExpectedRevenue(value: string | number) {
    if (!value) return;
    this.loading = true;
    try {
      const numericValue = typeof value === 'string' ? parseFloat(value) : value;
      if (numericValue !== this.lead.expected_revenue) {
        await firstValueFrom(this.odooEm.update(this.lead, { expected_revenue: numericValue }));
        this.lead.expected_revenue = numericValue;
      }
    } catch (error) {
      console.error('Error updating expected revenue:', error);
    } finally {
      this.loading = false;
    }
  }

  async SyncExpectedRevenue() {
    if(confirm("Sei sicuro di voler sincronizzare il valore atteso con il valore dei contratti?")) {
   await this.loadTotalContracts(this.lead);
   if (this.lead._totalContractAmount >0) {
    await this.updateExpectedRevenue(this.lead._totalContractAmount);
      }
      else {
        alert("Non ci sono contratti associati alla commessa...");
      }
  }
  }

  async delete() {
    // Delete a lead
    if (confirm("Sei sicuro di voler eliminare il lead ?")) {
      this.loading = true;
      await this.odooEm.delete(new Lead(), [this.lead.id]).pipe(first()).toPromise();
      this.loading = false;
      this.router.navigate(["deals"]);
    }
  }

  hasTag(tag: string) {
    return this.lead.tag_ids.values?.find((t) => t.name == tag) ? true : false;
  }

  async toggleTags(tag) {
    // Toggle a tag on the lead. used for modalità fornitura
    this.loading = true;
    var ts = await firstValueFrom(this.odooEm.search<CrmTag>(new CrmTag(), [["name", "=", tag]]));
    if (!ts.length) {
      return alert("Il tag " + tag + " non esiste");
    }
    //create an array with all the tags of the lead
    let tags = this.lead.tag_ids.ids;
    // add the tag to the array if it's not already there
    if (!tags.includes(ts[0].id)) {
    tags.push(ts[0].id);
    }

     // find the tag to remove
     let fornituraTags = ODOO_IDS.crmTagsFornitura;
     let certifciazioneTags = ODOO_IDS.crmTagsCertificazione;
    let other_tag = [];
     if (fornituraTags.includes(ts[0].id) ){
     other_tag = fornituraTags.filter((t) => t != ts[0].id);
     }
      else { //if in certificazione, if i selected the nessuna certificazione tag, remove the other tags
        if (tag == "No certificazione") {
     other_tag = certifciazioneTags.filter((t) => t != ts[0].id);
      }
      else { //i'm adding certificazione tag so i remove the nessuna certificazione tag
        other_tag[0] = 21 ;
    }
  }


    // remove the other tags
    tags = tags.filter((t) => !other_tag.includes(t));

    await firstValueFrom(this.odooEm.update(this.lead, { tag_ids: tags }));
  
    await this.loadDeal();
    this.loading = false;
  }

  async editActivity(a: MailActivity) {
   this.router.navigate(["activities", a.id], {
      relativeTo: this.route,
    });
    //}
    this.loading = false;
  }
  

  async deleteActivity(a: MailActivity) {
    if (confirm("Sei sicuro di voler eliminare l'attività?")) {
      this.loading = true;
      await this.odooEm.delete(new MailActivity(), [a.id]).toPromise();
      await this.loadDeal();
      this.loading = false;
    }
  }

  async completeActivity(a: MailActivity) {
    if(confirm("Sei sicuro di voler completare l'attività?")) {
    this.loading = true;
    await this.odooEm.update(a, { state: "done" }).toPromise();
    await this.loadDeal();
    this.loading = false;
  }
  }



  // ---------------------------------------------------------------------------------- HERE ALL!!!! Drive and Trello methods, FOR LEADS AND FOR SALES!

  filterDriveFolders(settore: string) {
    // Filter drive folders by sector
    if (this.lead.drive_folder_ids && this.lead.drive_folder_ids.values)
      return this.lead.drive_folder_ids.values.filter((x) => x.name == settore);
  }

  filterTrelloCards(settore: string) {
    // Filter trello cards by sector
    if (this.lead.trello_card_ids && this.lead.trello_card_ids.values)
      return this.lead.trello_card_ids.values.filter((x) => x.name == settore);
  }

  // ---------------------------------------------------------------------------------- Preventivi and produzione methods

  async loadOffers() {
    // Fetch offers from Odoo
    this.offers = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [
      ["opportunity_id", "=", this.lead.id],
      ["part_id", "=", false],
    ])
    );
  }

  async expandAll() {
    // Expand all parts
    this.loading = true;
    let ps = [];
    this.parts.forEach(async (p) => {
      ps.push(this.toggleResolvePart(p));
    });
    await Promise.all(ps);
    this.loading = false;
  }

  async resolvePart(p) {
    // Resolve a part
    this.loading = true;
    var res = await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [["id", "=", p.id]]));

    // Fetch related sales and activities
    await firstValueFrom(this.odooEm.resolve(res[0].sale_order_ids));
    await firstValueFrom(this.odooEm.resolveArray(new MailActivity(), res[0].sale_order_ids.values, "activity_ids"));

    // Fetch related drive folders and trello cards
    firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new DriveFolder(), res[0].sale_order_ids.values, "drive_folder_ids"));
    firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new TrelloCardEntry2(), res[0].sale_order_ids.values, "trello_card_ids"));

    // we fetched proc groups to find delivery status but we don't use it anymore. changed with custom function

    // firstValueFrom(this.odooEm.resolveArrayOfSingle(new ProcurementGroup(), res[0].sale_order_ids.values, "procurement_group_id"));
    // let procs = [];    
    // res[0].sale_order_ids.values.forEach((s) => {
    //   if (
    //     s.procurement_group_id.value &&
    //     s.procurement_group_id.value.stock_move_ids
    //   )
    //     procs.push(s.procurement_group_id.value);
    // });

    // if (procs.length > 0) {await firstValueFrom(this.odooEm.resolveArray(new StockMove(), procs, "stock_move_ids")); }               

    this.odooEm.call2("sale.order", "ga_compute_order_state", [null, res[0].sale_order_ids.values.map((r) => r.id),])
      .then((x) => {
        // ga_compute_order_state returns an ordered array of strings in the result key
        res[0].sale_order_ids.values.forEach((r, i) => {
          r._delivery_state = x.result[i];
        });
      });

    // assign the resolved values to the part
    p.sale_order_ids.ids = res[0].sale_order_ids.ids;
    p.sale_order_ids.values = res[0].sale_order_ids.values;
    this.loading = false;
  }

  async toggleResolvePart(p: CrmLeadPart) {
    // Toggle the resolution of a part
    if (!p) return;
    this.loading = true;
    if (p.sale_order_ids?.values) {
      p.sale_order_ids.values = null;
    } else {
      await this.resolvePart(p);
    }
    this.loading = false;
  }

  async attachNewPart() {
    if (confirm("Verrà aggiunta una nuova sotto-commessa (gruppo di ordini). Confermi?")) {
      this.loading = true;
      var p = {
        lead_id: this.lead.id,
        name: "",
      };
      var s = await this.odooEm.create<CrmLeadPart>(new CrmLeadPart(), p).toPromise();
      await this.loadParts();
      this.loading = false;
    }
  }

  async deletePart(p: CrmLeadPart) {
    if (confirm("Sei sicuro di voler eliminare la sotto-commessa? Non potrai eliminarla se ci sono ordini di lavoro al suo interno")) {
      this.loading = true;
      if (
        !p.sale_order_ids.ids ||
        (p.sale_order_ids.ids && p.sale_order_ids.ids.length == 0)
      ) {
        await firstValueFrom(this.odooEm.delete(new CrmLeadPart(), [p.id]));
        this.loading = false;
        await this.loadParts();
      } else {
        this.loading = false;
        return alert("Impossibile eliminare, ci sono ordini di lavoro al suo interno. Prima cancellali o spostali in altre sotto-commesse");
      }
    }
  }

  getActivity(s: SaleOrder) {
    // Fetch activities from Odoo for a specific sale order
    return this.activities.filter((a) => a.res_id == s.id);
  }

  filterProductionDriveFolders(p: SaleOrder) {
    var l = p.drive_folder_ids?.values?.find((x) => x.name == "Produzione" && x.origin == p.name);
    if (l) return [l];
    return;
  }

  filterProductionTrelloFolders(p: SaleOrder) {
    if (p.trello_card_ids && p.trello_card_ids.values) {
      return p.trello_card_ids.values.filter((x) => x.name == "Produzione" && x.origin == p.name);
    }
    return [];
  }

  async importLead(l: Lead) {
    // Import a lead through a custom Odoo method
    if (!confirm("Stai per importare nuovi gruppi di ordini nella commessa. Confermi ?"))
      return;

    this.loading = true;
    await this.odooEm.call2("crm.lead", "importParts", [this.lead.id, l.id]);
    this.loadDeal();
    this.loadParts();
    this.loading = false;
  }

  async drop(el: CdkDragDrop<CrmLeadPart, CrmLeadPart>) {
    // Drag and drop method for transfering sales between parts
    this.loading = true;
    var position = el.currentIndex;
    var dragged = el.item.data;
    var sourcePart: CrmLeadPart = el.previousContainer.data;
    var targetPart: CrmLeadPart = el.container.data;

    if (sourcePart != targetPart) {
      await firstValueFrom(this.odooEm.update(sourcePart, { sale_order_ids: [[3, dragged.id]] }));
      await firstValueFrom(this.odooEm.update(targetPart, { sale_order_ids: [[4, dragged.id]] }));
      await this.resolvePart(sourcePart);
    }
    await this.odooEm.call2(new CrmLeadPart().ODOO_MODEL, "reorder_sale_orders", [[el.container.data.id], el.item.data.id, position]);
    await this.resolvePart(targetPart);
    this.loading = false;
  }

  async attachCalendarEvent() {
    if (!this.newCalendarEventName) return;
    var calendarEventTmpl = {
      name: this.newCalendarEventName,
      partner_id: [this.lead.partner_id.id, this.lead.partner_id.name],
      start: this.newCalendarEventDate,
      stop: this.newCalendarEventDate,
      description: this.newCalendarEventNote,
      res_id: this.lead.id,
      opportunity_id: this.lead.id,
    };
    var c = await this.odooEm.create<CalendarEvent>(new CalendarEvent(), calendarEventTmpl).toPromise();
    this.elRef.nativeElement.querySelectorAll("#dm3")[0].classList.remove("show");
    this.newCalendarEventName =
      this.newCalendarEventDate =
      this.newCalendarEventNote =
      "";
    this.updateCalendarList = true;
  }

  // async renameGroup(g, name) {
  //   this.loading = true;
  //   await firstValueFrom(this.odooEm.update(g, { name: name }));
  //   this.loading = false;
  // }

  async updateCommitmentDate(s: SaleOrder, value: any) {
    this.loading = true;
    await this.odooEm.check(
      firstValueFrom(this.odooEm.update(s, {
        tag_ids: [[6, 0, [ODOO_IDS.tag_to_prepare]]],
        commitment_date: value.toString().split("T")[0],
      })
      )
    );
    s.commitment_date = value;
    this.loading = false;
  }

  async renameSale(s: SaleOrder, name) {
    this.loading = true;
    await firstValueFrom(this.odooEm.update(s, { ga_title: name }));
    this.loading = false;
  }

  async attachNewOffer() {
    if (this.offersPart) { // If the offers part already exists, attach a new sale to it
      await this.attachNewSale(this.offersPart);
    } else { // If the offers part doesn't exist, create it and attach a new sale to it
      this.loading = true;
      var p = {
        lead_id: this.lead.id,
        name: "Preventivi",
      };
      this.offersPart = await firstValueFrom(this.odooEm.create<CrmLeadPart>(new CrmLeadPart(), p));

      await this.attachNewSale(this.offersPart);
      this.loading = false;
    }
    this.toggleResolvePart(this.offersPart); // Mantieni la sezione dei preventivi aperta
  }

  async attachNewSale(p: CrmLeadPart) {
    let ss = await this.odooRPCService.getSessionInfo();
    this.loading = true;
    var sale = {
      opportunity_id: this.lead.id,
      partner_id: this.lead.partner_id.id,
      picking_policy: "direct",
      ga_address: this.lead.street,
      pricelist_id: ODOO_IDS.pricelist_commessa,
      user_id: ss.result.user_id[0],
    };
    var s = await this.odooEm.create<SaleOrder>(new SaleOrder(), sale).toPromise();
    var ids = p.sale_order_ids.ids.concat(s.id);
    await this.updatePart(p, "sale_order_ids", ids);
    this.loading = false;
  }

  close() {
    this.router.navigate(["leads"]);
  }

  // ---------------------------------------------------------------------------------- OLD METHODS, NOT USED ANYMORE

  //old vars
  // sales: SaleOrder[];
  // existingsale: SaleOrder;
  // @HostListener("window:beforeunload", ["$event"])
  // unloadHandler(event: Event) {
  //   return !this.loading;
  // }
  // trelloCards: TrelloCardEntry2[] = [];
  // @ViewChild("map") mapElement: any;

  // @ViewChild("appbilling") appbilling: BillingComponent;
  // @ViewChild("appcontract") appcontract: ContractsComponent;



  // redirectOffer(id) {
  //   window.open("/offers/" + id, "_blank");
  // }

  // pickedOut(s: SaleOrder) {
  //   //cerca se ci sono trasferimenti in uscita completati
  //   let picked = s.procurement_group_id.value?.stock_move_ids?.values?.find(
  //     (m) =>
  //       m.location_dest_id.id == ODOO_IDS.stock_location_sent &&
  //       m.state == "done"
  //   );
  //   console.log("PICKED ", picked);
  //   if (picked) return true;
  //   else return false;
  // }

  // orderArrived(s: SaleOrder) {
  //   // controlla tra i trasferimenti se ce n'è uno di tipo ricevuto completato
  //   let arrived = s.procurement_group_id.value?.stock_move_ids?.values?.find(
  //     (m) =>
  //       m.location_id.id == ODOO_IDS.stock_location_vendor && m.state == "done"
  //   );
  //   console.log("ARRIVED ", arrived);
  //   if (arrived) return true;
  //   else return false;
  // }

  // orderPrepared(s: SaleOrder) {
  //   // controlla tra i trasferimenti se ce ne sono completati che non vengono da customer e non vanno a vendor
  //   let prepared = s.procurement_group_id.value?.stock_move_ids?.values?.find(
  //     (m) =>
  //       m.state == "done" &&
  //       m.location_id.id != ODOO_IDS.stock_location_vendor &&
  //       m.state == "done" &&
  //       m.location_dest_id.id != ODOO_IDS.stock_location_customer
  //   );
  //   console.log("PREPARED ", prepared);
  //   if (prepared) return true;
  //   else return false;
  // }

  // getOrderState(s: SaleOrder) {
  //   //determino lo stato dell'ordine con appoggio alle funzioni sopra

  //   let c = "";
  //   console.log("CHECKING STATE ORDER ", s.name);

  //   if (
  //     s.state == "sale" &&
  //     (s.delivery_status == "partial" || s.delivery_status == "pending") &&
  //     this.pickedOut(s) == false &&
  //     this.orderArrived(s) == false &&
  //     this.orderPrepared(s) == false
  //   )
  //     c = "Confermato";
  //   if (
  //     (s.state == "sale" && s.delivery_status == "full") ||
  //     (s.state == "sale" && !s.delivery_status)
  //   )
  //     c = "Spedito interamente";

  //   if (
  //     s.state == "sale" &&
  //     s.delivery_status == "partial" &&
  //     !this.pickedOut(s) &&
  //     this.orderArrived(s) &&
  //     !this.orderPrepared(s)
  //   )
  //     c = "Confermato - acquisti arrivati";
  //   if (
  //     s.state == "sale" &&
  //     s.delivery_status == "partial" &&
  //     !this.pickedOut(s) &&
  //     this.orderPrepared(s)
  //   )
  //     c = "Preparato - non spedito";
  //   if (
  //     s.state == "sale" &&
  //     s.delivery_status == "partial" &&
  //     this.pickedOut(s)
  //   )
  //     c = "Spedito parzialmente";
  //   if (s.state == "draft") c = "Bozza";
  //   if (s.state == "cancel") c = "Annullato";
  //   console.log("DELI STATE ", s.name, c);

  //   return c;
  // }

  // computeChildrenSales() {

  //   var ffs = this.getFirstSales()
  //   ffs.forEach(fs => {
  //     fs._sales = this.sales.filter(s => {
  //       return (s.origin == fs.name) || (!fs.id && !this.isFirstSale(s) && !s.origin)
  //     })
  //   })

  // }

  // PRIMANOTA
  // isFirstSale(s:SaleOrder) {
  //   return s.tag_ids.values?.filter(t => t.name == 'PRIMANOTA').length
  // }

  // getFirstSales() {
  //   return this.sales.filter(x => !x.origin)
  // }

  // getDirectSales() {
  //   return this.sales.filter((x) => x.origin);
  // }

  // groupBy = function (xs, key) {
  //   return xs.reduce(function (rv, x) {
  //     if (!x[key]) {
  //       x[key] = x.name;
  //     }
  //     (rv[x[key]] = rv[x[key]] || []).push(x);

  //     return rv;
  //   }, {});
  // };


  // async attachProduzione() {
  //   if (!this.cfg.production_product_id) {
  //     alert("Nessun prodotto da produrre");
  //     return;
  //   }
  //   // hack to force close
  //   document.querySelectorAll(".dropdown-menu.show").forEach((s) => {
  //     s.classList.remove("show");
  //   });

  //   this.loading = true;
  //   // if (this.lead.stage_id)

  //   var existingsale: SaleOrder = (
  //     await this.odooEm
  //       .search<SaleOrder>(new SaleOrder(), [
  //         ["opportunity_id", "=", this.lead.id],
  //       ])
  //       .toPromise()
  //   )[0];
  //   if (!existingsale) {
  //     var saletmpl = {
  //       // name: "F" + this.lead.name ,
  //       opportunity_id: this.lead.id,
  //       partner_id: this.lead.partner_id.id,
  //       pricelist_id: 1,
  //     };
  //     existingsale = await this.odooEm
  //       .create<SaleOrder>(new SaleOrder(), saletmpl)
  //       .toPromise();
  //     existingsale = (
  //       await this.odooEm
  //         .search<SaleOrder>(new SaleOrder(), [
  //           ["opportunity_id", "=", this.lead.id],
  //         ])
  //         .toPromise()
  //     )[0];
  //   }

  //   var stockmove = {
  //     name: "P" + this.lead.tracking_code,
  //     product_id: this.cfg.production_product_id,
  //     product_uom_qty: 1,
  //     product_uom: 1,
  //     location_id: 15,
  //     location_dest_id: 8,
  //     origin: "P" + this.lead.tracking_code,
  //     production_id: null,
  //   };

  //   var productiontmpl = {
  //     product_id: this.cfg.production_product_id,
  //     product_qty: 1,
  //     product_uom_id: 1,
  //     bom_id: false,
  //     picking_type_id: 21,
  //     location_src_id: 8,
  //     location_dest_id: 8,
  //     origin: existingsale.name,
  //     title: this.newProductionName,
  //   };

  //   var p = await this.odooEm
  //     .create<MrpProduction>(new MrpProduction(), productiontmpl)
  //     .pipe(first())
  //     .toPromise();

  //   stockmove.production_id = p.id;
  //   var s = await this.odooEm
  //     .create<StockMove>(new StockMove(), stockmove)
  //     .pipe(first())
  //     .toPromise();
  //   await this.loadDeal();
  //   this.loading = false;
  // }


  // pickContact(field) {
  //   var w = window.open(
  //     "/contact?mode=embedded",
  //     "_blank",
  //     "height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
  //   );
  //   var h = (event) => {
  //     var d = JSON.parse(event.data);
  //     this.lead[field] = [d.id, d.name];
  //     this.updateDealToOdoo(field);
  //     w.close();
  //   };
  //   window.addEventListener("message", h, false);

  //   w.onbeforeunload = () => {
  //     window.removeEventListener("message", h);
  //   };
  // }

  // beforeunloadHandler(event) {
  //   return false;
  //   //I have used return false but you can your other functions or any query or condition
  // }



}
