<app-navbar data-print-row-header [loading]="loading" backroute="../..">
  <div class="d-flex align-items-center justify-content-between w-100 me-3">
  <a class="navbar-brand">
    <span>
      {{purchase?.name}}
    </span>
  </a>

  <div class="dropdown d-flex align-items-center gap-4 " style="z-index:2000;">
    <button class="btn btn-link text-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bars"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li><a class="dropdown-item" target="_blank" (click)="print(purchase)">Stampa</a></li>
      <li><a class="dropdown-item" target="_blank"
          href="//o3.galimberti.eu/web#id={{purchase?.id}}&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form">Apri
          in Odoo</a></li>
      <!-- <li><a class="dropdown-item" target="_blank" (click)="delete(purchase)">Elimina</a></li> -->
    </ul>
  </div>
</div>
</app-navbar>

<!----- TABLE ------>
<div class="table-container h-100 overflow-x-auto overflow-y-auto" *ngIf="purchase && !contact">
  <table id="order" *ngIf="loaded" class="table table-bordered tableFixHead table-hover mb-0 h-100">
    <thead class="border-info bg-info">
      <tr class="mt-3 border-top border-0 border-bottom-0">
        <td colspan="6" class="p-2 mt-3 border-0 border-bottom-0" style="border-top: 1px solid #333 !important;">
          <div class="d-flex align-items-center">
            {{purchase?.partner_id?.name}}
            <div class="dropdown">
              <button class="btn btn-link px-1 mx-3" type="button" id="dropdownMenu" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa-solid fa-user-pen "></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right p-3 "
                style="min-width:350px ; max-width: 600px; ;z-index:9999999">
                <app-contact-picker2 [mode]="'embedded'" class="embedded overflow-hidden"
                  (onSelect)="onContact($event)"></app-contact-picker2>
              </div>
            </div>
            <span class="ms-auto">Richiesto il:</span>
            <input type="date" class="text-muted ms-1 form-control w-auto"
              [ngModel]="purchase.date_planned ? purchase.date_planned.slice(0,10): null"
              (ngModelChange)="updateOrder(purchase, $event, 'date_planned')">
          </div>

        </td>
      </tr>

    </thead>

    <thead class="sticky-top">
      <tr valign="middle">
        <th class="bg-light ps-3">ID</th>
        <th class="bg-light">
          <div class="d-flex align-items-center">Nome
          </div>
        </th>
        <th class="bg-light text-end " colspan="1">UdM</th>
        <th class="bg-light text-end">Cad €</th>
        <th class="bg-light text-end">Totale €</th>
      </tr>
    </thead>
    <tbody *ngIf="loaded">
      <tr *ngFor="let line of purchase?.order_line.values" class="mw-100" id="tr{{line.id}}">
        <ng-container [ngTemplateOutlet]="lineProductTpl" [ngTemplateOutletContext]="{line: line, order: s}">
        </ng-container>
      </tr>
      <!-- total order -->
      <tr>
        <td colspan="4" class="text-end">Totale</td>
        <td class="text-end">
          <b>{{purchase.amount_total | number:'1.2-2'}}
          </b>
          </td>
      
          </tr>
      <tr *ngIf="purchase?.order_line?.values?.length == 0">
        <td colspan="14" class="text-center">Nessun prodotto</td>
      </tr>
      
    </tbody>

    <tbody *ngIf="!purchase.order_line || purchase.order_line.values" class="h-100">
      <td colspan="14" class="p-0">
        <textarea style="resize:none" class="form-control w-100 h-100 m-0" [ngModelOptions]="{'updateOn':'blur'}"
          [ngModel]="purchase.notes ? innerText(purchase.notes) : ''" placeholder="Note"
          (ngModelChange)="updateOrder(purchase,$event, 'notes')"></textarea>
      </td>
    </tbody>
    

    <tfoot>
      <tr *ngIf="sale?.opportunity_id.value">
        <td colspan="2"
        >
      Indirizzo di cantiere:
      </td>
        <td colspan="4">
          <i class="fa-duotone fa-location-dot"></i> {{sale.opportunity_id.value.street}}
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #lineProductTpl let-line="line" let-order="order">
  <!-- id -->
  <td style="vertical-align: middle; width: 30px;" class="ps-3 text-start align-middle">
    {{line.product_id.value?.id}}
  </td>

  <!-- name -->
  <td class="text-start align-middle " style="text-wrap:balance;">
    <a href="https://o3.galimberti.eu/web?debug=1#id={{line.product_id.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form"
      *ngIf="line.product_id" class="text-dark " target="_blank">
      {{line.product_id.value?.display_name.replaceAll(", -","")}}
    </a>
  </td>

  <!-- qty -->
  <td class="text-nowrap editable align-middle">
    <div class="d-flex align-items-center justify-content-end">

      <input-number [disabled]="purchase.state != 'draft'" class="me-2"
        (ngModelChange)="updateLine(line, 'product_qty', $event)"
        [ngModel]="line.product_qty "
        [ngModelOptions]="{'updateOn':'blur'}">
      </input-number>
      <span>{{line.product_id.value?.uom_id?.name}}</span>
    </div>
  </td>

  <td class="editable text-end align-middle">
    <input-number [disabled]="purchase.state != 'draft'" [ngModel]="line.price_unit"
      (ngModelChange)="updateLine(line, 'price_unit', $event)"></input-number>
  </td>

  <td class="text-end align-middle">
    {{line.price_total | number:'1.2-2'}}
  </td>

</ng-template>

<ng-template #lineSectionTpl let-line="line" let-order="order">

  <td colspan="14" class="">
    <input class="text-muted fw-bold form-control" [ngModelOptions]="{'updateOn':'blur'}" [ngModel]="line.name"
      (ngModelChange)="updateLine(line, 'name',$event)">
  </td>
</ng-template>