<div class="modal d-block" style="background-color: rgba(0,0,0,0.5);">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="d-flex justify-content-between w-100 mb-2">
                    <h5 class="modal-title">Crea Offerta</h5>
                    <button type="button" class="btn-close" (click)="onCancel.emit()"></button>
                </div>
                <div class="w-100">
                    <h5 class="mb-1">{{ quant.product_id.value.display_name.replaceAll(', -','') }}</h5>
                    <div class="text-warning" *ngIf="isAlreadyScrap">
                        <small>Attenzione: Questo articolo è già scontato</small>
                    </div>
                </div>
             </div>

            <div class="modal-body">
                <div *ngIf="showQuantityEditor">
                    <app-product-quantity-editor [product]="quant.product_id.value"
                        (onSave)="onQuantitySelected($event)" (onCancel)="onCancel.emit()">
                    </app-product-quantity-editor>
                </div>

                <div *ngIf="!showQuantityEditor && selectedQuantity">
                      <div class="mb-3">
                        <label class="form-label">Quantità</label>
                        <div class="d-flex align-items-center">
                            <span>{{ selectedQuantity }} {{ quant.product_uom_id.name }}</span>
                            <button class="btn btn-link ms-2 text-primary" (click)="showQuantityEditor = true">
                                <i class="fa-solid fa-pencil"></i>
                            </button>
                        </div>
                    </div>

                    <div class="mb-3" *ngIf="sourcePackage">
                        <label class="form-label">Da Pacco (opzionale)</label>
                        <input type="text" class="form-control" [value]="sourcePackage.name" readonly>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">A Pacco</label>
                        <div class="input-group">
                            <span class="form-control" *ngIf="!destinationPackage">Nessun pacco selezionato</span>
                            <span class="form-control" *ngIf="destinationPackage">{{ destinationPackage.name }}</span>
                            <!-- usa lo stesso -->
                            <button class="btn btn-link text-primary" (click)="destinationPackage = sourcePackage">
                                <i class="fa-solid fa-copy"></i>
                            </button>
                            <!-- scanner -->
                            <button class="btn btn-link text-primary" (click)="showScanner = true">
                                <i class="fa-solid fa-barcode"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Offerta applicata</label>
                        <select class="form-select" [(ngModel)]="selectedTag" (ngModelChange)="onFondo()">
                            <option [ngValue]="null">Seleziona un'offerta da applicare</option>
                            <option *ngFor="let tag of fondoTags" [ngValue]="tag">{{ tag.name }}</option>
                        </select>
                    </div>

                    <div class="mb-3" *ngIf="selectedTag">
                        <label class="form-label" [ngClass]="{'text-danger': !existingScrap}">


                            Articolo scontato {{existingScrap? 'già esistente. Utilizzo:' : ' non presente. Creo nuovo:'}}
                        </label>
                        <h5 class="mb-0">{{ newProductName }}</h5>
                    </div>

                    <!-- show new variant data with discount -->
                    <div class="mb-4" *ngIf="selectedTag" >
                        <div class="row mb-2" *ngIf="isAlreadyScrap">
                            <div class="col">
                                <small class="text-muted">Costo del prodotto attuale:</small>
                                <div class="d-flex align-items-baseline">
                                    <h6 class="mb-0">€ {{ quant.product_id.value.list_price | number:'1.2-2' }}</h6>
                                    <small class="text-muted ms-2">
                                        (sconto {{ (100 - quant.product_id.value.list_price / originalPrice * 100) | number:'1.0-0'
                                        }}%)
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2" >
                            <div class="col">
                                <small class="text-muted">Costo prodotto originale:</small>
                                <div class="d-flex align-items-baseline">
                                    <h6 class="mb-0">€ {{ originalPrice | number:'1.2-2' }}</h6>
                                    <small class="text-muted ms-2">
                                        (sconto 0%)
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <small class="text-muted">Costo nuovo prodotto scontato:</small>
                                <div class="d-flex align-items-baseline">
                                    <h6 class="mb-0">€ {{ newListPrice | number:'1.2-2' }}</h6>
                                    <small class="text-muted ms-2">
                                        (sconto {{ (100 - newListPrice / originalPrice * 100) | number:'1.0-0' }}%)
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- show info message with loading -->
                    <div class="mb-3" *ngIf="loading">
                        <div class="d-flex align-items-center">
                            <div class="spinner-border spinner-border-sm me-2"></div>
                            {{infoMessage}}
                        </div>
                    </div>





                    <button class="btn btn-primary w-100" [disabled]="!selectedTag || !destinationPackage" (click)="createScrap()">
                        Crea articolo scontato
                    </button>
                </div>

                <!-- Scanner -->
                <div *ngIf="showScanner">
                    <app-scanner (onPackage)="onDestinationScanned($event)" (onCode)="onCodeScanned($event)">
                    </app-scanner>
                </div>
            </div>
        </div>
    </div>
</div>